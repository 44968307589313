import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { categories } from "./categories.js";

// Define your initial state
const initialState = {
  userCategories: [],
  // userSubCategories: [],
  userProducts: [],
  userSingleProduct: {},
  catLoading: false,
  catError: null,
  // subcLoading: false,
  // subcError: null,
  prodLoading: false,
  prodError: null,
  userSingleProductLoading: false,
  userSingleProductError: null,
};

const baseUrl = process.env.REACT_APP_API_URL_LOCAL;

// Define your async thunk actions
export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    const response = await fetch(`${baseUrl}/category/all`);
    const data = await response.json();
    // const data = categories;
    return data;
  }
);

// export const fetchSubCategories = createAsyncThunk(
//   "subcategories/fetchSubCategories",
//   async (categoryId) => {
//     const response = await fetch(
//       `${baseUrl}/category/sub-categories/${categoryId}`
//     );
//     const data = await response.json();
//     return data;
//   }
// );

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (id) => {
    const response = await fetch(`${baseUrl}/category/${id}`);
    const data = await response.json();
    return data[0];
  }
);

export const fetchProduct = createAsyncThunk(
  "product/fetchProduct",
  async (id, { getState }) => {
    const { userCategoryState: state } = getState();
    const existingProduct = state.userProducts?.all_products?.find(
      (product) => product.id === id
    );

    // console.log(existingProduct);
    // const existingProduct = null;
    // console.log(state);

    if (existingProduct) {
      return existingProduct;
    } else {
      const response = await fetch(
        `${baseUrl}/products/${id}`
      );
      const data = await response.json();
      return data;
    }
  }
);

// Define your slice
const userCategorySlice = createSlice({
  name: "userCategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //categories
      .addCase(fetchCategories.pending, (state) => {
        state.catLoading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.catLoading = false;
        state.userCategories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.catLoading = false;
        state.catError = action.error.message;
      })

      //subcategories
      // .addCase(fetchSubCategories.pending, (state) => {
      //   state.subcLoading = true;
      // })
      // .addCase(fetchSubCategories.fulfilled, (state, action) => {
      //   state.subcLoading = false;
      //   state.userSubCategories = action.payload;
      // })
      // .addCase(fetchSubCategories.rejected, (state, action) => {
      //   state.subcLoading = false;
      //   state.subcError = action.error.message;
      // })

      //products
      .addCase(fetchProducts.pending, (state) => {
        state.prodLoading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.prodLoading = false;
        state.userProducts = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.prodLoading = false;
        state.prodError = action.error.message;
      })

      //for sigle product
      .addCase(fetchProduct.pending, (state) => {
        state.userSingleProductLoading = true;
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.userSingleProductLoading = false;
        state.userSingleProduct = action.payload;
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.userSingleProductLoading = false;
        state.userSingleProductError = action.error.message;
      });
  },
});

// Export actions and reducer
export default userCategorySlice.reducer;
