import React from "react";
import Footer from "../../components/LandingPage/Footer/Footer";
import Breadcrumb from "../../components/Category/Breadcrumb";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import SubCategoryAndProductCategoryContainer from "../../components/Category/SubCategoryAndProductCategoryContainer";
import { getRelatedCategoriesNameAndLink } from "../../config/utils";
import Navbar from "../../components/AnimatedLandingPage/Navbar";
import { Helmet } from "react-helmet";

const SubCategory = () => {
  const { categoryId } = useParams();

  const { userCategories } = useSelector((state) => state.userCategoryState);

  const items = getRelatedCategoriesNameAndLink(userCategories, categoryId);

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          {items[items.length - 1].name
            ? items[items.length - 1].name
            : "Subcategory"}{" "}
          | US Sports
        </title>
      </Helmet>
      <Navbar />
      {/* <Header /> */}
      <Breadcrumb
        items={[
          { name: "Categories", link: "/category" },
          ...items.slice(0, -1),
        ]}
        currentTitle={items[items.length - 1].name}
      />
      <SubCategoryAndProductCategoryContainer />
      <Footer />
    </>
  );
};

export default SubCategory;
