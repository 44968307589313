import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  addBackLogo,
  addFrontLogo,
  setCustomizeTabIndex,
} from "../../slices/canvasSlice";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const LogoEditor = ({ isFrontView, setIsFrontView }) => {
  const dispatch = useDispatch();
  const handleFileChangeFront = (e) => {
    if (!isFrontView) {
      setIsFrontView(true);
    }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new window.Image(); // Use window.Image
        img.src = reader.result;
        img.onload = () => {
          dispatch(addFrontLogo({ userLogo: img }));
        };
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFileChangeBack = (e) => {
    if (isFrontView) {
      setIsFrontView(false);
    }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new window.Image(); // Use window.Image
        img.src = reader.result;
        img.onload = () => {
          dispatch(addBackLogo({ userLogo: img }));
        };
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <input
        type="file"
        accept="image/*"
        id="logo-upload-input-front"
        style={{ display: "none" }}
        onChange={handleFileChangeFront}
      />
      <Box
        component={"label"}
        htmlFor="logo-upload-input-front"
        sx={{
          width: "70%",
          mb: 2,
          bgcolor: "#d1e2eb",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100px",
            // bgcolor: "#262626",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            cursor: "pointer",
          }}
        >
          <IconButton
            // variant="contained"
            disableElevation
            // fullWidth
            // sx={{ bgcolor: "#4172dc", color: "#fff" }}
            component="span"
          >
            <FileUploadIcon sx={{ fontSize: "40px" }} />
          </IconButton>
          <Typography>Click to upload Logo for the front Side</Typography>
        </Box>
      </Box>
      <input
        type="file"
        accept="image/*"
        id="logo-upload-input-back"
        style={{ display: "none" }}
        onChange={handleFileChangeBack}
      />
      <Box
        component={"label"}
        htmlFor="logo-upload-input-back"
        sx={{
          width: "70%",
          mb: 2,
          bgcolor: "#d1e2eb",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100px",
            // bgcolor: "#262626",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            cursor: "pointer",
          }}
        >
          <IconButton
            // variant="contained"
            disableElevation
            // fullWidth
            // sx={{ bgcolor: "#4172dc", color: "#fff" }}
            component="span"
          >
            <FileUploadIcon sx={{ fontSize: "40px" }} />
          </IconButton>
          <Typography>Click to upload Logo for the back Side</Typography>
        </Box>
      </Box>

      <Box
        sx={{ width: "100%", display: "flex", gap: "12px", marginTop: "16px" }}
      >
        <Button
          variant="contained"
          sx={{
            width: "50%",
            bgcolor: "#fff",
            color: "#040707",
            border: "1px solid #040707",
            "&:hover": {
              border: "#f26728 solid 1px",
              color: "#f26728",
              backgroundColor: "rgba(242, 103, 40, 0.1)",
            },
          }}
          disableFocusRipple
          disableElevation
          onClick={() => {
            dispatch(setCustomizeTabIndex("textNumbers"));
          }}
        >
          Back
        </Button>

        <Button
          variant="contained"
          sx={{
            width: "50%",
            bgcolor: "#fff",
            color: "#040707",
            border: "1px solid #040707",
            "&:hover": {
              border: "#f26728 solid 1px",
              color: "#f26728",
              backgroundColor: "rgba(242, 103, 40, 0.1)",
            },
          }}
          disableFocusRipple
          disableElevation
          onClick={() => {
            dispatch(setCustomizeTabIndex("roster"));
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default LogoEditor;
