import { createSlice } from "@reduxjs/toolkit";
import { productData } from "../config/productData";
import { fontStyles } from "../config/constants";

const awayCanvasSlice = createSlice({
  name: "awayCanvasState",
  initialState: {
    fontStyles: fontStyles,
    colors: productData.colors,
    frontPathsArr: productData.frontObjects,
    backPathsArr: productData.backObjects,
    frontMaskImage: productData.frontMaskImage,
    backMaskImage: productData.backMaskImage,
    frontTextArray: productData.frontText,
    backTextArray: productData.backText,
    userFrontLogo: {
      logo: null,
      x: 150,
      y: 200,
      title: "frontLogo",
      height: 220,
      width: 220,
    },
    userBackLogo: {
      logo: null,
      x: 150,
      y: 200,
      title: "backLogo",
      height: 220,
      width: 220,
    },
    frontCanvasExportImage: null,
    backCanvasExportImage: null,
    frontStageRef: null,
    backStageRef: null,

    isActive:false,

  },
  reducers: {

    changeAwayColor: (state, action) => {
      const { targetElements, newColor } = action.payload;

      state.colors = state.colors.map((colorObj) => {
        if (targetElements.includes(colorObj.title)) {
          // If the title matches one of the target elements, update the fill color
          return { ...colorObj, fill: newColor };
        }
        // If the title doesn't match, leave the object unchanged
        return colorObj;
      });
    },
    changeAwayText: (state, action) => {
      const { targetElements, newText } = action.payload;

      state.frontTextArray = state.frontTextArray.map((textObj) => {
        if (targetElements.includes(textObj.title)) {
          return { ...textObj, text: newText };
        }
        return textObj;
      });
      state.backTextArray = state.backTextArray.map((textObj) => {
        if (targetElements.includes(textObj.title)) {
          return { ...textObj, text: newText };
        }
        return textObj;
      });
    },
    changeAwayTextFill: (state, action) => {
      const { targetElements, newColor } = action.payload;
      state.frontTextArray = state.frontTextArray.map((textObj) => {
        if (targetElements.includes(textObj.title)) {
          return { ...textObj, fill: newColor };
        }
        return textObj;
      });
      state.backTextArray = state.backTextArray.map((textObj) => {
        if (targetElements.includes(textObj.title)) {
          return { ...textObj, fill: newColor };
        }
        return textObj;
      });
    },
    changeAwayTextStroke: (state, action) => {
      const { targetElements, newColor } = action.payload;

      state.frontTextArray = state.frontTextArray.map((textObj) => {
        if (targetElements.includes(textObj.title)) {
          return { ...textObj, stroke: newColor };
        }
        return textObj;
      });

      state.backTextArray = state.backTextArray.map((textObj) => {
        if (targetElements.includes(textObj.title)) {
          return { ...textObj, stroke: newColor };
        }
        return textObj;
      });
    },
    changeAwayPosition: (state, action) => {
      const { textArr, targetElementId, newX, newY } = action.payload;
      state[textArr] = state[textArr].map((textObj) => {
        if (targetElementId === textObj.id) {
          return { ...textObj, x: newX, y: newY };
        }
        return textObj;
      });
    },
    changeAwayFontStyles: (state, action) => {
      const { targetElements, newFont } = action.payload;
      state.frontTextArray = state.frontTextArray.map((textObj) => {
        if (targetElements.includes(textObj.title)) {
          return { ...textObj, fontFamily: newFont };
        }
        return textObj;
      });
      state.backTextArray = state.backTextArray.map((textObj) => {
        if (targetElements.includes(textObj.title)) {
          return { ...textObj, fontFamily: newFont };
        }
        return textObj;
      });
    },
    addAwayFrontLogo: (state, action) => {
      const { userLogo } = action.payload;
      state.userFrontLogo.logo = userLogo;
    },
    addAwayBackLogo: (state, action) => {
      const { userLogo } = action.payload;
      state.userBackLogo.logo = userLogo;
    },
    changeAwayLogoPosition: (state, action) => {
      const { logoStateName, newX, newY } = action.payload;
      state[logoStateName] = { ...state[logoStateName], x: newX, y: newY };
    },
    changeAwayLogoDimensions: (state, action) => {
      const { logoStateName, newW, newH } = action.payload;
      state[logoStateName].width = newW;
      state[logoStateName].height = newH;
    },
    deleteAwayElement: (state, action) => {},
    exportAwayCanvas: (state, action) => {
      const { stageRef, downloadName } = action.payload;
      const dataUrl = stageRef.current.toDataURL();
      if (downloadName === "canvas-front") {
        state.frontCanvasExportImage = dataUrl;
      } else {
        state.backCanvasExportImage = dataUrl;
      }

      // const link = document.createElement("a");
      // link.download = downloadName;
      // link.href = dataUrl;
      // link.click();
    },
    updateAwayStageRef: (state, action) => {
      const { canvasRef, refStateName } = action.payload;
      state[refStateName] = canvasRef;
    },

    toggleIsActive:(state, action)=>{
      state.isActive = !state.isActive;
    }

    
  },
});

export const {
  changeAwayColor,
  changeAwayText,
  changeAwayTextFill,
  changeAwayTextStroke,
  changeAwayFontStyles,
  addAwayFrontLogo,
  addAwayBackLogo,
  changeAwayPosition,
  changeAwayLogoPosition,
  changeAwayLogoDimensions,
  exportAwayCanvas,
  updateAwayStageRef,
  toggleIsActive,
} = awayCanvasSlice.actions;

export const selectAwayColors = (state) => state.awayCanvasState.colors;
export const selectAwayFrontPaths = (state) => state.awayCanvasState.frontPathsArr;
export const selectAwayBackPaths = (state) => state.awayCanvasState.backPathsArr;
export const selectAwayFrontMaskImage = (state) => state.awayCanvasState.frontMaskImage;
export const selectAwayBackMaskImage = (state) => state.awayCanvasState.backMaskImage;
export const selectAwayFrontTextArray = (state) => state.awayCanvasState.frontTextArray;
export const selectAwayBackTextArray = (state) => state.awayCanvasState.backTextArray;
export const selectAwayFontStyles = (state) => state.awayCanvasState.fontStyles;
export const selectAwayUserFrontLogo = (state) => state.awayCanvasState.userFrontLogo;
export const selectAwayUserBackLogo = (state) => state.awayCanvasState.userBackLogo;
export const selectAwayFrontCanvasExportImage = (state) =>
  state.awayCanvasState.frontCanvasExportImage;
export const selectAwayBackCanvasExportImage = (state) =>
  state.awayCanvasState.backCanvasExportImage;
export const selectAwayFrontCanvasRef = (state) => state.awayCanvasState.frontStageRef;
export const selectAwayBackCanvasRef = (state) => state.awayCanvasState.backStageRef;
export const selectIsAwayActive = (state) => state.awayCanvasState.isActive;


export default awayCanvasSlice.reducer;