import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL_LOCAL;

export const getAllCategories = createAsyncThunk(
  "categoriesSlice/getAllCategories",
  async () => {
    try {
      const response = await axios.get(`${baseUrl}/category/all`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getAllParentCategories = createAsyncThunk(
  "categoriesSlice/getAllParentCategories",
  async () => {
    try {
      const response = await axios.get(`${baseUrl}/category`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createNewCategory = createAsyncThunk(
  "categoriesSlice/createNewCategory",
  async (data) => {
    try {
      // console.log(data);
      const response = await axios.post(`${baseUrl}/category`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateCategory = createAsyncThunk(
  "categoriesSlice/updateCategory",
  async (bodyData) => {
    try {
      // console.log(bodyData);
      const response = await axios.put(
        `${baseUrl}/category/${bodyData.id}`,
        bodyData.data
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "categoriesSlice/deleteCategory",
  async (id) => {
    try {
      // console.log(id);
      const response = await axios.delete(`${baseUrl}/category/${id}`);
      // console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const uploadCategoryImage = createAsyncThunk(
  "categoriesSlice/uploadCategoryImage",
  async (data) => {
    try {
      // console.log(data);
      const response = await axios.put(
        `${baseUrl}/category/image-upload`,
        data
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const categoriesSlice = createSlice({
  name: "categoriesSlice",
  initialState: {
    isCategoryAdding: false,
    isCategoryUpdating: false,
    isCategoryDeleting: false,
    allCategories: { isLoading: false, data: [] },
    allParentCategories: { isLoading: false, data: [] },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllParentCategories.fulfilled, (state, action) => {
      state.allParentCategories.data = action.payload;
      state.allParentCategories.isLoading = false;
    });
    builder.addCase(getAllParentCategories.pending, (state, action) => {
      state.allParentCategories.isLoading = true;
    });
    builder.addCase(getAllParentCategories.rejected, (state, action) => {
      console.error(action.error.message);
      state.allParentCategories.isLoading = false;
    });
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.allCategories.data = action.payload;
      state.allCategories.isLoading = false;
    });
    builder.addCase(getAllCategories.pending, (state, action) => {
      state.allCategories.isLoading = true;
    });
    builder.addCase(getAllCategories.rejected, (state, action) => {
      console.error(action.error.message);
      state.allCategories.isLoading = false;
    });
    builder.addCase(createNewCategory.pending, (state, action) => {
      state.isCategoryAdding = true;
    });
    builder.addCase(createNewCategory.fulfilled, (state, action) => {
      state.isCategoryAdding = false;
      const newCategory = action.payload;
      state.allCategories.data.push(newCategory);
      if (
        newCategory.parent_category_id === null ||
        newCategory.parent_category_id === ""
      ) {
        state.allParentCategories.data.push(newCategory);
      }
    });
    builder.addCase(createNewCategory.rejected, (state, action) => {
      console.error(action.error.message);
      state.isCategoryAdding = false;
    });
    builder.addCase(updateCategory.pending, (state, action) => {
      state.isCategoryUpdating = true;
    });
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      state.isCategoryUpdating = false;
      const newCategory = action.payload;
      state.allCategories.data = state.allCategories.data.map((category) => {
        if (category.id === newCategory.id) {
          return { ...newCategory };
        }
        return category;
      });
      if (
        newCategory.parent_category_id === null ||
        newCategory.parent_category_id === ""
      ) {
        state.allParentCategories.data = state.allParentCategories.data.map(
          (category) => {
            if (category.id === newCategory.id) {
              return { ...newCategory };
            }
            return category;
          }
        );
      }
    });
    builder.addCase(updateCategory.rejected, (state, action) => {
      console.error(action.error.message);
      state.isCategoryUpdating = false;
    });
    builder.addCase(deleteCategory.pending, (state, action) => {
      state.isCategoryDeleting = true;
    });
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.isCategoryDeleting = false;
      const deletedCategory = action.payload;
      state.allCategories.data = state.allCategories.data.filter((category) => {
        return category.id !== deletedCategory.id;
      });
      if (
        deletedCategory.parent_category_id === null ||
        deletedCategory.parent_category_id === ""
      ) {
        state.allParentCategories.data = state.allParentCategories.data.filter(
          (category) => {
            return category.id !== deletedCategory.id;
          }
        );
      }
    });
    builder.addCase(deleteCategory.rejected, (state, action) => {
      console.error(action.error.message);
      state.isCategoryDeleting = false;
    });
    builder.addCase(uploadCategoryImage.pending, (state, action) => {
      state.isCategoryUpdating = true;
    });
    builder.addCase(uploadCategoryImage.fulfilled, (state, action) => {
      state.isCategoryUpdating = false;
      const newCategory = action.payload[0];
      // console.log(newCategory);
      state.allCategories.data = state.allCategories.data.map((category) => {
        if (category.id === newCategory.id) {
          return { ...newCategory };
        }
        return category;
      });
      if (
        newCategory.parent_category_id === null ||
        newCategory.parent_category_id === ""
      ) {
        state.allParentCategories.data = state.allParentCategories.data.map(
          (category) => {
            if (category.id === newCategory.id) {
              return { ...newCategory };
            }
            return category;
          }
        );
      }
    });
    builder.addCase(uploadCategoryImage.rejected, (state, action) => {
      console.error(action.error.message);
      state.isCategoryUpdating = false;
    });
  },
});

export const {} = categoriesSlice.actions;

export const selectIsCategoryAdding = (state) =>
  state.categoriesSlice.isCategoryAdding;

export const selectIsCategoryUpdating = (state) =>
  state.categoriesSlice.isCategoryUpdating;

export const selectIsCategoryDeleting = (state) =>
  state.categoriesSlice.isCategoryDeleting;

export const selectAllParentCategories = (state) =>
  state.categoriesSlice.allParentCategories;

export const selectAllCategories = (state) =>
  state.categoriesSlice.allCategories;

export default categoriesSlice.reducer;
