import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import FrontTextNumbersEditor from "./FrontTextNumbersEditor";
import BackTextNumbersEditor from "./BackTextNumbersEditor";

const TextAndNumbersEditor = () => {
  const [expandedPanel, setExpandedPanel] = useState("front");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Accordion
        expanded={expandedPanel === "front"}
        onChange={handleChange("front")}
        sx={{ bgcolor: "#f6f9fb", my: 1, width: "100%" }}
        elevation={0}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 4 }}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 700, color: "#262626" }}
          >
            Front Side Text And Numbers
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FrontTextNumbersEditor />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanel === "back"}
        onChange={handleChange("back")}
        sx={{ bgcolor: "#f6f9fb", my: 1, width: "100%" }}
        elevation={0}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ px: 4 }}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 700, color: "#262626" }}
          >
            Back Side Text And Numbers
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BackTextNumbersEditor />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TextAndNumbersEditor;
