import React, { useEffect } from "react";
import LineReviewChart from "../../../components/Dashboard/DashOverview/LineReviewChart";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  selectAllProducts,
} from "../../../slices/productSlice";
import {
  getAllCategories,
  selectAllCategories,
} from "../../../slices/categoriesSlice";
import {
  getAllCustomProducts,
  selectAllCustomProducts,
} from "../../../slices/adminCanvasSlice";
import { useNavigate } from "react-router";

const DashOverview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allCustomProducts = useSelector(selectAllCustomProducts);
  const allProducts = useSelector(selectAllProducts);
  const allCategories = useSelector(selectAllCategories);
  // console.log(allCategories);
  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllCategories());
    dispatch(getAllCustomProducts());
  }, []);
  return (
    <div className="w-full flex h-[94vh] flex-col justify-between">
      <div className="w-full h-[60%] flex">
        <div className="graph-for-inquiries w-full flex flex-col items-center">
          <LineReviewChart />
          <h3 className="-mt-8">Recent rating of the products</h3>
        </div>
        {/* <div className="total-number-of-reviews w-[30%] p-10">
          <div className="w-full h-full bg-[#d1e2eb] flex flex-col items-center justify-between p-10 rounded-2xl">
            <h2 className="text-[1.5vw] font-semibold text-center">
              Total number of products
            </h2>
            <h2 className="text-[2.5vw] font-bold mt-[10px]">9021</h2>
            <button className="px-[1.5vw] py-[0.8vw] bg-red-50 text-base font-semibold rounded-2xl ">
              Goto product page
            </button>
          </div>
        </div> */}
      </div>
      <div className="w-full h-[39%] flex items-center justify-between gap-3">
        <div className="w-[33.33%] h-full rounded-xl p-10 bg-[#d1e2eb] flex flex-col items-center justify-between ">
          <h2 className="text-[1.5vw] font-semibold text-center">
            Total number of products
          </h2>
          <h2 className="text-[2.5vw] font-bold mt-[10px]">
            {allProducts.isLoading ? "-- --" : allProducts.data.length}
          </h2>
          <button
            onClick={() => navigate("/dashboard/products")}
            className="px-[1.5vw] py-[0.8vw] bg-red-50 text-base font-semibold rounded-2xl "
          >
            Goto product page
          </button>
        </div>
        <div className="w-[33.33%] h-full rounded-xl p-10 bg-[#d1e2eb] flex flex-col items-center justify-between ">
          <h2 className="text-[1.5vw] font-semibold text-center">
            Total number of custom products
          </h2>
          <h2 className="text-[2.5vw] font-bold mt-[10px]">
            {allCustomProducts.isAllCustomProductsLoading
              ? "-- --"
              : allCustomProducts.data.length}
          </h2>
          <button
            onClick={() => navigate("/dashboard/custom-products")}
            className="px-[1.5vw] py-[0.8vw] bg-red-50 text-base font-semibold rounded-2xl "
          >
            Goto custom product page
          </button>
        </div>
        <div className="w-[33.33%] h-full rounded-xl p-10 bg-[#d1e2eb] flex flex-col items-center justify-between ">
          <h2 className="text-[1.5vw] font-semibold text-center">
            Total number of categories
          </h2>
          <h2 className="text-[2.5vw] font-bold mt-[10px]">
            {allCategories.isLoading ? "-- --" : allCategories.data.length}
          </h2>
          <button
            onClick={() => navigate("/dashboard/categories")}
            className="px-[1.5vw] py-[0.8vw] bg-red-50 text-base font-semibold rounded-2xl "
          >
            Goto category page
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashOverview;
