import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL_LOCAL;

// The Users here are actually admins as we are not dealing with customers.

export const getAllUsers = createAsyncThunk(
  "usersAdminSlice/getAllUsers",
  async () => {
    try {
      // Make the API request here
      const response = await axios.get(`${baseUrl}/users`);
      return response.data; // Assuming the response contains productData
    } catch (error) {
      throw error;
    }
  }
);

export const deleteUser = createAsyncThunk(
  "usersAdminSlice/deleteUser",
  async (id) => {
    try {
      // Make the API request here
      const response = await axios.delete(`${baseUrl}/users/${id}`);
      return response.data; // Assuming the response contains productData
    } catch (error) {
      throw error;
    }
  }
);

export const addNewUser = createAsyncThunk(
  "usersAdminSlice/addNewUser",
  async (data) => {
    try {
      // Make the API request here
      const response = await axios.post(`${baseUrl}/users`, data);
      return response.data; // Assuming the response contains productData
    } catch (error) {
      throw error;
    }
  }
);

const usersAdminSlice = createSlice({
  name: "usersAdminSlice",
  initialState: {
    allUsers: {
      isLoading: false,
      data: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state, action) => {
        state.allUsers.isLoading = true;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.allUsers.isLoading = false;
        console.error(action.error.message);
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        const allUsers = action.payload;
        state.allUsers.isLoading = false;
        state.allUsers.data = allUsers;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const deletedUser = action.payload;
        state.allUsers.data = state.allUsers.data.filter(
          (user) => user.id !== deletedUser.id
        );
      })
      .addCase(deleteUser.rejected, (state, action) => {
        console.error(action.error.message);
        throw new Error(action.error.message);
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        const { user: createdUser } = action.payload;
        state.allUsers.data.push(createdUser);
        console.log(createdUser);
      })
      .addCase(addNewUser.rejected, (state, action) => {
        console.error(action.error.message);
        throw new Error(action.error.message);
      });
  },
});

export const {} = usersAdminSlice.actions;

export const selectAllUsers = (state) => state.usersAdminState.allUsers;

export default usersAdminSlice.reducer;
