import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Box, Button, Grid, Rating, Typography } from "@mui/material";
import toast from "react-hot-toast";
import axios from "axios";

function AddReviewForm({ id = null }) {
  const inputStyle = {
    width: "100%",
    padding: "10px 12px",
    outline: "none",
    borderRadius: "4px",
    fontSize: "14px",
    border: "1px solid gray",
    marginTop: "2px",
  };
  const buttonStyle = {
    padding: "10px 16px",
    background: "blue",
    cursor: "pointer",
    color: "white",
    fontSize: "16px",
    border: "none",
    borderRadius: "4px",
    fontWeight: "600",
  };

  const labelStyle = {
    fontSize: "16px",
    fontWeight: "400",
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    opacity: 0.5,
    cursor: "not-allowed",
  };

  const {
    isSubmitting,
    submitCount,
    errors,
    values,
    handleSubmit,
    handleChange,
    resetForm,
  } = useFormik({
    initialValues: {
      rating: 0,
      content: "",
      name: "",
      email: "",
    },
    validationSchema: Yup.object({
      rating: Yup.number().required().min(1),
      content: Yup.string().required(),
      name: Yup.string().required("Name is required"),
      email: Yup.string().email("Invalid Email").required("Email is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const url = `${process.env.REACT_APP_API_URL_LOCAL}/products/${id}/reviews`;
      const postData = {
        ...values,
        product_id: id,
      };
      try {
        setSubmitting(true);

        toast.promise(
          axios.post(url, postData),
          {
            loading: "Sending...",
            success: "Successfully Submitted",
            error: "Something Went Wrong",
          },
          {
            error: {
              duration: 800,
            },
          }
        ).then(() => {
          resetForm(); // Reset form after successful submission
        });
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          {/* Errors... */}
          {/* {submitCount > 0 && Object.keys(errors).length !== 0 ? (
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              {Object.keys(errors).map((key, index) => (
                <Typography sx={{color:"red"}} key={index}>{errors[key]}</Typography>
              ))}
            </Grid>
          ) : null} */}

          {/* Ratings... */}
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{
                color: submitCount > 0 && errors.rating ? "red" : "black",
                ...labelStyle,
                mb: 1,
              }}
            >
              Your Ratings*
            </Typography>
            <Rating
              name="rating"
              id="rating"
              onChange={handleChange}
              value={values.rating}
            />
          </Grid>

          {/* Content... */}
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{
                color: submitCount > 0 && errors.content ? "red" : "black",
                ...labelStyle,
                row: "5",
              }}
              size="small"
            >
              Your review*
            </Typography>
            <textarea
              rows={4}
              style={{ ...inputStyle, resize: "vertical" }}
              onChange={handleChange}
              type="text"
              id="content"
              name="content"
              value={values.content} // Add this line to bind the value
            ></textarea>
          </Grid>

          {/* Name... */}
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{
                color: submitCount > 0 && errors.name ? "red" : "black",
                ...labelStyle,
              }}
              size="small"
            >
              Name*
            </Typography>
            <input
              style={inputStyle}
              type="text"
              id="name"
              name="name"
              onChange={handleChange}
              value={values.name} // Add this line to bind the value
            />
          </Grid>

          {/* Email... */}
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{
                color: submitCount > 0 && errors.email ? "red" : "black",
                ...labelStyle,
              }}
              size="small"
            >
              Email*
            </Typography>
            <input
              style={inputStyle}
              type="text"
              id="email"
              name="email"
              onChange={handleChange}
              value={values.email} // Add this line to bind the value
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              type="submit"
              disabled={isSubmitting}
              sx={{
                bgcolor: "#fff",
                color: "#040707",
                border: "1px solid #040707",
                "&:hover": {
                  border: "#f26728 solid 1px",
                  color: "#f26728",
                  backgroundColor: "rgba(242, 103, 40, 0.1)",
                },
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default AddReviewForm;
