import React from "react";
import { featuredCategories } from "../../config/constants";
import ParallaxSection from "./ParallaxSection";
import { useNavigate } from "react-router";

const Categories = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full p-[30px] md:px-[3vw] md:py-[3vw] leading-none bg-white h-fit flex items-center justify-center flex-col ">
      <div className="w-full h-fit">
        <p className="text-[8dvw] md:text-[3vw] font-normal">Top Categories</p>
      </div>
      <div className="categories flex items-center justify-center flex-wrap h-fit w-full gap-[5dvw] md:gap-[1.5vw] my-7">
        {featuredCategories.map((item, index) => (
          <div onClick={()=> navigate(`/category/subcategory/${item.id}`)} className="w-full md:w-[48%] h-[45dvw] md:h-[20vw] shadow-lg rounded-xl overflow-hidden relative grayscale hover:grayscale-0 transition-all ease-linear cursor-pointer">
            <ParallaxSection
              width={"100%"}
              height={"100%"}
              imageSrc={item.img}
              // noScaleUp
              categoryName={item.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
