import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import noImageFound from "../../../assets/images/no-image-icon-23494.png";

const AllCard = ({ data, handleDelete, redirectUrl }) => {
  // console.log(data);
  if (Array.isArray(data?.product_images)) {
    const primaryImage = data?.product_images.find(
      (img) => img.type === "primary"
    );

    if (primaryImage) {
      var primaryImageUrl = `${
        process.env.REACT_APP_API_URL_LOCAL
      }/uploads/${encodeURIComponent(primaryImage.image_url)}`;
      // console.log("Primary Image URL:", primaryImageUrl);
    } else {
      // console.log("No primary image found for the product");
    }
  }

  return (
    <Card
      sx={{ width: "100%", height: "220px", bgcolor: "#fff" }}
      elevation={3}
    >
      <Link
        to={`${redirectUrl}${data.c_id ? data.c_id : data.id ? data.id : ""}`}
      >
        {/* <Box
          sx={{
            width: "100%",
            height: "80%",
            backgroundImage: `url(${
              data?.front_image_src
                ? data?.front_image_src
                : Array.isArray(data?.product_images)
                ? primaryImageUrl
                : "https://sportscustomuniform.com/virtual_images/usa_sfn_jersey_back_109.jpg"
            })`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Box> */}

        <CardMedia
          component="img"
          // height="80%"
          width={"100%"}
          image={
            data?.front_image_src
              ? data?.front_image_src
              : Array.isArray(data?.product_images)
              ? primaryImageUrl
              : data?.image_url
              ? `${
                  process.env.REACT_APP_API_URL_LOCAL
                }/uploads/${encodeURIComponent(data?.image_url)}`
              : noImageFound
          }
          alt=""
          sx={{
            objectFit: "contain",
            objectPosition: "center",
            maxHeight: "80%",
            minHeight: "80%",
            overflow: "hidden",
            aspectRatio: "1/1",
          }}
        />
      </Link>
      <Divider />
      <Box
        sx={{
          width: "100%",
          maxHeight: "20%",
          height: "20%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            p: "3px 10px",
            width: "80%",
          }}
        >
          <Link
            to={`${redirectUrl}${
              data.c_id ? data.c_id : data.id ? data.id : ""
            }`}
          >
            <Tooltip
              title={data?.product_name ? data?.product_name : data?.name}
              placement="top"
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data?.product_name ? data?.product_name : data?.name}
              </Typography>
            </Tooltip>
            <Tooltip title={data?.sku}>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: 400,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                SKU: {data?.sku}
              </Typography>
            </Tooltip>
          </Link>
        </Box>
        <Box sx={{ width: "20%" }}>
          <Tooltip title="Delete Product">
            <IconButton
              onClick={() => handleDelete(data?.c_id ? data.c_id : data.id)}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Card>
  );
};

export default AllCard;
