import React from "react";
import AllUsersContainer from "../../../components/Dashboard/User/AllUsersContainer";

const Users = () => {
  return (
    <div>
      <AllUsersContainer />
    </div>
  );
};

export default Users;
