import { createSlice } from "@reduxjs/toolkit";
// import { paymentMethods, shippingMethods } from "../config/constants";

let rosterItemIdCounter = 3;

const initialState = {
  cartItems: [
    // {
    //     productId: 1,
    //     productDescription: '2 Pieces Mango set- Regular fit',
    //     unitPrice: 24,
    //     color: "red",
    //     roster: [
    //         {
    //             id: 1,
    //             number: null,
    //             playerName: "",
    //             topSize: "YXS",
    //             bottomSize: "YXS",
    //             qty: 1,
    //         }
    //     ]
    // },
    //     {
    //       "number": 12,
    //       "name": "Ahmad",
    //       "topSize": "YS",
    //       "bottomSize": "YS",
    //       "qty": 1,
    //       "price": 25
    //     },
    // ]


    // ""productRoster": [
    // {
    //     "number": 12,
    //     "name": "Ahmad",
    //     "topSize": "YS",
    //     "bottomSize": "YS",
    //     "qty": 1,
    //     "price": 25
    //   }
    // ]

    
  ],
  customer: null,
  // paymentMethod: paymentMethods[0],
  // shippingMethod: shippingMethods[0],
  productionType: "normal",
  discountCode: null,
  minimumFee: 60,
  minimumQty: 8,
  tabIndex: 0,
  totalAmount: 0,
  totalQuantity: 0,
};

const virtualCartSlice = createSlice({
  name: "virtualCartSlice",
  initialState,
  reducers: {
    // Set discount Code...

    setDiscountCode: (state, action) => {
      state.discountCode = action.payload;
      calculateTotal(state);
    },

    //Shipping and Payment Method related actions...

    setShippingMethod: (state, action) => {
      state.shippingMethod = action.payload;
      calculateTotal(state);
    },

    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
      calculateTotal(state);
    },

    //Cart Component tabIndex related actions...
    setTabIndex: (state, action) => {
      if (action.payload !== 3) {
        state.tabIndex = action.payload;
      } else {
        if (state.customer === null) {
          state.tabIndex = 1; // Go to Customer information tab to fill the required fields
        } else {
          state.tabIndex = action.payload; // Go to Confirmation Tab
        }
      }
    },

    //customer related actions...
    addCustomer: (state, action) => {
      state.customer = action.payload;
    },

    //RosterItems Related actions....
    addRosterItem: (state, action) => {
      const { productId, rosterItem } = action.payload;

      const product = state.cartItems.find(
        (item) => item.productId === productId
      );
      if (product) {
        const id = rosterItemIdCounter++;

        product.roster.push({ ...rosterItem, id });
      }

      calculateTotal(state);
    },
    deleteRosterItem: (state, action) => {
      const { productId, rosterItemId } = action.payload;
      const product = state.cartItems.find(
        (item) => item.productId === productId
      );
      if (product !== -1) {
        product.roster = product.roster.filter(
          (item) => item.id !== rosterItemId
        );
      }

      calculateTotal(state);
    },

    updateRosterItem: (state, action) => {
      const { productId, updatedRosterItem } = action.payload;
      const product = state.cartItems.find(
        (item) => item.productId === productId
      );
      if (product) {
        // Find the index of the roster item to edit
        const index = product.roster.findIndex(
          (item) => item.id === updatedRosterItem.id
        );
        if (index !== -1) {
          // Update the roster item with the new values
          product.roster[index] = updatedRosterItem;
        }
      }

      calculateTotal(state);
    },

    //Cart Items related actions...

    addCartItem: (state, action) => {
      const { productId, unitPrice } = action.payload;
      state.cartItems = [
        {
          productId,
          productDescription: "3 Pieces Strawberry set- Slim fit",
          unitPrice,
          color: "red",
          roster: [
            {
              id: 1,
              number: null,
              playerName: "",
              topSize: "YS",
              bottomSize: "YS",
              qty: 1,
            },
          ],
        },
      ];
    },

    deleteCartItem: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (cartItem) => cartItem.productId !== action.payload
      );

      calculateTotal(state);
    },

    // Production Types related actions...
    changeProductionType: (state, action) => {
      state.productionType = action.payload;

      calculateTotal(state);
    },
  },
});

// Calculating the total Amount...
const calculateTotal = (state) => {
  let totalAmount = 0;
  let totalQuantity = 0;

  // Iterate through cartItems
  state.cartItems.forEach((item) => {
    item.roster.forEach((rosterItem) => {
      if (rosterItem.playerName && rosterItem.number && rosterItem.qty) {
        totalAmount += item.unitPrice * Number(rosterItem.qty); // Calculate total amount
        totalQuantity += Number(rosterItem.qty); // Calculate total quantity
      }
    });
  });

  totalAmount += state.shippingMethod.description.shippingCost;

  if (totalQuantity < state.minimumQty) totalAmount += state.minimumFee;

  if (state.productionType === "express")
    totalAmount = totalAmount + totalAmount * 0.15;
  else if (state.productionType === "super_express")
    totalAmount = totalAmount + totalAmount * 0.3;

  state.totalAmount = totalAmount;
  state.totalQuantity = totalQuantity;
};

export const {
  setDiscountCode,

  setPaymentMethod,
  setShippingMethod,

  setTabIndex,

  addCustomer,

  addRosterItem,
  updateRosterItem,
  deleteRosterItem,

  addCartItem,
  deleteCartItem,

  changeProductionType,
} = virtualCartSlice.actions;
export default virtualCartSlice.reducer;
