import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFrontObject,
  addFrontPathBackend,
  changeBackPathColor,
  changeFrontPath,
  changeFrontPathColor,
  changeFrontPathTitle,
  changePathObjIsSaved,
  changeTextObjIsSaved,
  deleteFrontObject,
  deleteFrontPathBackend,
  selectFrontPaths,
  updateFrontPathBackend,
} from "../../slices/adminCanvasSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { clothingColors, twitterPickerStyle } from "../../config/constants";
import { TwitterPicker } from "react-color";
import { useParams } from "react-router";
import SaveIcon from "@mui/icons-material/Save";
import toast from "react-hot-toast";
import ControlMenuButton from "./ControlMenuButton";

const FrontPathsEditor = () => {
  const { c_id } = useParams();
  const dispatch = useDispatch();
  const frontPathsArr = useSelector(selectFrontPaths);
  const [apiLoading, setApiLoading] = useState(false);

  const handleDeletePath = (pathObj) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          setApiLoading(true);
          await dispatch(deleteFrontPathBackend(pathObj?.id));
          dispatch(deleteFrontObject({ objectID: pathObj?.id }));
          resolve("Deleted!");
        } catch (error) {
          console.error(error);
          reject(error);
        } finally {
          setApiLoading(false);
        }
      }),
      {
        loading: "Deleting...",
        success: "Deleted!",
        error: "Error",
      }
    );
  };

  const handleUpdatePath = (pathObj) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const updated = await dispatch(
            updateFrontPathBackend({
              ...pathObj,
            })
          );
          if (updated) {
            dispatch(
              changePathObjIsSaved({
                targetID: pathObj.id,
                value: true,
                applyTo: "front",
              })
            );
          }
          resolve("Saved!");
        } catch (error) {
          console.error(error);
          reject(error);
        }
      }),
      {
        loading: "Saving...",
        success: "Saved!",
        error: "Error",
      }
    );
  };

  const pathActions = [
    {
      title: "Delete",
      handleClick: handleDeletePath,
      icon: DeleteIcon,
      color: "#be0000",
    },
    {
      title: "Save",
      handleClick: handleUpdatePath,
      icon: SaveIcon,
      color: "#008000",
    },
  ];

  return (
    <div>
      <Grid container>
        {frontPathsArr?.map((pathObj, index) => {
          return (
            <Grid item xs={12} md={6} lg={4} xl={3} sx={{}}>
              <Box sx={{ m: 1, p: 1 }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "fit-content",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px", fontWeight: 500, color: "#262626" }}
                  >
                    Edit Layer {index + 1}
                  </Typography>
                  <ControlMenuButton
                    actions={pathActions}
                    textOrPathObj={pathObj}
                  />
                  {/* <IconButton
                    disabled={apiLoading ? true : false}
                    onClick={async () => {
                      setApiLoading(true);
                      try {
                        const deletedPath = await dispatch(
                          deleteFrontPathBackend(pathObj?.id)
                        );
                        dispatch(deleteFrontObject({ objectID: pathObj?.id }));
                      } catch (error) {
                      } finally {
                        setApiLoading(false);
                      }
                    }}
                  >
                    {apiLoading ? (
                      <CircularProgress
                        size={"24px"}
                        sx={{ color: "#555555" }}
                      />
                    ) : (
                      <DeleteIcon
                        sx={{ color: apiLoading ? "lightgrey" : "#c8102e" }}
                      />
                    )}
                  </IconButton> */}
                </Box>
                <TextField
                  size="small"
                  placeholder="Enter Title For Your Path Object"
                  label={"Title"}
                  value={pathObj?.title}
                  onChange={(e) => {
                    dispatch(
                      changeFrontPathTitle({
                        targetID: pathObj?.id,
                        newTitle: e.target.value,
                      })
                    );
                  }}
                  fullWidth
                  variant="outlined"
                  sx={{
                    my: 1,
                    width: "100%",
                    minWidth: "100%",
                    color: "#7d888d",
                    "& label": {
                      color: "#697176",
                      fontSize: "18px",
                    },
                    "& label.Mui-focused": {
                      color: "#697176",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#7d888d",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&:hover fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#7d888d",
                      },
                    },
                    input: {
                      color: "#262626",
                    },
                  }}
                />
                <TextField
                  size="small"
                  placeholder="Enter Path For Your Base Layer"
                  label={"Path"}
                  value={pathObj?.path}
                  onChange={(e) => {
                    dispatch(
                      changeFrontPath({
                        newPath: e.target.value,
                        pathTitle: pathObj?.title,
                      })
                    );
                  }}
                  multiline={true}
                  maxRows={2}
                  minRows={1}
                  fullWidth
                  variant="outlined"
                  sx={{
                    my: 1,
                    width: "100%",
                    minWidth: "100%",
                    color: "#7d888d",
                    "& label": {
                      color: "#697176",
                      fontSize: "18px",
                    },
                    "& label.Mui-focused": {
                      color: "#697176",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#7d888d",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&:hover fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#7d888d",
                      },
                    },
                    input: {
                      color: "#e2e2e2",
                    },
                  }}
                />
                <TwitterPicker
                  styles={twitterPickerStyle}
                  color={pathObj?.color}
                  width="100%"
                  triangle="hide"
                  colors={clothingColors}
                  onChange={(e) => {
                    dispatch(
                      changeFrontPathColor({
                        pathTitle: pathObj?.title,
                        newColor: e.hex,
                      })
                    );
                    dispatch(
                      changeBackPathColor({
                        pathTitle: pathObj?.title,
                        newColor: e.hex,
                      })
                    );
                  }}
                />
              </Box>
            </Grid>
          );
        })}
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Box
            sx={{
              m: 1,
              p: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              fullWidth
              size="small"
              variant="contained"
              disableElevation
              disableRipple
              sx={{
                bgcolor: "#262626",
                color: "#fff",
                "&:hover": {
                  color: "#fff",
                  bgcolor: "#262626",
                },
              }}
              disabled={apiLoading ? true : false}
              onClick={() => {
                toast.promise(
                  new Promise(async (resolve, reject) => {
                    const randomTitle = Math.random()
                      .toString(36)
                      .substring(2, 7);
                    const randomId = Math.random().toString(36).substring(2, 7);
                    const color = "#ff0";
                    try {
                      setApiLoading(true);

                      await dispatch(
                        addFrontPathBackend({
                          c_id,
                          title: randomTitle,
                          path: "",
                          color: color,
                          id: randomId,
                        })
                      );
                      // dispatch(
                      //   addFrontObject({
                      //     newObject: {
                      //       path: "",
                      //       title: randomTitle,
                      //       color: color,
                      //       id: randomId,
                      //       type: "path",
                      //     },
                      //   })
                      // );
                      resolve("Added!");
                    } catch (error) {
                      console.error(error);
                      reject(error);
                    } finally {
                      setApiLoading(false);
                    }
                  }),
                  {
                    loading: "Adding...",
                    success: "Added!",
                    error: "Error",
                  }
                );
              }}
            >
              Add Path
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default FrontPathsEditor;
