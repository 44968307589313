import AuthLayout from "../components/Auth/AuthLayout";
import * as reviews from "../views";

export const routes = [
  {
    id: "001",
    path: "/",
    element: reviews.LandingPage,
    exact: true,
    children: [
      {
        id: "111",
        path: "",
        element: reviews.AnimatedLandingPage,
      },
      {
        id: "112",
        path: "/contact",
        element: reviews.Contact,
      },
      {
        id: "123",
        path: "/about",
        element: reviews.About,
      },
      {
        id: "112",
        path: "/how-to-order",
        element: reviews.HowToOrder,
      },
      // {
      //   id: "112",
      //   path: "/cart",
      //   element: reviews.Cart,
      // },
      {
        id: "002",
        path: "/customize/:custom_product_id",
        element: reviews.Customize,
      },
    ],
  },
  {
    id: "002",
    path: "/products/:categoryId",
    element: reviews.Product,
  },
  {
    id: "002",
    path: "/landing-page",
    element: reviews.AnimatedLandingPage,
  },
  {
    id: "002",
    path: "/product/:productId",
    element: reviews.ProductPage,
  },
  {
    id: "002",
    path: "/category",
    element: reviews.Category,
  },
  {
    id: "002",
    path: "/category/subcategory/:categoryId",
    element: reviews.SubCategory,
  },
  {
    id: "002",
    path: "/shop",
    element: reviews.Shop,
  },
  {
    id: "002",
    path: "/login",
    element: reviews.Login,
  },
  {
    id: "002",
    path: "/sign-up",
    element: reviews.SignUp,
  },
  {
    id: "002",
    path: "/create-new-product",
    element: reviews.AdminCreateProduct,
  },
  {
    id: "00",
    path: "*",
    element: reviews.PageNotFound,
  },
  {
    id: "00",
    path: "under-construction",
    element: reviews.UnderConstruction,
  },
  {
    id: "100",
    path: "/admin/login",
    element: reviews.AdminLogin,
  },

  // Admin Routes ...
  {
    id: "290",
    path: "/dashboard",
    element: reviews.Dashboard,
    exact: true,
    children: [
      {
        id: "111",
        path: "",
        element: reviews.DashOverview,
      },
      {
        id: "111",
        path: "categories",
        element: reviews.DashCategories,
      },
      {
        id: "112",
        path: "products",
        element: reviews.DashProduct,
        children: [
          {
            id: "877",
            path: "",
            element: reviews.DashProduct,
          },
          {
            id: "877",
            path: ":id",
            element: reviews.SingleProduct,
          },
        ],
      },
      {
        id: "115",
        path: "custom-products",
        element: reviews.CustomProduct,
        children: [
          {
            id: "877",
            path: "",
            element: reviews.CustomProduct,
          },
          {
            id: "877",
            path: ":c_id",
            element: reviews.AdminCreateProduct,
          },
        ],
      },
      {
        id: "121",
        path: "orders",
        element: reviews.Orders,
      },
      {
        id: "654",
        path: "users",
        element: reviews.Users,
      },
    ],
  },
];
