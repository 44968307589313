import React, { useState } from "react";
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Button,
  CssBaseline,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { buttonStyleMui, dashboardLinkItems } from "../../../config/constants";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
// import logo from "../../../assets/images/adnan-arshad-logo.png";
import logo from "../../../assets/images/favicon.png";
import AddIcon from "@mui/icons-material/Add";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { addNewUser } from "../../../slices/usersAdminSlice";
import AddNewUserDialog from "./AddNewUserDialog";

const SideDrawer = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const drawerWidth = 250;

  const location = useLocation();

  const handleLogOut = () => {
    const confirmLogOut = () => {
      const confirmed = window.confirm("Are you sure you want to Logout?");
      if (confirmed) {
        sessionStorage.clear();
        window.location.reload();
      }
    };
    confirmLogOut();
  };

  // const handleUserSignup = async (userId) => {
  //   const confirmed = window.confirm("Are you sure want to delete the user?");
  //   if (confirmed) {
  //     try {
  //       await toast.promise(
  //         new Promise(async (resolve, reject) => {
  //           try {
  //             await dispatch(addNewUser(data));
  //             resolve("User deleted successfully!");
  //           } catch (error) {
  //             console.error(error);
  //             reject(error);
  //           }
  //         }),
  //         {
  //           loading: "Deleting...",
  //           success: "User deleted successfully!",
  //           error: "Failed to delete user",
  //         }
  //       );
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   } else {
  //     alert("Cancelled!");
  //   }
  // };

  const [openDialog, setOpenDialog] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    name: "",
  });

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeDialog = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmitDialog = async () => {
    // Handle form submission logic here
    console.log(formValues);
    try {
      await toast.promise(
        new Promise(async (resolve, reject) => {
          try {
            await dispatch(addNewUser(formValues));
            resolve("User deleted successfully!");
          } catch (error) {
            console.error(error);
            reject(error);
          }
        }),
        {
          loading: "Creating new user...",
          success: "User created successfully!",
          error: "Failed to create user",
        }
      );
    } catch (error) {
      console.error(error);
    }
    setFormValues({
      email: "",
      password: "",
      name: "",
    });
    handleCloseDialog();
  };
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            <Box
              sx={{
                padding: 2,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <img src={logo} alt="logo" style={{ width: "30px" }} />
                </Box>
                <List>
                  {dashboardLinkItems.map((item, index) => (
                    <Link
                      key={index}
                      to={item.to}
                      underline="none"
                      style={{
                        textDecoration: "none",
                        color: "#262626",
                      }}
                    >
                      <ListItem
                        button
                        onClick={handleClose}
                        disableRipple
                        sx={{
                          "&:hover": {
                            bgcolor:
                              location.pathname === item.to
                                ? "#d1e2eb"
                                : "#f1f6f9",
                          },
                          borderRadius: "10px",
                          bgcolor:
                            location.pathname === item.to ? "#d1e2eb" : "none",
                          my: "4px",
                        }}
                      >
                        <ListItemText primary={item.text} />
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </Box>
              <div className="flex items-center justify-around">
                <Button
                  onClick={handleLogOut}
                  variant="text"
                  disableRipple
                  disableElevation
                  sx={{
                    "&:hover": { bgcolor: "rgba(255,0,0,0.9)", color: "white" },
                    color: "red",
                    width: "70%",
                  }}
                >
                  Log Out
                </Button>
                <IconButton
                  onClick={handleClickOpenDialog}
                  variant="text"

                  // disableRipple
                  // disableElevation
                  // sx={{
                  //   "&:hover": { bgcolor: "rgba(0,255,0,0.9)", color: "white" },
                  //   color: "green",
                  // }}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </Box>
          </Drawer>
        </Box>
      </Box>
      <AddNewUserDialog
        open={openDialog}
        formValues={formValues}
        handleClickOpen={handleClickOpenDialog}
        handleClose={handleCloseDialog}
        handleChange={handleChangeDialog}
        handleSubmit={handleSubmitDialog}
      />
    </>
  );
};

export default SideDrawer;
