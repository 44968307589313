import { Box, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";

import FrontSideEditor from "./FrontSideEditor";
import BackSideEditor from "./BackSideEditor";
import { useState } from "react";
import MainPathsEditor from "./MainPathsEditor";
import MaskImageEditor from "./MaskImageEditor";
import TextAndNumbersEditor from "./TextAndNumbersEditor";
import AdditionalLayersEditor from "./AdditionalLayersEditor";

const AdminCanvasEditor = () => {
  const [tabIndexAdminCanvas, setTabIndexAdminCanvas] = useState(0);
  const handleChange = (e, newValue) => {
    setTabIndexAdminCanvas(newValue);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          my: 2,
        }}
      >
        {/* <FrontSideEditor />
        <BackSideEditor /> */}
        <Tabs
          variant={isSmallScreen ? "scrollable" : "fullWidth"}
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={tabIndexAdminCanvas}
          onChange={handleChange}
          sx={{
            my: 2,
            ".MuiTabs-indicator": {
              // display: "none",
              bgcolor: "red",
            },
          }}
        >
          <Tab
            sx={{
              ".Mui-focusVisible": { backgroundColor: "rgba(255, 0, 0, 0.3)" },
              "&.Mui-selected": {
                color: "red", // Active (selected) tab color
              },
            }}
            label="Main Paths Editor"
          />
          <Tab
            sx={{
              ".Mui-focusVisible": { backgroundColor: "rgba(255, 0, 0, 0.3)" },
              "&.Mui-selected": {
                color: "red", // Active (selected) tab color
              },
            }}
            label="Mask Image"
          />
          <Tab
            sx={{
              ".Mui-focusVisible": { backgroundColor: "rgba(255, 0, 0, 0.3)" },
              "&.Mui-selected": {
                color: "red", // Active (selected) tab color
              },
            }}
            label="Text and Numbers"
          />
        </Tabs>
        <Box sx={{ width: "100%", height: "fit-content" }}>
          {tabIndexAdminCanvas === 0 && <MainPathsEditor />}
          {tabIndexAdminCanvas === 1 && <MaskImageEditor />}
          {tabIndexAdminCanvas === 2 && <TextAndNumbersEditor />}
          {/* {tabIndexAdminCanvas === 3 && <AdditionalLayersEditor />} */}
        </Box>
      </Box>
    </>
  );
};

export default AdminCanvasEditor;
