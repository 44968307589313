import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import SideDrawer from "../Category/SideDrawer";
import ProductCard from "../Common/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories, fetchProducts } from "../../slices/userCategorySlice";
import { Bars } from "react-loader-spinner";
import { useParams } from "react-router";

const ProductContainer = ({ label = false }) => {
  const { categoryId } = useParams();

  const dispatch = useDispatch();
  const { userProducts, prodLoading, prodError } = useSelector(
    (state) => state.userCategoryState
  );

  const labelStyle = {
    fontWeight: "500",
    fontSize: "20px",
    textRendering: "optimizeLegibility",
  };

  useEffect(() => {
    dispatch(fetchProducts(categoryId));
  }, [dispatch, categoryId]);

  // console.log(userProducts?.all_products[0]);

  // Filter products with custom_product_id null
  const customProducts = userProducts?.all_products?.filter(
    (product) => product.custom_product_id !== null
  );

  // Filter non custom products
  const nonCustomProducts = userProducts?.all_products?.filter(
    (product) => product.custom_product_id === null
  );

  // Filter package products
  const packageProducts = nonCustomProducts?.filter(
    (product) => product.type === "package"
  );

  // Single products
  const singleProducts = nonCustomProducts?.filter(
    (product) => product.type === "single"
  );

  // console.log(prodError);;

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchProducts(categoryId));
  }, [dispatch]);

  if (prodLoading) {
    if (label) return null; // Two avoid two spinner loading icons...
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Typography>Loading ...</Typography> */}
        {/* <CircularProgress/> */}
        <Bars
          height="45"
          width="45"
          color="gray"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </Box>
    );
  }
  if (prodError) return <>Error</>;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* <SideDrawer /> */}
      {label && (
        <Typography sx={{ fontWeight: "700", fontSize: "24px" }}>
          Products
        </Typography>
      )}

      <Grid container spacing={4}>
        {packageProducts?.length !== 0 && packageProducts?.length && (
          <>
            <Grid item xs={12}>
              <Typography sx={labelStyle}>Pakages</Typography>
            </Grid>
            {packageProducts?.map((product) => (
              <Grid item xs={12} sm={4} md={3} lg={2} key={product.id}>
                <ProductCard productData={product} />
              </Grid>
            ))}
          </>
        )}

        {customProducts?.length !== 0 && customProducts?.length && (
          <>
            <Grid item xs={12}>
              <Typography sx={labelStyle}>Customize Products</Typography>
            </Grid>
            {customProducts?.map((product) => (
              <Grid item xs={12} sm={4} md={3} lg={2} key={product.id}>
                <ProductCard productData={product} />
              </Grid>
            ))}
          </>
        )}

        {singleProducts?.length !== 0 && singleProducts?.length && (
          <>
            <Grid item xs={12}>
              <Typography sx={labelStyle}>Single Products</Typography>
            </Grid>
            {singleProducts?.map((product) => (
              <Grid item xs={12} sm={4} md={3} lg={2} key={product.id}>
                <ProductCard productData={product} />
              </Grid>
            ))}
          </>
        )}

        {/* If there are no products */}
        {(userProducts?.all_products?.length === 0 ||
          !userProducts?.all_products) && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              mt: 3,
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontStyle: "italic",
                color: "#333333",
                mb: 2
              }}
              variant="h6"
            >
              No Products Found
            </Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default ProductContainer;
