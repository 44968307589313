import React from "react";
import LoginSignupForm from "../../components/Common/LoginSignupForm";
import { Helmet } from "react-helmet";

const Login = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Login | US Sports</title>
      </Helmet>
      <LoginSignupForm />
    </>
  );
};

export default Login;
