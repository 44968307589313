import React from "react";
import Footer from "../../components/LandingPage/Footer/Footer";
import Breadcrumb from "../../components/Category/Breadcrumb";
import CategoryContainer from "../../components/Category/CategoryContainer";
import Navbar from "../../components/AnimatedLandingPage/Navbar";
import { Helmet } from "react-helmet";

const Category = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Category | US Sports</title>
      </Helmet>
      {/* <Header /> */}
      <Navbar />
      <Breadcrumb items={[]} currentTitle="Categories" />
      {/* <SubcategoryHeader subcategory={false} /> */}
      {/* <SubcategoryContainer data={userCategories} /> */}
      <div className="min-h-[40vh]">
        <CategoryContainer />
      </div>
      <Footer />
    </>
  );
};

export default Category;
