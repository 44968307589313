import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem,
  ListItemText,
  List,
  Tooltip,
} from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { Bars } from "react-loader-spinner";
import Topbar from "../AllContainer/Topbar";
import UploadIcon from "@mui/icons-material/Upload";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateCategoryDialog from "./UpdateCategoryDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCategory,
  selectIsCategoryDeleting,
  selectIsCategoryUpdating,
  uploadCategoryImage,
} from "../../../slices/categoriesSlice";
import { buttonStyleMui } from "../../../config/constants";
import ImageDialog from "./ImageDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import toast from "react-hot-toast";

const tableCellStyle = {
  "&.MuiTableCell-root": {
    py: 0,
  },
};

// Recursive function to build the category tree and make Accordion
export function CategoryTree({
  categories,
  onAddSubCategory,
  handleUpdateCategory,
  handleDeleteCategory,
  isCategoryDeleting,
  handleFileInputChange,
  isCategoryUpdating,
  imageUploadRef,
  handleOpenDialog,
  handleChange,
}) {
  const [tree, setTree] = useState([]);

  useEffect(() => {
    // Function to build the category tree
    const buildTree = (categories, parentId = null) => {
      return categories
        .filter((category) => category.parent_category_id === parentId)
        .map((category) => ({
          ...category,
          children: buildTree(categories, category.id),
        }));
    };

    // Build the tree from categories
    const categoryTree = buildTree(categories);
    setTree(categoryTree);
  }, [categories]);

  // Recursive function to render tree nodes with add subcategory button
  const renderTree = (nodes, depth = 0) => (
    <div>
      {nodes.map((node, index) => {
        return depth < 2 ? (
          <Accordion key={node.id}>
            <AccordionSummary expandIcon={depth < 2 && <ExpandMoreIcon />}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>{node.name}</Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Tooltip title="Edit Category">
                    <IconButton
                      onClick={() => handleUpdateCategory({ ...node })}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Category">
                    <IconButton
                      disabled={isCategoryDeleting}
                      onClick={() => handleDeleteCategory(node.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <input
                    type="file"
                    accept="image/*"
                    ref={imageUploadRef}
                    style={{ display: "none" }}
                    onChange={handleFileInputChange}
                  />
                  <Tooltip title="Upload/Change image">
                    <IconButton
                      disabled={isCategoryUpdating}
                      onClick={() => handleChange(node.id)}
                    >
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                  <Box
                    sx={{
                      width: "2vw",
                      height: "2vw",
                      // bgcolor: "yellowgreen",
                      backgroundImage: node?.category_img_url
                        ? `url("${process.env.REACT_APP_API_URL_LOCAL}/uploads/${node?.category_img_url}")`
                        : `url("https://static-00.iconduck.com/assets.00/no-image-icon-256x256-blc2175p.png")`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      
                      mr: 2,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenDialog(
                        `${process.env.REACT_APP_API_URL_LOCAL}/uploads/${node?.category_img_url}`
                      );
                    }}
                  ></Box>
                  {depth < 2 && (
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        e.stopPropagation();
                        onAddSubCategory?.(node.id);
                      }}
                      sx={{ mr: 2, p: "2px 5px", textTransform: "none" }}
                    >
                      Add Subcategory
                    </Button>
                  )}
                </Box>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              {depth < 2 && renderTree(node.children, depth + 1)}
            </AccordionDetails>
          </Accordion>
        ) : (
          <List
            sx={{
              borderBottom: "1px solid #eee",
              borderTop: index === 0 && "1px solid #eee",
            }}
          >
            <ListItem
              disablePadding
              key={node.id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography>{node.name}</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Edit Category">
                  <IconButton onClick={() => handleUpdateCategory({ ...node })}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Category">
                  <IconButton
                    disabled={isCategoryDeleting}
                    onClick={() => handleDeleteCategory(node.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <input
                  type="file"
                  accept="image/*"
                  ref={imageUploadRef}
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
                <Tooltip title="Upload/Change image">
                  <IconButton
                    disabled={isCategoryUpdating}
                    onClick={() => handleChange(node.id)}
                  >
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
                <Box
                  sx={{
                    width: "2vw",
                    height: "2vw",
                    // bgcolor: "yellowgreen",
                    backgroundImage: node?.category_img_url
                      ? `url("${process.env.REACT_APP_API_URL_LOCAL}/uploads/${node?.category_img_url}")`
                      : `url("https://static-00.iconduck.com/assets.00/no-image-icon-256x256-blc2175p.png")`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    
                  }}
                  onClick={() =>
                    handleOpenDialog(
                      `${process.env.REACT_APP_API_URL_LOCAL}/uploads/${node?.category_img_url}`
                    )
                  }
                ></Box>
                {depth < 2 && (
                  <Button
                    variant="outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      onAddSubCategory?.(node.id);
                    }}
                    sx={{ mr: 2, p: "2px 5px", textTransform: "none" }}
                  >
                    Add Subcategory
                  </Button>
                )}
              </Box>
            </ListItem>
          </List>
        );
      })}
    </div>
  );

  return <div>{renderTree(tree)}</div>;
}

// Recursive function to build the category tree and make Table
function CategoryTreeTable({ categories }) {
  const [tree, setTree] = useState([]);

  useEffect(() => {
    // Function to build the category tree
    const buildTree = (categories, parentId = null) => {
      return categories
        .filter((category) => category.parent_category_id === parentId)
        .map((category) => ({
          ...category,
          children: buildTree(categories, category.id),
        }));
    };

    // Build the tree from categories
    const categoryTree = buildTree(categories);
    setTree(categoryTree);
  }, [categories]);

  // Recursive function to render tree nodes as table rows
  const renderTreeRows = (nodes) => (
    <>
      {nodes.map((node) => (
        <React.Fragment key={node.id}>
          <TableRow>
            <TableCell>{node.name}</TableCell>
            <TableCell>{node.description}</TableCell>
          </TableRow>
          {node.children &&
            node.children.length > 0 &&
            renderTreeRows(node.children)}
        </React.Fragment>
      ))}
    </>
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="category tree">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderTreeRows(tree)}</TableBody>
      </Table>
    </TableContainer>
  );
}

const DashCategoryCard = ({
  allParentCategories,
  allCategories,
  onAddSubCategory,
}) => {
  const imageUploadRef = useRef(null);
  const dispatch = useDispatch();
  const [isUpdateCatOpen, setIsUpdateCatOpen] = useState(false);
  const [currentCategory, setcurrentCategory] = useState({});
  const [imageUploadCategoryId, setImageUploadCategoryId] = useState(null);
  const [open, setOpen] = useState(false);
  const [dialogImageUrl, setDialogImageUrl] = useState("");

  const isCategoryDeleting = useSelector(selectIsCategoryDeleting);
  const isCategoryUpdating = useSelector(selectIsCategoryUpdating);

  const handleUpdateCategory = async function (category) {
    await setcurrentCategory({ ...category });
    setIsUpdateCatOpen(true);
  };

  const categoryActions = [
    {
      title: "Edit Category",
      handleClick: () => handleUpdateCategory(currentCategory),
      icon: EditIcon,
      color: "#687175",
    },
  ];

  const handleDeleteCategory = async (id) => {
    try {
      await dispatch(deleteCategory(id));
      alert("Successfully Deleted!");
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (categoryId) => {
    setImageUploadCategoryId(categoryId);
    imageUploadRef.current.click();
    // const file = event.target.files[0];
    // const formData = new FormData();
    // formData.append("image", file);
    // formData.append("category_id", categoryId);
    // const formJson = Object.fromEntries(formData);

    // console.log(formJson);
    // try {
    //   await dispatch(uploadCategoryImage(formData));
    // } catch (error) {
    //   console.error(error.message);
    // }
  };
  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && imageUploadCategoryId) {
      const formData = new FormData();
      formData.append("image", selectedFile);
      formData.append("category_id", imageUploadCategoryId);

      const formJson = Object.fromEntries(formData);

      // console.log(formJson);

      toast.promise(
        new Promise(async (resolve, reject) => {
          try {
            await dispatch(uploadCategoryImage(formData));
            resolve();
          } catch (error) {
            reject(error);
          }
        }),
        {
          loading: "Uploading Category Image...",
          success: "Category Image uploaded successfully!",
          error: "Error While Uploading Category Image",
        }
      );
    }

    event.target.value = "";
  };

  // console.log(allCategories);

  const handleOpenDialog = (imageUrl) => {
    setDialogImageUrl(imageUrl);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: "98%", mx: "auto" }}>
      <UpdateCategoryDialog
        allCategories={allCategories}
        open={isUpdateCatOpen}
        setOpen={setIsUpdateCatOpen}
        currentCategory={currentCategory}
      />
      <ImageDialog
        imageUrl={dialogImageUrl}
        open={open}
        onClose={handleCloseDialog}
      />
      {/* <TableContainer component={Paper}>
        {allCategories.isLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              p: 2,
            }}
          >
            <Bars height={"35px"} color="#b5c4cc" />
            <Typography mt={1} sx={{ color: "#687175" }}>
              Loading All Categories...
            </Typography>
          </Box>
        ) : (
          <Table sx={{ minWidth: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Subcategories</TableCell>
                <TableCell align="center">Actions</TableCell>
                <TableCell align="center">Image</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allCategories.data.map((category) => {
                return (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    key={category.id}
                  >
                    <TableCell
                      sx={tableCellStyle}
                      align="left"
                      component="th"
                      scope="row"
                    >
                      <Typography>{category.name}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{ ...tableCellStyle, maxWidth: "100px" }}
                      align="right"
                    >
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          textAlign: "center",
                        }}
                      >
                        {category.description}
                      </Typography>
                    </TableCell>
                    <TableCell sx={tableCellStyle} align="center">
                      <Typography>{category.subcat_count}</Typography>
                    </TableCell>
                    <TableCell sx={tableCellStyle} align="center">
                      <IconButton
                        onClick={() => handleUpdateCategory({ ...category })}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        disabled={isCategoryDeleting}
                        onClick={() => handleDeleteCategory(category.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      sx={{
                        ...tableCellStyle,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      align="center"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        ref={imageUploadRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                      />
                      <Box
                        sx={{
                          width: "2vw",
                          height: "2vw",
                          // bgcolor: "yellowgreen",
                          backgroundImage: category?.category_img_url
                            ? `url("https://api.aasportsusa.com/uploads/${category?.category_img_url}")`
                            : `url("https://static-00.iconduck.com/assets.00/no-image-icon-256x256-blc2175p.png")`,
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          
                        }}
                        onClick={() =>
                          handleOpenDialog(
                            `${process.env.REACT_APP_API_URL_LOCAL}/uploads/${category?.category_img_url}`
                          )
                        }
                      ></Box>
                      <IconButton
                        disabled={isCategoryUpdating}
                        onClick={() => handleChange(category.id)}
                      >
                        <UploadIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer> */}
      {allCategories?.isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "fit-content",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: 2,
          }}
        >
          <Bars height={"35px"} color="#b5c4cc" />
          <Typography mt={1} sx={{ color: "#687175" }}>
            Loading All Categories...
          </Typography>
        </Box>
      ) : (
        <Box>
          <CategoryTree
            categories={allCategories?.data}
            onAddSubCategory={onAddSubCategory}
            handleUpdateCategory={handleUpdateCategory}
            handleDeleteCategory={handleDeleteCategory}
            handleFileInputChange={handleFileInputChange}
            isCategoryUpdating={isCategoryUpdating}
            isCategoryDeleting={isCategoryDeleting}
            imageUploadRef={imageUploadRef}
            handleOpenDialog={handleOpenDialog}
            handleChange={handleChange}
          />
        </Box>
      )}

      {/* <Box my={2}>
      <CategoryTreeTable categories={allCategories?.data} />
      </Box> */}
    </Box>
  );
};

export default DashCategoryCard;
