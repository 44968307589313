import { Box, Button } from "@mui/material";
import {
  changeBackTextPosition,
  changeFrontTextPosition,
  selectAdminCanvasFrontView,
  toggleView,
} from "../../slices/adminCanvasSlice";
import AdminCanvasEditor from "./AdminCanvasEditor";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CanvasEditor from "../Canvas/CanvasEditor";
import {
  Group,
  Image,
  Layer,
  Path,
  Rect,
  Stage,
  Text,
  Transformer,
} from "react-konva";
import {
  changeColor,
  changeLogoDimensions,
  changeLogoPosition,
  changePosition,
  changeText,
  exportCanvas,
  selectBackCanvasRef,
  selectBackMaskImage,
  selectBackPaths,
  selectBackTextArray,
  selectColors,
  selectFrontCanvasRef,
  selectFrontMaskImage,
  selectFrontPaths,
  selectFrontTextArray,
  selectUserBackLogo,
  selectUserFrontLogo,
  updateStageRef,
} from "../../slices/adminCanvasSlice";
import useImage from "use-image";
import { Puff } from "react-loader-spinner";

const MakeProduct = ({ loading }) => {
  const dispatch = useDispatch();
  const [isFrontView, setIsFrontView] = useState(true);
  const stage1Ref = useRef(null);
  const stage2Ref = useRef(null);
  const frontPathsArr = useSelector(selectFrontPaths);
  const backPathsArr = useSelector(selectBackPaths);
  const frontMaskImageObject = useSelector(selectFrontMaskImage);
  const backMaskImageObject = useSelector(selectBackMaskImage);
  const [frontMaskImage] = useImage(frontMaskImageObject?.src);
  const [backMaskImage] = useImage(backMaskImageObject?.src);
  const frontTextArray = useSelector(selectFrontTextArray);
  const backTextArray = useSelector(selectBackTextArray);
  const userFrontLogo = useSelector(selectUserFrontLogo);
  const userBackLogo = useSelector(selectUserBackLogo);

  const userFrontLogoRef = useRef(null);
  const userFrontLogoTransformerRef = useRef(null);
  const [selectedImageFront, setSelectedImageFront] = useState(null);

  const userBackLogoRef = useRef(null);
  const userBackLogoTransformerRef = useRef(null);
  const [selectedImageBack, setSelectedImageBack] = useState(null);

  const frontStageRef = useSelector(selectFrontCanvasRef);
  const backStageRef = useSelector(selectBackCanvasRef);

  useEffect(() => {
    if (
      userFrontLogoRef.current &&
      userFrontLogoTransformerRef &&
      selectedImageFront === userFrontLogoRef.current
    ) {
      userFrontLogoTransformerRef.current.nodes([userFrontLogoRef.current]);
      if (selectedImageFront === userFrontLogoRef.current) {
        userFrontLogoTransformerRef.current.getLayer().batchDraw();
      }
    }
  }, [userFrontLogoRef, userFrontLogoTransformerRef, selectedImageFront]);
  useEffect(() => {
    if (
      userBackLogoRef.current &&
      userBackLogoTransformerRef &&
      selectedImageBack === userBackLogoRef.current
    ) {
      userBackLogoTransformerRef.current.nodes([userBackLogoRef.current]);
      if (selectedImageBack === userBackLogoRef.current) {
        userBackLogoTransformerRef.current.getLayer().batchDraw();
      }
    }
  }, [userBackLogoRef, userBackLogoTransformerRef, selectedImageBack]);

  const handleSelectFrontImage = (e) => {
    if (e.target.getClassName() === "Image") {
      setSelectedImageFront(e.target);
    }
  };

  const handleDeselectFrontImage = (event) => {
    const clickedOnImage = event.target === userFrontLogoRef.current;
    if (!clickedOnImage) {
      setSelectedImageFront(null);
    }
  };

  const handleSelectBackImage = (e) => {
    if (e.target.getClassName() === "Image") {
      setSelectedImageBack(e.target);
    }
  };

  const handleDeselectBackImage = (event) => {
    const clickedOnImage = event.target === userBackLogoRef.current;
    if (!clickedOnImage) {
      setSelectedImageBack(null);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "fit-content",
        }}
      >
        {loading ? (
          <Box
            sx={{
              height: "300px",
              width: "250px",
              p: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Puff height={60} width={60} color="#66ccff" />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                width: "50%",
                height: "fit-content",
                display: isFrontView ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stage
                width={600}
                height={600}
                onClick={
                  isFrontView
                    ? handleDeselectFrontImage
                    : handleDeselectBackImage
                }
                ref={stage1Ref}
              >
                <Layer className="front-layer">
                  <Group>
                    {frontPathsArr?.map((pathObj) => {
                      // console.log(pathObj);
                      return <Path data={pathObj.path} fill={pathObj.color} />;
                    })}
                  </Group>
                  <Group>
                    <Image image={frontMaskImage ? frontMaskImage : null} />
                  </Group>
                  <Group>
                    {frontTextArray?.map((textObj) => {
                      return (
                        <Text
                          text={textObj.text}
                          x={textObj.x}
                          y={textObj.y}
                          fill={textObj.fill}
                          draggable={textObj.draggable}
                          height={textObj.height}
                          width={textObj.width}
                          fontSize={textObj.fontSize}
                          align={textObj.align}
                          stroke={textObj.stroke}
                          strokeWidth={textObj.strokeWidth}
                          fontFamily={textObj.fontFamily}
                          onDragEnd={(e) => {
                            const target = e.target;
                            dispatch(
                              changeFrontTextPosition({
                                targetID: textObj.id,
                                newX: target.x(),
                                newY: target.y(),
                              })
                            );
                          }}
                        />
                      );
                    })}
                  </Group>
                  <Group>
                    {userFrontLogo?.logo && (
                      <Image
                        ref={userFrontLogoRef}
                        image={userFrontLogo.logo}
                        x={userFrontLogo.x}
                        y={userFrontLogo.y}
                        draggable
                        width={userFrontLogo.width}
                        height={userFrontLogo.height}
                        onClick={handleSelectFrontImage}
                        onDragEnd={(e) => {
                          dispatch(
                            changeLogoPosition({
                              logoStateName: "userFrontLogo",
                              newX: e.target.attrs.x,
                              newY: e.target.attrs.y,
                            })
                          );
                        }}
                        onTransform={(e) => {
                          const node = e.target;
                          const scaleX = node.attrs.scaleX;
                          const scaleY = node.attrs.scaleY;

                          // Calculate new width and height
                          const newWidth = node.width() * scaleX;
                          const newHeight = node.height() * scaleY;
                          // console.log(newWidth, newHeight);
                          dispatch(
                            changeLogoDimensions({
                              logoStateName: "userFrontLogo",
                              newW: newWidth,
                              newH: newHeight,
                            })
                          );
                        }}
                      />
                    )}
                    {selectedImageFront === userFrontLogoRef.current && (
                      <Transformer
                        rotateEnabled
                        resizeEnabled
                        ref={userFrontLogoTransformerRef}
                      />
                    )}
                  </Group>
                </Layer>
              </Stage>
            </Box>
            <Box
              sx={{
                width: "50%",
                height: "fit-content",
                // bgcolor: "lightcyan",
                display: !isFrontView ? "flex" : "none",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stage
                width={600}
                height={600}
                onClick={
                  isFrontView
                    ? handleDeselectFrontImage
                    : handleDeselectBackImage
                }
                ref={stage2Ref}
              >
                <Layer className="back-layer">
                  <Group>
                    {backPathsArr?.map((pathObj) => {
                      // console.log(pathObj);
                      return <Path data={pathObj.path} fill={pathObj.color} />;
                    })}
                  </Group>
                  <Group>
                    <Image image={backMaskImage ? backMaskImage : null} />
                  </Group>
                  <Group>
                    {backTextArray?.map((textObj) => {
                      return (
                        <Text
                          text={textObj.text}
                          x={textObj.x}
                          y={textObj.y}
                          fill={textObj.fill}
                          draggable={textObj.draggable}
                          height={textObj.height}
                          width={textObj.width}
                          fontSize={textObj.fontSize}
                          align={textObj.align}
                          stroke={textObj.stroke}
                          strokeWidth={textObj.strokeWidth}
                          fontFamily={textObj.fontFamily}
                          onDragEnd={(e) => {
                            const target = e.target;
                            dispatch(
                              changeBackTextPosition({
                                targetID: textObj.id,
                                newX: target.x(),
                                newY: target.y(),
                              })
                            );
                          }}
                        />
                      );
                    })}
                  </Group>
                  <Group>
                    {userBackLogo?.logo && (
                      <Image
                        ref={userBackLogoRef}
                        image={userBackLogo.logo}
                        x={userBackLogo.x}
                        y={userBackLogo.y}
                        draggable
                        width={userBackLogo.width}
                        height={userBackLogo.height}
                        onClick={handleSelectBackImage}
                        onDragEnd={(e) => {
                          dispatch(
                            changeLogoPosition({
                              logoStateName: "userBackLogo",
                              newX: e.target.attrs.x,
                              newY: e.target.attrs.y,
                            })
                          );
                        }}
                        onTransform={(e) => {
                          const node = e.target;
                          const scaleX = node.attrs.scaleX;
                          const scaleY = node.attrs.scaleY;

                          // Calculate new width and height
                          const newWidth = node.width() * scaleX;
                          const newHeight = node.height() * scaleY;
                          // console.log(userFrontLogo.width);
                          dispatch(
                            changeLogoDimensions({
                              logoStateName: "userBackLogo",
                              newW: newWidth,
                              newH: newHeight,
                            })
                          );
                        }}
                      />
                    )}
                    {selectedImageBack === userBackLogoRef.current && (
                      <Transformer
                        rotateEnabled
                        resizeEnabled
                        ref={userBackLogoTransformerRef}
                      />
                    )}
                  </Group>
                </Layer>
              </Stage>
            </Box>
          </>
        )}
        <Button onClick={() => setIsFrontView(!isFrontView)}>
          Change View
        </Button>
        <AdminCanvasEditor />
      </Box>
    </>
  );
};

export default MakeProduct;
