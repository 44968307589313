import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import SendInquiryForm from "../Forms/SendInquiryForm";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const ProductButtons = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { userSingleProduct: product } = useSelector(
    (state) => state.userCategoryState
  );
  // console.log(product);

  return (
    <Box
      sx={{
        width: "100%",
        // height: "70px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: { xs: "column", md: "row" },
        gap: "10px",
      }}
    >
      <Button
        variant="contained"
        // size="small"
        sx={{
          width: { xs: "100%", md: "100%" },
          height: "100%",
          bgcolor: "#fff",
          color: "#040707",
          border: "1px solid #040707",
          "&:hover": {
            border: "#f26728 solid 1px",
            color: "#f26728",
            backgroundColor: "rgba(242, 103, 40, 0.1)",
          },
        }}
        onClick={handleOpen}
        disableElevation
      >
        Send Inquiry
      </Button>

      {product?.custom_product_id && (
        <Button
          variant="contained"
          // size="small"
          sx={{
            width: { xs: "100%", md: "100%" },
            height: "100%",
            bgcolor: "#fff",
            color: "#040707",
            border: "1px solid #040707",
            "&:hover": {
              border: "#f26728 solid 1px",
              color: "#f26728",
              backgroundColor: "rgba(242, 103, 40, 0.1)",
            },
          }}
          onClick={() => navigate(`/customize/${product?.custom_product_id}`)}
          disableElevation
        >
          Customize And Send Inquiry
        </Button>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <>
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              boxShadow: 24,
              padding: "4px",
              borderRadius: "8px",
              outline: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                m: "-4px",
                bgcolor: "#2196f3",
                borderTopLeftRadius: "inherit",
                borderTopRightRadius: "inherit",
                p: "6px 16px",
              }}
            >
              <Typography color="white" fontStyle="bold" variant="h5">
                Inquiry Form
              </Typography>

              <IconButton onClick={handleClose} aria-label="close">
                <CloseIcon sx={{ color: "white" }} />
              </IconButton>
            </Box>

            <Box
              sx={{
                maxWidth: "760px",
                mx: "auto",
                p: "4px 16px 14px 16px",
              }}
            >
              <SendInquiryForm closeModel={handleClose} productPage={true} />
            </Box>
          </Box>
        </>
      </Modal>

      {/* <Button
        variant="outlined"
        sx={{
          width: { xs: "100%", md: "49%" },
          mt: { xs: 2, md: 0 },
          height: "100%",
          color: "#555555",
          borderColor: "#434343",
          borderWidth: "2px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#434343",
              borderWidth: "2px",
            },
            "&:hover fieldset": {
              borderColor: "#434343",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#434343",
            },
          },
          "&:hover": {
            borderColor: "#434343",
            borderWidth: "2px",
          },
        }}
        disableElevation
      >
        Add to basket
      </Button> */}
    </Box>
  );
};

export default ProductButtons;
