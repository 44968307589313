import React, { useEffect, useState } from "react";
import AllContainer from "../AllContainer/AllContainer";
import AddIcon from "@mui/icons-material/Add";
import AddNewProdDialog from "./AddNewProdDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategories,
  selectAllCategories,
} from "../../../slices/categoriesSlice";
import {
  getAllCustomProducts,
  selectAllCustomProducts,
} from "../../../slices/adminCanvasSlice";

const CustomProducts = () => {
  const dispatch = useDispatch();
  const allCategories = useSelector(selectAllCategories);
  useEffect(() => {
    if (allCategories?.data.length === 0) {
      dispatch(getAllCategories());
    }
  }, []);
  useEffect(() => {
    dispatch(getAllCustomProducts());
    return () => {};
  }, []);

  const allCustomProducts = useSelector(selectAllCustomProducts);
  const [allCustomProductsArray, setAllCustomProductsArray] = useState([]);

  useEffect(() => {
    if (Array.isArray(allCustomProducts?.data)) {
      setAllCustomProductsArray(allCustomProducts?.data);
    }
  }, [allCustomProducts?.data]);

  const [isAddProdDialogOpen, setIsAddProdDialogOpen] = useState(false);

  const handleAddNewProd = () => {
    setIsAddProdDialogOpen(true);
  };

  const customProductActions = [
    {
      title: "Add New Product",
      handleClick: handleAddNewProd,
      icon: AddIcon,
      color: "#687175",
    },
  ];
  console.log(allCustomProducts.data);
  const searchCustomProducts = (searchQuery) => {
    // Convert search query to lowercase for case-insensitive search
    const query = searchQuery.toLowerCase().trim();
    if (query.length === 0) {
      setAllCustomProductsArray(allCustomProducts?.data);
    } else {
      // Filter products based on name or SKU
      const filteredProducts = allCustomProducts?.data.filter((product) => {
        const productName = product.product_name.toLowerCase();
        const productSKU = product.sku.toLowerCase();
        return productName.includes(query) || productSKU.includes(query);
      });

      setAllCustomProductsArray(filteredProducts);
      return filteredProducts;
    }
  };

  return (
    <>
      <AddNewProdDialog
        open={isAddProdDialogOpen}
        setOpen={setIsAddProdDialogOpen}
        allCategories={allCategories}
      />
      <AllContainer
        title={"Custom Products"}
        data={allCustomProductsArray}
        isLoading={allCustomProducts.isAllCustomProductsLoading}
        searchFunction={searchCustomProducts}
        actions={customProductActions}
        redirectUrl={`/dashboard/custom-products/`}
      />
    </>
  );
};

export default CustomProducts;
