import React, { useEffect, useState } from "react";
import DashCategoryTable from "./DashCategoryTable";
import { Box } from "@mui/material";
import Topbar from "../AllContainer/Topbar";
import AddIcon from "@mui/icons-material/Add";
import AddNewCategoryDialog from "./AddNewCategoryDialog";
import {
  getAllCategories,
  selectAllCategories,
} from "../../../slices/categoriesSlice";
import { useDispatch, useSelector } from "react-redux";
import UpdateCategoryDialog from "./UpdateCategoryDialog";

const AllCategoriesContainer = ({ allParentCategories }) => {
  const dispatch = useDispatch();
  const allCategories = useSelector(selectAllCategories);
  const [isAddCatOpen, setIsAddCatOpen] = useState(false);
  const [parentCategoryId, setParentCategoryId] = useState("");

  const handleAddNewCategory = function () {
    setParentCategoryId("");
    setIsAddCatOpen(!isAddCatOpen);
  };

  const topbarActions = [
    {
      title: "Add New Top Level Category",
      handleClick: handleAddNewCategory,
      icon: AddIcon,
      color: "#687175",
    },
  ];

  useEffect(() => {
    if (allCategories?.data && allCategories?.data.length === 0) {
      dispatch(getAllCategories());
    }
    return () => {};
  }, []);

  // Parent Id For the Addition of Subcategory
  const onAddSubCategory = (parentId) => {
    // console.log("Parent Id: ", parentId);
    setParentCategoryId(parentId);
    setIsAddCatOpen(!isAddCatOpen);
  };

  return (
    <Box sx={{ width: "100%", height: "fit-content", p: 2 }}>
      <AddNewCategoryDialog
        allCategories={allCategories}
        open={isAddCatOpen}
        setOpen={setIsAddCatOpen}
        parentCategoryId={parentCategoryId}
      />

      <Topbar title={"Categories"} actions={topbarActions} />
      <DashCategoryTable
        allParentCategories={allParentCategories}
        allCategories={allCategories}
        onAddSubCategory={onAddSubCategory}
      />
    </Box>
  );
};

export default AllCategoriesContainer;
