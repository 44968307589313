import React, { useEffect } from "react";
import Breadcrumb from "../../components/Category/Breadcrumb";
import Footer from "../../components/LandingPage/Footer/Footer";
import SingleProductContainer from "../../components/Product/SingleProductContainer";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../slices/userCategorySlice";
import { getRelatedCategoriesNameAndLink } from "../../config/utils";
import Navbar from "../../components/AnimatedLandingPage/Navbar";
import { Helmet } from "react-helmet";


export const ProductPage = () => {
  const dispatch = useDispatch();
  const { userCategories, userSingleProduct } = useSelector(
    (state) => state.userCategoryState
  );

  const categoryId = userSingleProduct?.category_id;

  const items = getRelatedCategoriesNameAndLink(userCategories, categoryId);

  useEffect(() => {
    if (userCategories.length === 0) dispatch(fetchCategories());
  }, []);

  


  return (
    <>
    <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{userSingleProduct?.name ? userSingleProduct?.name : "Product"} | US Sports</title>
      </Helmet>
      <Navbar />
      <Breadcrumb
        items={[{ name: "Categories", link: "/category" }, ...items]}
        currentTitle={userSingleProduct?.name}
      />
      <SingleProductContainer />
      <Footer />
    </>
  );
};
