import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  buttonStyleMui,
  selectMuiStyle,
  textFieldStyle,
} from "../../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewCategory,
  selectIsCategoryAdding,
} from "../../../slices/categoriesSlice";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";

const AddNewCategoryDialog = ({
  allCategories,
  open,
  setOpen,
  parentCategoryId,
}) => {
  const dispatch = useDispatch();
  const [parentCategoryValue, setparentCategoryValue] = useState("");
  const [isParentCategory, setIsParentCategory] = useState(true);

  const isCategoryAddingApi = useSelector(selectIsCategoryAdding);

  const handleIsParentCategoryChange = (event) => {
    setIsParentCategory(event.target.checked);
  };

  useEffect(() => {
    if (parentCategoryId !== "") {
      setIsParentCategory(false);
      if (allCategories.data.length > 0) {
        const category = allCategories?.data.find(
          (category) => category.id === parentCategoryId
        );
        // console.log("Name of the category", category);
        setparentCategoryValue(category.name);
      }
    } else {
      setIsParentCategory(true);
    }

    return () => {
      setparentCategoryValue("");
    };
  }, [parentCategoryId]);

  useEffect(() => {
    if (!isCategoryAddingApi && open) {
      setOpen(false);
    }
  }, [isCategoryAddingApi]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    if (!isCategoryAddingApi) {
      setOpen(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            if (parentCategoryId !== "") {
              formData.append("parent_id", parentCategoryId);
            }
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            // console.log(formJson);

            toast.promise(
              new Promise(async (resolve, reject) => {
                try {
                  await dispatch(createNewCategory(formJson));
                  resolve();
                } catch (error) {
                  reject(error);
                } finally {
                  handleClose();
                }
              }),
              {
                loading: "Creating New Category...",
                success: "Category created successfully!",
                error: "Error While Creating Category",
              }
            );
          },

          sx: { p: 1 },
        }}
      >
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent sx={{ "&.MuiDialogContent-root": { p: 1 } }}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="category_name"
            label="Category Name"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={textFieldStyle}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="desc"
            name="category_desc"
            label="Category Description"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={textFieldStyle}
          />
          {!isParentCategory && (
            <Fade in>
              <TextField
                value={parentCategoryValue}
                label="Parent Category"
                fullWidth
                size="small"
                sx={textFieldStyle}
              />
            </Fade>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            disableElevation
            disabled={isCategoryAddingApi}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button type="submit" disableElevation disabled={isCategoryAddingApi}>
            {isCategoryAddingApi ? (
              <Oval height={20} width={20} color="#0a5484" />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewCategoryDialog;
