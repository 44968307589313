import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RosterItemsContainer from "../Cart/RosterItemsContainer";
import { Box, Button, Typography } from "@mui/material";
import { AddShoppingCart } from "@mui/icons-material";
import { addCartItem } from "../../slices/virtualCartSlice";
import { validateCartItems } from "../../config/utils";
import { useNavigate } from "react-router";
import {
  addRosterFile,
  exportCanvasAsync,
  selectBackCanvasRef,
  selectCustomizeTabIndex,
  selectFrontCanvasRef,
  selectProductRosterFile,
} from "../../slices/canvasSlice";

const RosterEditor = () => {
  const { cartItems } = useSelector((state) => state.virtualCart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rosterFile = useSelector(selectProductRosterFile);

  useEffect(() => {
    //TODO:: Get the product ID or Slag from the url
    dispatch(addCartItem({ productId: 1, unitPrice: 25 }));
  }, []);

  // Function to handle file selection
  const handleRosterSheetChange = (event) => {
    const file = event.target.files[0];
    dispatch(addRosterFile(file));
  };

  // const customizeTabIndex = useSelector(selectCustomizeTabIndex);
  // const frontStageRef = useSelector(selectFrontCanvasRef);
  // const backStageRef = useSelector(selectBackCanvasRef);

  // useEffect(() => {
  //   if (customizeTabIndex === "roster") {
  //     console.log("exporting canvas ...");
  //     dispatch(
  //       exportCanvasAsync({ stageRef: frontStageRef, downloadName: "canvas-front" })
  //     );
  //     dispatch(
  //       exportCanvasAsync({ stageRef: backStageRef, downloadName: "canvas-back" })
  //     );
  //   }
  // }, [customizeTabIndex]);

  return (
    <>
      <Typography sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}>
        Create Roster
      </Typography>
      {cartItems.length > 0 && <RosterItemsContainer cartItem={cartItems[0]} />}

      {/* <Button
        startIcon={<AddShoppingCart/>} 
        sx = {{marginTop:'8px'}}
        disabled = {!validateCartItems(cartItems)}
        variant="contained"
        onClick ={()=> navigate('/cart')}
        >
        Add to Cart
      </Button> */}

      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          bgcolor: "#e9ebed",
          my: "16px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          py: 1,
          borderRadius: "8px",
        }}
      >
        <Typography
          sx={{ fontSize: "16px", fontWeight: 600, textAlign: "center" }}
        >
          OR
        </Typography>
        <Typography
          sx={{ fontSize: "16px", fontWeight: 400, textAlign: "center" }}
        >
          Upload an Excel Sheet
        </Typography>
        <Box
          sx={{
            width: "30%",
            height: "60px",
            my: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            bgcolor: "#233746",
            borderRadius: "8px",
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={() => {
            // Trigger the hidden file input when the box is clicked
            document.getElementById("rosterSheetInput").click();
          }}
        >
          <input
            id="rosterSheetInput"
            type="file"
            accept=".xlsx,.xls" // Specify accepted file types (optional)
            style={{ display: "none" }} // Hide the input element
            onChange={handleRosterSheetChange}
          />
          <Typography>Click to {rosterFile ? "Change" : "Upload"}</Typography>
          {rosterFile?.name}
        </Box>
      </Box>
    </>
  );
};

export default RosterEditor;
