import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  getProductById,
  resetSingleProduct,
  selectIsProductUpdating,
  selectSingleProduct,
  updateProduct,
} from "../../../slices/productSlice";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { textFieldStyle } from "../../../config/constants";
import {
  getAllCategories,
  selectAllCategories,
} from "../../../slices/categoriesSlice";
import { buttonStyleMui } from "../../../config/constants";
import ProductImageUploadCard from "../../../components/Dashboard/DashProduct/ProductImageUploadCard";
import FileUploadDialog from "../../../components/Dashboard/DashProduct/FileUploadDialog";
import FileUploadDialogPrimary from "../../../components/Dashboard/DashProduct/FileUploadDialogPrimary";
import TagsEditor from "../../../components/Dashboard/DashProduct/TagsEditor";
import toast from "react-hot-toast";
import ReviewsEditor from "../../../components/Dashboard/DashProduct/ReviewsEditor";
import { Editor } from "@tinymce/tinymce-react";

const SingleProduct = () => {
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const allCategories = useSelector(selectAllCategories);
  const singleProduct = useSelector(selectSingleProduct);
  const isProductUpdating = useSelector(selectIsProductUpdating);

  const [categoryValue, setcategoryValue] = useState("");
  const [productName, setproductName] = useState("");
  const [productSku, setproductSku] = useState("");
  const [productDescription, setproductDescription] = useState("");
  const [productType, setProductType] = useState("");

  const [isPrimaryImageAvailable, setisPrimaryImageAvailable] = useState(false);

  const [isAddProdImageDialogOpen, setIsAddProdImageDialogOpen] =
    useState(false);
  const [isAddProdImageDialogOpenPrimary, setIsAddProdImageDialogOpenPrimary] =
    useState(false);

  useEffect(() => {
    dispatch(getProductById(id));
    return () => {
      dispatch(resetSingleProduct());
    };
  }, []);

  // console.log(singleProduct);

  // ↘️ START OF STEP WISE SELECTION OF CATEGORIES

  const [theMostParentCategories, setTheMostParentCategories] = useState([]);
  const [selectedMostParentCategory, setSelectedMostParentCategory] =
    useState("");
  useEffect(() => {
    if (allCategories?.data) {
      const theMostParentCategoriesArray = allCategories.data.filter(
        (category) => category?.parent_category_id === null
      );
      setTheMostParentCategories(theMostParentCategoriesArray);
    }
    // console.log(theMostParentCategories);
  }, [allCategories?.data]);

  // function to get all subcategories
  const getAllSubCategories = (parentCategoryId) => {
    if (allCategories?.data) {
      const subCategoriesArray = allCategories.data.filter(
        (category) => category?.parent_category_id === parentCategoryId
      );
      return subCategoriesArray;
    }
  };

  // Selection Of Categories Based On Gender Category
  const [genderCategoryBasedCategories, setGenderCategoryBasedCategories] =
    useState([]);
  const [selectedGenderBasedCategory, setSelectedGenderBasedCategory] =
    useState("");

  useEffect(() => {
    if (
      selectedMostParentCategory !== "" &&
      getAllSubCategories(selectedMostParentCategory).length > 0
    ) {
      const genderCategoryBasedCategoriesArray = getAllSubCategories(
        selectedMostParentCategory
      );
      setGenderCategoryBasedCategories(genderCategoryBasedCategoriesArray);
    }
  }, [selectedMostParentCategory]);

  // Selection of Sub Categories
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    if (
      selectedGenderBasedCategory !== "" &&
      getAllSubCategories(selectedGenderBasedCategory).length > 0
    ) {
      const subcategoriesArray = getAllSubCategories(
        selectedGenderBasedCategory
      );
      setSubCategories(subcategoriesArray);
    }
  }, [selectedGenderBasedCategory]);

  // ↖️ END OF STEP WISE SELECTION OF CATEGORIES

  useEffect(() => {
    const allCategoriesData = allCategories?.data;
    if (allCategoriesData.length === 0) {
      dispatch(getAllCategories());
    }

    return () => {};
  }, [allCategories]);

  useEffect(() => {
    if (singleProduct?.data !== null) {
      setproductDescription(singleProduct?.data.description);
      setproductName(singleProduct?.data.name);
      setproductSku(singleProduct?.data.sku);
      setProductType(singleProduct?.data.type);

      if (allCategories?.data.length > 0) {
        // SELECTING CATEGORIES

        // Find parent category id of the category of the product
        const categoryObjectFromProduct = allCategories?.data.filter(
          (category) => category.id === singleProduct?.data.category_id
        )[0];

        const firstParentOfProductCategory = allCategories?.data.filter(
          (category) =>
            category.id === categoryObjectFromProduct?.parent_category_id
        )[0];
        const secondParentOfProductCategory = allCategories?.data.filter(
          (category) =>
            category.id === firstParentOfProductCategory?.parent_category_id
        )[0];

        // console.log("Product Category :", categoryObjectFromProduct);
        // console.log("First Parent :", firstParentOfProductCategory);
        // console.log("Second Parent :", secondParentOfProductCategory);

        setcategoryValue(categoryObjectFromProduct?.id);
        setSelectedGenderBasedCategory(firstParentOfProductCategory?.id);
        setSelectedMostParentCategory(secondParentOfProductCategory?.id);
      }

      if (
        singleProduct?.data?.product_images?.some(
          (image) => image.type === "primary"
        )
      ) {
        setisPrimaryImageAvailable(true);
      } else {
        setisPrimaryImageAvailable(false);
      }
    }

    return () => {
      setproductDescription("");
      setproductName("");
      setproductSku("");
      setcategoryValue("");
      setProductType("");
      setisPrimaryImageAvailable(false);
    };
  }, [singleProduct, allCategories]);

  useEffect(()=>{
    // console.log("Produc Desc:" + productDescription);
  },[productDescription])

  return (
    <>
      <FileUploadDialog
        open={isAddProdImageDialogOpen}
        setOpen={setIsAddProdImageDialogOpen}
      />
      <FileUploadDialogPrimary
        open={isAddProdImageDialogOpenPrimary}
        setOpen={setIsAddProdImageDialogOpenPrimary}
      />
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "30px", fontWeight: 500 }}>
          Edit Your Product
        </Typography>
        {(singleProduct.isLoading === true ||
          allCategories.isLoading === true) && (
          <Typography sx={{ color: "#ff2400" }}>
            Loading Product Data ...
          </Typography>
        )}
        <Box
          sx={{
            width: "100%",
            p: 2,
            borderRadius: "0.3vw",
            boxShadow: 2,
          }}
          component={"form"}
          onSubmit={async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            formData.append("description", productDescription)
            const formJson = Object.fromEntries(formData.entries());
            // console.log(formJson);

            toast.promise(
              new Promise(async (resolve, reject) => {
                try {
                  const { payload: updatedProduct } = await dispatch(
                    updateProduct({ id: id, data: formJson })
                  );
                  resolve(updatedProduct);
                } catch (error) {
                  reject(error);
                }
              }),
              {
                loading: "Updating Product...",
                success: (updatedProduct) =>
                  `Product ${updatedProduct.name} updated!`,
                error: "Error While Updating Product",
              }
            );
          }}
        >
          <TextField
            required
            margin="dense"
            id="name"
            name="name"
            label="Product Name"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={textFieldStyle}
            value={productName}
            onChange={(e) => setproductName(e.target.value)}
          />
          {/* <TextField
            required
            margin="dense"
            id="description"
            name="description"
            label="Product Description"
            multiline
            maxRows={4}
            minRows={2}
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={textFieldStyle}
            value={productDescription}
            onChange={(e) => setproductDescription(e.target.value)}
          /> */}
          <FormLabel sx={{ ml: 1 }}>Product Description</FormLabel>
          <div className="my-2">
            <Editor
              apiKey={process.env.REACT_APP_TINYMC_API_KEY}
              onInit={(_evt, editor) => (editorRef.current = editor)}
              value={productDescription}
              onEditorChange={(content, editor) => {
                // console.log(content);
                setproductDescription(content);
              }}
              init={{
                height: 500,
                menubar: false,
                branding: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>
          <TextField
            required
            margin="dense"
            id="sku"
            name="sku"
            label="SKU"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={textFieldStyle}
            value={productSku}
            onChange={(e) => setproductSku(e.target.value)}
          />

          <TextField
            required
            margin="dense"
            id="product_type"
            name="type"
            label="Product Type"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            select
            value={productType}
            sx={textFieldStyle}
            onChange={(e) => setProductType(e.target.value)}
          >
            {["single", "package"].map((item) => (
              <MenuItem value={item}>
                <Typography>{item}</Typography>
              </MenuItem>
            ))}
          </TextField>

          <TextField
            value={selectedMostParentCategory}
            label="Select Gender"
            fullWidth
            select
            size="small"
            sx={textFieldStyle}
            onChange={(e) => {
              setSelectedMostParentCategory(e.target.value);
            }}
          >
            {theMostParentCategories?.map((item) => (
              <MenuItem value={item.id}>
                <Typography>{item.name}</Typography>
              </MenuItem>
            ))}
          </TextField>
          {/* Conditional Checks for Gender Based Category Selection Selection */}
          {selectedMostParentCategory !== "" &&
            getAllSubCategories(selectedMostParentCategory).length > 0 && (
              <TextField
                value={selectedGenderBasedCategory}
                label="Select Category"
                fullWidth
                select
                size="small"
                sx={textFieldStyle}
                onChange={(e) => {
                  setSelectedGenderBasedCategory(e.target.value);
                }}
              >
                {genderCategoryBasedCategories?.map((item) => (
                  <MenuItem value={item.id}>
                    <Typography>{item.name}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            )}
          {/* Conditional check for Sub Category Selection */}
          {selectedGenderBasedCategory !== "" &&
            getAllSubCategories(selectedGenderBasedCategory).length > 0 && (
              <TextField
                id="category_id"
                name="category_id"
                value={categoryValue}
                label="Select Subcategory"
                fullWidth
                select
                size="small"
                sx={textFieldStyle}
                onChange={(e) => {
                  setcategoryValue(e.target.value);
                }}
              >
                {subCategories?.map((item) => (
                  <MenuItem value={item.id}>
                    <Typography>{item.name}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            )}

          <Button
            disabled={isProductUpdating}
            type="submit"
            sx={{ ...buttonStyleMui }}
          >
            Save
          </Button>
        </Box>
        {/* Tags Editing Component */}
        <TagsEditor singleProduct={singleProduct} />

        {/* Reviews Editor Component */}
        <ReviewsEditor singleProduct={singleProduct} />
        <Box
          sx={{
            width: "100%",
            height: "fit-content",
            mt: 2,
          }}
        >
          {/* <input type="file" name="file" id="secondary_image" /> */}
          <Typography sx={{ fontSize: "24px", fontWeight: 600, mb: 3 }}>
            Primary Images
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: { xs: "center", sm: "start" },
            }}
          >
            {Array.isArray(singleProduct?.data?.product_images) &&
              singleProduct?.data?.product_images?.map((image) => {
                if (image.type === "primary") {
                  return (
                    <ProductImageUploadCard
                      imageSrc={`${process.env.REACT_APP_API_URL_LOCAL}/uploads/${image.image_url}`}
                      image={image}
                      variant={"primary"}
                    />
                  );
                }
              })}
            {!isPrimaryImageAvailable && (
              <Box sx={{ width: "300px", height: "fit-content", mr: 2, mb: 2 }}>
                <Button
                  fullWidth
                  sx={{ ...buttonStyleMui }}
                  onClick={() => setIsAddProdImageDialogOpenPrimary(true)}
                >
                  Add Image
                </Button>
              </Box>
            )}
          </Box>
          <Typography sx={{ fontSize: "24px", fontWeight: 600, mb: 3 }}>
            Secondary Images
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: { xs: "center", sm: "start" },
            }}
          >
            {Array.isArray(singleProduct?.data?.product_images) &&
              singleProduct?.data?.product_images?.map((image) => {
                if (image.type === "secondary") {
                  return (
                    <ProductImageUploadCard
                      imageSrc={`${process.env.REACT_APP_API_URL_LOCAL}/uploads/${image.image_url}`}
                      image={image}
                      variant={"secondary"}
                    />
                  );
                }
              })}
            <Box sx={{ width: "300px", height: "fit-content", mr: 2, mb: 2 }}>
              <Button
                fullWidth
                sx={{ ...buttonStyleMui }}
                onClick={() => setIsAddProdImageDialogOpen(true)}
              >
                Add Image
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { SingleProduct };
