import { Button } from "@mui/material";
import React from "react";
import { ChromePicker } from "react-color";

const AddColorToPalette = ({
  color,
  setColor,
  setAllColorsArray,
  setToolbarOpen,
}) => {
  return (
    <div className="z-[999] p-1 rounded-md bg-white h-fit shadow-lg">
      <ChromePicker
        color={color}
        onChange={(newColor) => {
          setColor(newColor.hex);
        }}
        className="no-shadow"
        disableAlpha
      />
      <Button
        onClick={() => {
          setAllColorsArray((prevArr) => [...prevArr, color]);
          setToolbarOpen(false);
        }}
        sx={{
          bgcolor: "#fff",
          color: "#040707",
          border: "1px solid #040707",
          "&:hover": {
            border: "#f26728 solid 1px",
            color: "#f26728",
            backgroundColor: "rgba(242, 103, 40, 0.1)",
          },
        }}
        fullWidth
        size={"small"}
      >
        Add to the palette
      </Button>
    </div>
  );
};

export default AddColorToPalette;
