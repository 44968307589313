import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddTagsInput from "./AddTagsInput";

const TagsEditor = ({ singleProduct }) => {
  const [productTags, setproductTags] = useState([]);
  useEffect(() => {
    if (singleProduct?.data?.tags?.length > 0) {
      var tags = singleProduct?.data?.tags;

      if (Array.isArray(tags)) {
        setproductTags([...tags]);
      }
      // console.log(productTags);
    }
    return () => {
      setproductTags([]);
    };
  }, [singleProduct.data]);

  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        borderRadius: "0.3vw",
        boxShadow: 2,
        my: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          flexDirection: { xs: "column", md: "row" },
          mb: 0.5,
          gap: "1vw ",
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
          Edit Tags Of Your Product
        </Typography>
      </Box>
      <AddTagsInput productTags={productTags} />
    </Box>
  );
};

export default TagsEditor;
