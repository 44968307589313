import React, { useEffect, useState } from "react";
import MakeProduct from "./MakeProduct";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCustomProduct,
  fetchProductData,
  resetState,
  selectIsCustomProductDeleting,
  selectIsCustomProductUpdating,
  selectProductData,
} from "../../slices/adminCanvasSlice";
import { useNavigate, useParams } from "react-router";
import { buttonStyleMui } from "../../config/constants";
import UpdateProdDialog from "../Dashboard/CustomProducts/UpdateProdDialog";
import {
  getAllCategories,
  selectAllCategories,
} from "../../slices/categoriesSlice";

import UploadIcon from "@mui/icons-material/Upload";
import toast from "react-hot-toast";

const CreateProductAdmin = () => {
  const [isApiLoading, setIsApiLoading] = useState(false);

  const isDeleting = useSelector(selectIsCustomProductDeleting);
  const isUpdating = useSelector(selectIsCustomProductUpdating);
  const navigate = useNavigate();
  const { c_id } = useParams();
  // console.log(c_id);
  const dispatch = useDispatch();
  const [isUpdateProdDialogOpen, setIsUpdateProdDialogOpen] = useState(false);
  const productData = useSelector(selectProductData);
  useEffect(() => {
    if (c_id) {
      const productData = dispatch(fetchProductData(c_id));
    }
    return () => {
      dispatch(resetState());
    };
  }, []);
  // console.log("This is prod Data", productData);

  const allCategories = useSelector(selectAllCategories);

  useEffect(() => {
    if (allCategories.length === 0) {
      dispatch(getAllCategories());
    }
  }, []);

  const handleDeleteCustomProduct = async () => {
    const userConfirmed = window.confirm("Are you sure want to delete?");
    if (userConfirmed) {
      // console.log("Confirmed");
      toast
        .promise(
          new Promise(async (resolve, reject) => {
            try {
              await dispatch(deleteCustomProduct(c_id));
              resolve();
            } catch (error) {
              reject(error);
            }
          }),
          {
            loading: "Deleting Product...",
            success: "Product deleted successfully!",
            error: "Error While Deleting Product",
          }
        )
        .then(() => {
          // console.log("Deleted Successfully!");
          navigate("/dashboard/custom-products");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // console.log("Process Completed");
        });
    } else {
      // console.log("Not Confirmed");
    }
  };

  const handleFileChangeFront = async (e) => {
    const file = e.target.files[0];
    setIsApiLoading(true);
    if (file) {
      // setSelectedImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        const img = new window.Image(); // Use window.Image
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
      try {
        const formData = new FormData();
        formData.append("image", file); // Assuming 'image' is the expected field name
        formData.append("custom_product_id", c_id /* Your custom product ID */); // Add if needed
        formData.append("title", "Front Mask Image"); // Adjust title as needed

        // const response = await axios.post(
        //   "http://localhost:8000/custom-product/front-mask-image",
        //   formData,
        //   {
        //     headers: {
        //       "Content-Type": "multipart/form-data",
        //     },
        //   }
        // );

        // const urlofImg = `http://localhost:8000/uploads/${response.data?.src}`;
        // setImageUrl(urlofImg); // Assuming 'src' is the response property for image path
        // console.log(urlofImg);
        // dispatch(changeFrontMaskImage({ newImageSrc: urlofImg }));
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setIsApiLoading(false);
      }
    }
  };

  return (
    <>
      <input
        type="file"
        accept="image/*"
        id="thumb-upload-image-front"
        style={{ display: "none" }}
        onChange={handleFileChangeFront}
      />
      <UpdateProdDialog
        allCategories={allCategories}
        open={isUpdateProdDialogOpen}
        setOpen={setIsUpdateProdDialogOpen}
        productData={productData.data}
      />
      <Box
        sx={{
          width: "100%",
          height: "50px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
            Product Name: {productData?.data?.product_name}
          </Typography>
          <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
            SKU: {productData?.data?.sku}
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={() => setIsUpdateProdDialogOpen(true)}
            sx={{ ...buttonStyleMui, mr: "10px" }}
            disabled={isUpdating}
          >
            Update
          </Button>
          <Button
            sx={{
              ...buttonStyleMui,
              mr: "10px",
              bgcolor: "#ff2400",
              "&:hover": { bgcolor: "#ff2400" },
            }}
            disabled={isDeleting}
            onClick={handleDeleteCustomProduct}
          >
            Delete
          </Button>
          {/* <Button
          sx={{ ...buttonStyleMui, mr: "10px" }}
          component={"label"}
          htmlFor="thumb-upload-image-front"
        >
          Front Image
          <UploadIcon sx={{ ml: "2px" }} />
        </Button>
        <Button sx={buttonStyleMui}>
          Back Image
          <UploadIcon sx={{ ml: "2px" }} />
        </Button> */}
        </Box>
      </Box>
      <Box
        sx={{
          width: "90%",
          height: "fit-content",
          // bgcolor: "#ff0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mx: "auto",
        }}
      >
        <MakeProduct loading={productData.loading} />
      </Box>
    </>
  );
};

export default CreateProductAdmin;
