import "./App.css";
import { Routes, Route } from "react-router";
import { routes } from "./routes";
import { Provider } from "react-redux";
import store from "./store";
import LocomotiveScroll from "locomotive-scroll";
import "./index.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";

function App() {
  const locomotiveScroll = new LocomotiveScroll();

  const theme = createTheme({
    typography: {
      fontFamily: "Noto Sans",
    },
  });

  const renderRoutes = (routes) =>
    routes.map((route, index) => (
      <Route key={index} path={route.path} element={<route.element />}>
        {route.children && renderRoutes(route.children)}
      </Route>
    ));
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Routes>{renderRoutes(routes)}</Routes>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
