import React from "react";
import { Outlet } from "react-router";
import SideDrawer from "../../components/Dashboard/Generic/SideDrawer";
import { Box } from "@mui/material";
import AuthLayout from "../../components/Auth/AuthLayout";
import { Helmet } from "react-helmet";


const Dashboard = () => {
  return (
    <AuthLayout>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Dashboard | US Sports</title>
      </Helmet>
      <Box sx={{ display: "flex" }}>
        <SideDrawer />
        <Box sx={{ p: 2, width: "100%" }}>
          <Outlet />
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default Dashboard;
