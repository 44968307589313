import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import logImage from "../../assets/images/contact-form-image.png";
import { useLocation } from "react-router";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";

const LoginSignupForm = () => {
  const location = useLocation();
  // console.log(location.pathname);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Email is Required!"),
    password: Yup.string()
      .required("Password is Required!")
      .min(8, "Password should be at least 8 characters long"),
  });
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: loginSchema,
    onSubmit: (values) => console.log(values),
  });
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: "#d1e2eb",
      }}
    >
      <Box
        sx={{
          width: "40%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            minWidth: "300px",
            bgcolor: "#fff",
            width: "450px",
            height: "fit-content",
            p: "50px 40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ mb: 3 }}>
            <Typography
              sx={{ fontWeight: 700, fontSize: "30px", color: "#000000" }}
            >
              {location.pathname === "/sign-up" && "Sign Up"}
              {location.pathname === "/login" && "Sign In"}
            </Typography>
          </Box>
          <Formik onSubmit={formik.onSubmit}>
            {({ errors, touched }) => (
              // <Box component="form" sx={{ width: "100%" }} autoComplete="off">
              <>
                <TextField
                  {...formik.getFieldProps("email")}
                  name="email"
                  variant="outlined"
                  size="small"
                  label="Enter Email Address"
                  sx={{
                    width: "100%",
                    minWidth: "100%",
                    color: "#555555",
                    "& label": {
                      color: "#555555",
                    },
                    "& label.Mui-focused": {
                      color: "#555555",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#555555",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#555555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#555555",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#555555",
                      },
                    },
                  }}
                />
                {touched.email && errors.email && <div>{errors.email}</div>}

                <TextField
                  {...formik.getFieldProps("password")}
                  name="password"
                  variant="outlined"
                  label="Enter Password"
                  size="small"
                  type="password"
                  sx={{
                    width: "100%",
                    minWidth: "100%",
                    color: "#555555",
                    my: 2,
                    "& label": {
                      color: "#555555",
                    },
                    "& label.Mui-focused": {
                      color: "#555555",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#555555",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#555555",
                      },
                      "&:hover fieldset": {
                        borderColor: "#555555",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#555555",
                      },
                    },
                  }}
                />
                {touched.password && errors.password && (
                  <div>{errors.password}</div>
                )}

                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Remeber Password    "
                />
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  sx={{
                    width: "100%",
                    bgcolor: "#C4C4C4",
                    color: "#FFFFFF",
                    "&:hover": {
                      bgcolor: "#9d9d9d",
                      color: "#FFFFFF",
                    },
                    my: 2,
                  }}
                  disableElevation
                >
                  Sign In
                </Button>
              </>
              // </Box>
            )}
          </Formik>
        </Card>
      </Box>
      <Box
        sx={{
          width: "60%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${logImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      ></Box>
    </Box>
  );
};

export default LoginSignupForm;
