import {
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ProductButtons from "./ProductButtons";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsTab from "./ProductDetailsTab";
import { setProductPageTabIndex } from "../../slices/tabIndexSlice";
import { calculateAverageRating } from "../../config/utils";
import { useNavigate } from "react-router";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userSingleProduct: product } = useSelector(
    (state) => state.userCategoryState
  );

  const [quantity, setQuantity] = useState(1);

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };
  const deccrementQuantity = () => {
    setQuantity(quantity - 1);
  };

  const [liked, setLiked] = useState(false);

  const handleClick = () => {
    dispatch(setProductPageTabIndex(2));
    navigate("#review-form", { replace: true });

    // Smooth scroll to the element
    const element = document.querySelector("#review-form");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "50%" },
        // p: { xs: "0px 20px", md: "0px 50px" },
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        alignItems: "start",
      }}
    >
      <Box sx={{ flexGrow: "1" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: "none",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: { xs: "14px", sm: "20px", md: "28px" },
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: { xs: "26px", sm: "28px", md: "32px" },
                  fontWeight: 800,
                  color: "#262626",
                }}
              >
                {product.name}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { sm: "flex-start", md: "center" },
                  gap: { xs: "1px", md: "6px" },
                }}
              >
                <Rating
                  sx={{
                    fontSize: { xs: "16px", sm: "18px", md: "22px" },
                  }}
                  readOnly
                  value={calculateAverageRating(product.reviews)}
                />

                {product.reviews?.length > 0 && (
                  <>
                    <Typography
                      sx={{
                        fontSize: { xs: "12px", sm: "14px" },
                        color: "gray",
                      }}
                    >
                      {product.reviews.length || 2} customer reviews
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", sm: "18px" },
                        display: { xs: "none", md: "block" },
                        color: "gray",
                      }}
                    >
                      |
                    </Typography>
                  </>
                )}
                <Typography
                  sx={{
                    fontSize: { xs: "12px", sm: "14px" },
                    color: "gray",
                  }}
                >
                  <p
                    style={{
                      transition: "color 0.1s",
                      color: "gray",
                      cursor: "pointer"
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "black")}
                    onMouseLeave={(e) => (e.target.style.color = "gray")}
                    onClick={() => {
                      dispatch(setProductPageTabIndex(2));
                      navigate("#review-form");
                      window.scrollTo({top: 780, behavior: "smooth"})
                    }}
                  >
                    Add a review
                  </p>
                </Typography>
              </Box>
            </Box>

            {/* Available Sizes */}

            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: "none",
                flexWrap: "wrap",
                gap: "16px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "14px", sm: "16px", md:"18px" },
                  fontWeight: 500,
                }}
              >
                Size
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                {["XS", "S", "M", "L", "XL"].map((item) => (
                  <Typography
                    key={item}
                    // variant="outlined"
                    // disabled
                    // size="small"
                    sx={{
                      color: "#555555",
                      fontSize: { xs: "14px", sm: "16px", md:"18px" },
                      borderColor: "#7B7B7B",
                      "&:hover": {
                        borderColor: "#7B7B7B",
                      },
                    }}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
              
            </Box> */}

            <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
              {/* SKU */}
              <Typography
                sx={{
                  letterSpacing: "1px",
                  fontSize: "14px",
                  color: "#999999",
                  fontWeight: 500,
                }}
              >
                SKU: <span style={{ color: "#282828" }}>{product.sku}</span>
              </Typography>

              {/* Category */}
              <Typography
                sx={{
                  letterSpacing: "1px",
                  fontSize: "14px",
                  color: "#999999",
                  fontWeight: 500,
                }}
              >
                Category:{" "}
                <span style={{ color: "#282828" }}>
                  {product.category_name || "Dummy Category"}
                </span>
              </Typography>

              {/* Tags */}
              <Box sx={{ display: "flex", letterSpacing: "1px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#999999",
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 1,
                  }}
                >
                  <span>Tags: </span>
                  {product.tags?.map((tag) => {
                    return (
                      <Chip
                        // color="primary"
                        variant="outlined"
                        size="small"
                        label={tag.tag}
                        sx={{
                          color: "#f26728",
                          borderColor: "#f26728",
                        }}
                      />
                    );
                  })}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* <IconButton onClick={() => setLiked(liked ? false : true)}>
          <FavoriteIcon sx={{ color: liked ? "#f00" : "#BCBFC2" }} />
        </IconButton> */}
        </Box>

        {/* Qunatity Box Start */}
        {/* <Box
        sx={{
          height: { xs: "fit-content", md: "65px" },
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontSize: "18px", fontWeight: 400, pb: { xs: 2, md: 0 } }}
        >
          Quantity
        </Typography>
        <Box sx={{ pb: { xs: 2, md: 0 } }} component="form" autoComplete="off">
          <TextField
            size="small"
            variant="outlined"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            sx={{
              width: "150px",

              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#C4C4C4",
                },
                "&:hover fieldset": {
                  borderColor: "#C4C4C4",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#C4C4C4",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => deccrementQuantity()}
                    disableTouchRipple
                  >
                    <RemoveIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => incrementQuantity()}
                    disableTouchRipple
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                textAlign: "right",
              },
            }}
          />
        </Box>
        <Typography sx={{ pb: { xs: 2, md: 0 } }}>
          40 Available / 190 sold
        </Typography>
      </Box> */}
        {/* Qunatity Box End */}

        {/* Shipping Box Start */}
        {/* <Box
        sx={{
          width: "100%",
          display: "flex",
          height: { xs: "fit-content", md: "65px" },
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontSize: "18px", fontWeight: 400, pb: { xs: 2, md: 0 } }}
        >
          Shipping
        </Typography>
        <FormControl
          sx={{
            width: "200px",
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pb: { xs: 2, md: 0 },
          }}
        >
          <Select
            value={10}
            size="small"
            sx={{ height: "48%", minHeight: "48%" }}
          >
            <MenuItem value={10}>City Square, Sialkot</MenuItem>
          </Select>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              color: "#9D9D9D",
              pt: { xs: 2, md: 0 },
            }}
          >
            10-14 days delievery
          </Typography>
        </FormControl>
      </Box> */}
        {/* Shipping Box End */}

        {/* Adding Product Description */}

        <Box
          sx={{
            py: 2,
          }}
        >
          {/* <Typography variant="h6" sx={{ pb: 1 }}>
            Product Description
          </Typography>
          <div dangerouslySetInnerHTML={{__html: product.description}} /> */}
        </Box>
      </Box>
      <ProductButtons />
    </Box>
  );
};

export default ProductDetails;
