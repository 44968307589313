import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import React, { useState } from "react";
import {
  changeBackMaskImage,
  deleteBackMaskImage,
  selectBackMaskImage,
} from "../../slices/adminCanvasSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import axios from "axios";
import toast from "react-hot-toast";

const BackMaskImageEditor = () => {
  const [isApiLoading, setIsApiLoading] = useState(false);
  const { c_id } = useParams();
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const backMaskImage = useSelector(selectBackMaskImage);
  // console.log("back mask image ", backMaskImage);

  const handleFileChangeBack = (e) => {
    toast.promise(
      new Promise((resolve, reject) => {
        const file = e.target.files[0];
        setIsApiLoading(true);
        if (file) {
          setSelectedImage(file);
          const reader = new FileReader();
          reader.onload = () => {
            const img = new window.Image();
            img.src = reader.result;
            img.onload = () => {
              setImageUrl(reader.result);
            };
          };
          reader.readAsDataURL(file);
          const formData = new FormData();
          formData.append("image", file);
          formData.append("custom_product_id", c_id);
          formData.append("title", "Back Mask Image");

          axios
            .post(
              `${process.env.REACT_APP_API_URL_LOCAL}/custom-product/back-mask-image`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              const urlofImg = `${process.env.REACT_APP_API_URL_LOCAL}/uploads/${response.data?.src}`;
              setImageUrl(urlofImg);
              // console.log(urlofImg);
              dispatch(changeBackMaskImage({ newImageSrc: urlofImg }));
              resolve("Image uploaded successfully!");
            })
            .catch((error) => {
              console.error("Error uploading image:", error);
              reject(error);
            })
            .finally(() => {
              setIsApiLoading(false);
            });
        } else {
          reject("No file selected!");
        }
      }),
      {
        loading: "Uploading image...",
        success: "Image uploaded successfully!",
        error: "Error uploading image!",
      }
    );
  };

  const handleDeleteBackMaskImage = () => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          setIsApiLoading(true);
          const deletedImage = await axios.delete(
            `${process.env.REACT_APP_API_URL_LOCAL}/custom-product/back-mask-image`,
            {
              data: {
                custom_product_id: c_id,
              },
              headers: {
                "Content-Type": "application/json", // Set the content type
              },
            }
          );
          dispatch(deleteBackMaskImage());
          // Clear the input value to ensure the onChange event is triggered
          const inputElement = document.getElementById(
            "mask-upload-input-front"
          );
          if (inputElement) {
            inputElement.value = "";
          }
          resolve("Image deleted successfully!");
        } catch (error) {
          console.log("Error deleting image: ", error);
          reject(error);
        } finally {
          setIsApiLoading(false);
        }
      }),
      {
        loading: "Deleting image...",
        success: "Image deleted successfully!",
        error: "Error deleting image!",
      }
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <input
        type="file"
        accept="image/*"
        id="mask-upload-input-back"
        style={{ display: "none" }}
        onChange={handleFileChangeBack}
      />
      <Box
        sx={{
          width: "100%",
          mb: 2,
          bgcolor: "#d1e2eb",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100px",
            // bgcolor: "#262626",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            cursor: "pointer",
          }}
        >
          {isApiLoading ? (
            <CircularProgress sx={{ color: "#0a5484" }} />
          ) : (
            <IconButton
              component={"label"}
              htmlFor="mask-upload-input-back"
              // variant="contained"
              disableElevation
              // fullWidth
              // sx={{ bgcolor: "#4172dc", color: "#fff" }}
            >
              <FileUploadIcon sx={{ fontSize: "40px" }} />
            </IconButton>
          )}
          <Typography>Click to upload Back Mask Image</Typography>
        </Box>
      </Box>
      <Button
        fullWidth
        size="small"
        variant="contained"
        disableElevation
        disableRipple
        disabled={
          isApiLoading || !backMaskImage?.src || !backMaskImage ? true : false
        }
        sx={{
          bgcolor: "#262626",
          color: "#fff",
          "&:hover": {
            color: "#fff",
            bgcolor: "#262626",
          },
        }}
        onClick={() => handleDeleteBackMaskImage()}
      >
        Delete Back Mask Image
      </Button>
    </Box>
  );
};

export default BackMaskImageEditor;
