import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getAllUsers,
  selectAllUsers,
} from "../../../slices/usersAdminSlice";
import { Bars } from "react-loader-spinner";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";

const AllUsersContainer = () => {
  const allUsers = useSelector(selectAllUsers);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUsers());
  }, []);
  console.log(allUsers);
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    // Adjust the time zone offset to Pakistan Standard Time (UTC+5)
    date.setHours(date.getHours() + 5);

    const formattedDateTime = new Intl.DateTimeFormat("en-PK", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Karachi", // Optional: Explicitly set the time zone
    }).format(date);
    return formattedDateTime;
  };

  const handleDeleteUser = async (userId) => {
    const confirmed = window.confirm("Are you sure want to delete the user?");
    if (confirmed) {
      try {
        await toast.promise(
          new Promise(async (resolve, reject) => {
            try {
              await dispatch(deleteUser(userId));
              resolve("User deleted successfully!");
            } catch (error) {
              console.error(error);
              reject(error);
            }
          }),
          {
            loading: "Deleting...",
            success: "User deleted successfully!",
            error: "Failed to delete user",
          }
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      alert("Cancelled!");
    }
  };

  return (
    <main className="w-full flex flex-col">
      <div className="top-heading w-fit flex items-end justify-center gap-2">
        <h3 className="text-[1.2vw] font-semibold">All Users</h3>
        <p className="text-[0.8vw]">
          *Users who have access to the admin dashboard.
        </p>
      </div>
      <div className="all-users w-full my-4">
        {allUsers.isLoading ? (
          <div className="w-full flex items-center justify-center my-10">
            <Bars
              height="40"
              width="40"
              color="gray"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <table class="min-w-max w-full table-auto rounded-md overflow-hidden">
            <thead>
              <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th class="py-3 px-6 text-left">Name</th>
                <th class="py-3 px-6 text-left">Email</th>
                <th class="py-3 px-6 text-left">Created at</th>
                <th class="py-3 px-6 text-center">Actions</th>
              </tr>
            </thead>
            <tbody class="text-gray-600 text-sm font-light">
              {allUsers.data.map((user) => (
                <tr class="border-b border-gray-200 hover:bg-gray-100">
                  <td class="py-3 px-6 text-left whitespace-nowrap">
                    <div class="flex items-center">
                      <span class="font-medium">{user.name}</span>
                    </div>
                  </td>
                  <td class="py-3 px-6 text-left">
                    <div class="flex items-center">
                      <span>{user.email}</span>
                    </div>
                  </td>
                  <td class="py-3 px-6 text-left">
                    <div class="flex items-center">
                      <span>{formatDate(user.created_at)}</span>
                    </div>
                  </td>
                  <td class="py-3 px-6 text-center">
                    <div class="flex item-center justify-center">
                      <IconButton onClick={() => handleDeleteUser(user.id)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </td>
                </tr>
              ))}
              {/* <!-- Repeat similar <tr> blocks for more rows --> */}
            </tbody>
          </table>
        )}
      </div>
    </main>
  );
};

export default AllUsersContainer;
