import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { productData } from "../config/productData";
import { fontStyles } from "../config/constants";

const baseUrl = process.env.REACT_APP_API_URL_LOCAL;

export const getAllCustomProducts = createAsyncThunk(
  "adminCanvasState/getAllCustomProducts",
  async (data) => {
    try {
      // Make the API request here
      const response = await axios.get(`${baseUrl}/custom-product`);
      return response.data; // Assuming the response contains productData
    } catch (error) {
      throw error;
    }
  }
);

export const createCustomProduct = createAsyncThunk(
  "adminCanvasState/createCustomProduct",
  async (data) => {
    try {
      // Make the API request here
      const response = await axios.post(`${baseUrl}/custom-product`, data);
      return response.data; // Assuming the response contains productData
    } catch (error) {
      throw error;
    }
  }
);

export const updateCustomProduct = createAsyncThunk(
  "adminCanvasState/updateCustomProduct",
  async (data) => {
    try {
      // Make the API request here
      const response = await axios.put(
        `${baseUrl}/custom-product/${data.id}`,
        data.body
      );
      return response.data; // Assuming the response contains productData
    } catch (error) {
      throw error;
    }
  }
);

export const deleteCustomProduct = createAsyncThunk(
  "adminCanvasState/deleteCustomProduct",
  async (id) => {
    try {
      // Make the API request here
      const response = await axios.delete(`${baseUrl}/custom-product/${id}`);
      return response.data; // Assuming the response contains productData
    } catch (error) {
      throw error;
    }
  }
);

export const fetchProductData = createAsyncThunk(
  "adminCanvasState/fetchProductData",
  async (id) => {
    try {
      // Make the API request here
      const response = await axios.get(`${baseUrl}/custom-product/${id}`);
      // console.log(response.data);
      return response.data; // Assuming the response contains productData
    } catch (error) {
      throw error;
    }
  }
);

export const addFrontPathBackend = createAsyncThunk(
  "adminCanvasState/addFrontPathBackend",
  async ({ c_id, title, path, color, id }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/custom-product/front-path`,
        {
          custom_product_id: c_id,
          title: title,
          type: "path",
          path: path,
          color: color,
          id: id,
        }
      );
      return response.data[0];
    } catch (error) {
      throw error;
    }
  }
);
export const updateFrontPathBackend = createAsyncThunk(
  "adminCanvasState/updateFrontPathBackend",
  async ({ title, path, color, id }) => {
    try {
      const response = await axios.put(
        `${baseUrl}/custom-product/front-path/${id}`,
        {
          title: title,
          type: "path",
          path: path,
          color: color,
          id: id,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteFrontPathBackend = createAsyncThunk(
  "adminCanvasState/deleteFrontPathBackend",
  async (id) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/custom-product/front-path/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Back Path async reducers

export const addBackPathBackend = createAsyncThunk(
  "adminCanvasState/addBackPathBackend",
  async ({ c_id, title, path, color, id }) => {
    try {
      const response = await axios.post(`${baseUrl}/custom-product/back-path`, {
        custom_product_id: c_id,
        title: title,
        type: "path",
        path: path,
        color: color,
        id: id,
      });
      return response.data[0];
    } catch (error) {
      throw error;
    }
  }
);
export const updateBackPathBackend = createAsyncThunk(
  "adminCanvasState/updateBackPathBackend",
  async ({ title, path, color, id }) => {
    try {
      const response = await axios.put(
        `${baseUrl}/custom-product/back-path/${id}`,
        {
          title: title,
          type: "path",
          path: path,
          color: color,
          id: id,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteBackPathBackend = createAsyncThunk(
  "adminCanvasState/deleteBackPathBackend",
  async (id) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/custom-product/back-path/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Front Text Backend

export const addFrontTextBackend = createAsyncThunk(
  "adminCanvasState/addFrontTextBackend",
  async ({
    c_id,
    y,
    x,
    title,
    text,
    strokeWidth,
    fontSize,
    draggable,
    align,
    width,
    height,
    fill,
    stroke,
    fontFamily,
    id,
  }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/custom-product/front-text`,
        {
          y,
          x,
          title,
          text,
          strokeWidth,
          fontSize,
          draggable,
          align,
          width,
          height,
          fill,
          stroke,
          fontFamily,
          id,
          custom_product_id: c_id,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateFrontTextBackend = createAsyncThunk(
  "adminCanvasState/updateFrontTextBackend",
  async ({
    y,
    x,
    title,
    text,
    strokeWidth,
    fontSize,
    draggable,
    align,
    width,
    height,
    fill,
    stroke,
    fontFamily,
    id,
  }) => {
    try {
      const response = await axios.put(
        `${baseUrl}/custom-product/front-text/${id}`,
        {
          y,
          x,
          title,
          text,
          strokeWidth,
          fontSize,
          draggable,
          align,
          width,
          height,
          fill,
          stroke,
          fontFamily,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteFrontTextBackend = createAsyncThunk(
  "adminCanvasState/deleteFrontTextBackend",
  async (id) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/custom-product/front-text/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Back Text Backend

export const addBackTextBackend = createAsyncThunk(
  "adminCanvasState/addBackTextBackend",
  async ({
    c_id,
    y,
    x,
    title,
    text,
    strokeWidth,
    fontSize,
    draggable,
    align,
    width,
    height,
    fill,
    stroke,
    fontFamily,
    id,
  }) => {
    try {
      const response = await axios.post(`${baseUrl}/custom-product/back-text`, {
        y,
        x,
        title,
        text,
        strokeWidth,
        fontSize,
        draggable,
        align,
        width,
        height,
        fill,
        stroke,
        fontFamily,
        id,
        custom_product_id: c_id,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateBackTextBackend = createAsyncThunk(
  "adminCanvasState/updateBackTextBackend",
  async ({
    y,
    x,
    title,
    text,
    strokeWidth,
    fontSize,
    draggable,
    align,
    width,
    height,
    fill,
    stroke,
    fontFamily,
    id,
  }) => {
    try {
      const response = await axios.put(
        `${baseUrl}/custom-product/back-text/${id}`,
        {
          y,
          x,
          title,
          text,
          strokeWidth,
          fontSize,
          draggable,
          align,
          width,
          height,
          fill,
          stroke,
          fontFamily,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteBackTextBackend = createAsyncThunk(
  "adminCanvasState/deleteBackTextBackend",
  async (id) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/custom-product/back-text/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const adminCanvasSlice = createSlice({
  name: "adminCanvasState",
  initialState: {
    allCustomProducts: { isAllCustomProductsLoading: false, data: [] },
    isProductUpdating: false,
    isProductDeleting: false,
    isNewProductCreating: false,
    productData: { loading: false, data: null },
    fontStyles: fontStyles,
    // colors: productData?.colors,
    frontPathsArr: null,
    backPathsArr: null,
    frontMaskImage: null,
    backMaskImage: null,
    frontTextArray: null,
    backTextArray: null,
    userFrontLogo: {
      logo: null,
      x: 150,
      y: 200,
      title: "frontLogo",
      height: 220,
      width: 220,
    },
    userBackLogo: {
      logo: null,
      x: 150,
      y: 200,
      title: "backLogo",
      height: 220,
      width: 220,
    },
    frontCanvasExportImage: null,
    backCanvasExportImage: null,
    frontStageRef: null,
    backStageRef: null,
  },
  reducers: {
    changeFrontPathColor: (state, action) => {
      const { pathTitle, newColor } = action.payload;
      state.frontPathsArr = state.frontPathsArr.map((pathObj) => {
        if (pathTitle === pathObj.title) {
          return { ...pathObj, color: newColor, isSaved: false };
        }
        return pathObj;
      });
    },
    changeBackPathColor: (state, action) => {
      const { pathTitle, newColor } = action.payload;
      state.backPathsArr = state.backPathsArr.map((pathObj) => {
        if (pathTitle === pathObj.title) {
          return { ...pathObj, color: newColor, isSaved: false };
        }
        return pathObj;
      });
    },
    changeFrontText: (state, action) => {
      const { targetTitle, newText } = action.payload;

      state.frontTextArray = state.frontTextArray.map((textObj) => {
        if (targetTitle === textObj.title) {
          return { ...textObj, text: newText, isSaved: false };
        }
        return textObj;
      });
    },
    changeBackText: (state, action) => {
      const { targetTitle, newText } = action.payload;

      state.backTextArray = state.backTextArray.map((textObj) => {
        if (targetTitle === textObj.title) {
          return { ...textObj, text: newText, isSaved: false };
        }
        return textObj;
      });
    },
    changeTextFill: (state, action) => {
      const { targetElements, newColor } = action.payload;
      state.frontTextArray = state.frontTextArray.map((textObj) => {
        if (targetElements === textObj.title) {
          return { ...textObj, fill: newColor, isSaved: false };
        }
        return textObj;
      });
      state.backTextArray = state.backTextArray.map((textObj) => {
        if (targetElements === textObj.title) {
          return { ...textObj, fill: newColor, isSaved: false };
        }
        return textObj;
      });
    },
    changeTextStroke: (state, action) => {
      const { targetElements, newColor } = action.payload;

      state.frontTextArray = state.frontTextArray.map((textObj) => {
        if (targetElements === textObj.title) {
          return { ...textObj, stroke: newColor, isSaved: false };
        }
        return textObj;
      });

      state.backTextArray = state.backTextArray.map((textObj) => {
        if (targetElements === textObj.title) {
          return { ...textObj, stroke: newColor, isSaved: false };
        }
        return textObj;
      });
    },
    changeTextStrokeWidth: (state, action) => {
      const { targetElements, newStrokeWidth } = action.payload;

      state.frontTextArray = state.frontTextArray.map((textObj) => {
        if (targetElements === textObj.title) {
          return { ...textObj, strokeWidth: newStrokeWidth, isSaved: false };
        }
        return textObj;
      });

      state.backTextArray = state.backTextArray.map((textObj) => {
        if (targetElements === textObj.title) {
          return { ...textObj, strokeWidth: newStrokeWidth, isSaved: false };
        }
        return textObj;
      });
    },
    changeTextFontSize: (state, action) => {
      const { targetElements, newFontSize } = action.payload;

      state.frontTextArray = state.frontTextArray.map((textObj) => {
        if (targetElements === textObj.title) {
          return { ...textObj, fontSize: newFontSize, isSaved: false };
        }
        return textObj;
      });

      state.backTextArray = state.backTextArray.map((textObj) => {
        if (targetElements === textObj.title) {
          return { ...textObj, fontSize: newFontSize, isSaved: false };
        }
        return textObj;
      });
    },
    changePosition: (state, action) => {
      const { textArr, targetElementId, newX, newY } = action.payload;
      state[textArr] = state[textArr].map((textObj) => {
        if (targetElementId === textObj.id) {
          return { ...textObj, x: newX, y: newY, isSaved: false };
        }
        return textObj;
      });
    },
    changeFrontTextPosition: (state, action) => {
      const { targetID, newX, newY } = action.payload;
      state.frontTextArray = state.frontTextArray.map((textObj) => {
        if (targetID === textObj.id) {
          return { ...textObj, x: newX, y: newY, isSaved: false };
        }
        return textObj;
      });
    },
    changeBackTextPosition: (state, action) => {
      const { targetID, newX, newY } = action.payload;
      state.backTextArray = state.backTextArray.map((textObj) => {
        if (targetID === textObj.id) {
          return { ...textObj, x: newX, y: newY, isSaved: false };
        }
        return textObj;
      });
    },
    changeFontStyles: (state, action) => {
      const { targetElements, newFont } = action.payload;
      state.frontTextArray = state.frontTextArray.map((textObj) => {
        if (targetElements === textObj.title) {
          return { ...textObj, fontFamily: newFont, isSaved: false };
        }
        return textObj;
      });
      state.backTextArray = state.backTextArray.map((textObj) => {
        if (targetElements === textObj.title) {
          return { ...textObj, fontFamily: newFont, isSaved: false };
        }
        return textObj;
      });
    },
    addFrontLogo: (state, action) => {
      const { userLogo } = action.payload;
      state.userFrontLogo.logo = userLogo;
    },
    addBackLogo: (state, action) => {
      const { userLogo } = action.payload;
      state.userBackLogo.logo = userLogo;
    },
    changeLogoPosition: (state, action) => {
      const { logoStateName, newX, newY } = action.payload;
      state[logoStateName] = { ...state[logoStateName], x: newX, y: newY };
    },
    changeLogoDimensions: (state, action) => {
      const { logoStateName, newW, newH } = action.payload;
      state[logoStateName].width = newW;
      state[logoStateName].height = newH;
    },
    deleteElement: (state, action) => {},
    exportCanvas: (state, action) => {
      const { stageRef, downloadName } = action.payload;
      const dataUrl = stageRef.current.toDataURL();
      if (downloadName === "canvas-front") {
        state.frontCanvasExportImage = dataUrl;
      } else {
        state.backCanvasExportImage = dataUrl;
      }

      // const link = document.createElement("a");
      // link.download = downloadName;
      // link.href = dataUrl;
      // link.click();
    },
    updateStageRef: (state, action) => {
      const { canvasRef, refStateName } = action.payload;
      state[refStateName] = canvasRef;
    },
    changeFrontPath: (state, action) => {
      const { newPath, pathTitle } = action.payload;
      state.frontPathsArr = state.frontPathsArr.map((pathObj) => {
        if (pathTitle === pathObj.title) {
          return { ...pathObj, path: newPath, isSaved: false };
        }
        return pathObj;
      });
    },
    changeBackPath: (state, action) => {
      const { newPath, pathTitle } = action.payload;
      state.backPathsArr = state.backPathsArr.map((pathObj) => {
        if (pathTitle === pathObj.title) {
          return { ...pathObj, path: newPath, isSaved: false };
        }
        return pathObj;
      });
    },
    deleteFrontObject: (state, action) => {
      const { objectID } = action.payload;
      state.frontPathsArr = state.frontPathsArr.filter(
        (pathObj) => pathObj.id !== objectID
      );
    },
    deleteBackObject: (state, action) => {
      const { objectID } = action.payload;
      state.backPathsArr = state.backPathsArr.filter(
        (pathObj) => pathObj.id !== objectID
      );
    },
    addFrontObject: (state, action) => {
      const { newObject } = action.payload;
      if (state.frontPathsArr) {
        state.frontPathsArr = [...state.frontPathsArr, newObject];
      }
    },
    addBackObject: (state, action) => {
      const { newObject } = action.payload;
      state.backPathsArr = [...state.backPathsArr, newObject];
    },
    changeFrontMaskImage: (state, action) => {
      const { newImageSrc } = action.payload;
      state.frontMaskImage.src = newImageSrc;
    },
    deleteFrontMaskImage: (state, action) => {
      state.frontMaskImage.src = "";
    },
    deleteBackMaskImage: (state, action) => {
      state.backMaskImage.src = "";
    },
    changeBackMaskImage: (state, action) => {
      const { newImageSrc } = action.payload;
      state.backMaskImage.src = newImageSrc;
    },
    deleteFrontTextObject: (state, action) => {
      const { objectID } = action.payload;
      state.frontTextArray = state.frontTextArray.filter(
        (textObj) => textObj.id !== objectID
      );
    },
    deleteBackTextObject: (state, action) => {
      const { objectID } = action.payload;
      state.backTextArray = state.backTextArray.filter(
        (textObj) => textObj.id !== objectID
      );
    },
    addFrontTextObject: (state, action) => {
      const { newTextObject } = action.payload;
      state.frontTextArray = [...state.frontTextArray, newTextObject];
    },
    addBackTextObject: (state, action) => {
      const { newTextObject } = action.payload;
      state.backTextArray = [...state.backTextArray, newTextObject];
    },
    changeFrontTitle: (state, action) => {
      const { targetID, newTitle } = action.payload;
      state.frontTextArray = state.frontTextArray.map((textObj) => {
        if (textObj.id === targetID) {
          return { ...textObj, title: newTitle, isSaved: false };
        }
        return textObj;
      });
    },
    changeBackTitle: (state, action) => {
      const { targetID, newTitle } = action.payload;
      state.backTextArray = state.backTextArray.map((textObj) => {
        if (textObj.id === targetID) {
          return { ...textObj, title: newTitle, isSaved: false };
        }
        return textObj;
      });
    },
    changeFrontPathTitle: (state, action) => {
      const { targetID, newTitle } = action.payload;
      state.frontPathsArr = state.frontPathsArr.map((pathObj) => {
        if (pathObj.id === targetID) {
          return { ...pathObj, title: newTitle, isSaved: false };
        }
        return pathObj;
      });
    },
    changeBackPathTitle: (state, action) => {
      const { targetID, newTitle } = action.payload;
      state.backPathsArr = state.backPathsArr.map((pathObj) => {
        if (pathObj.id === targetID) {
          return { ...pathObj, title: newTitle, isSaved: false };
        }
        return pathObj;
      });
    },
    resetState: (state) => {
      state.productData.data = [];
      state.frontPathsArr = [];
      state.backPathsArr = [];
      state.frontTextArray = [];
      state.backTextArray = [];
      state.frontMaskImage = null;
      state.backMaskImage = null;
      return;
    },
    changeTextObjIsSaved: (state, action) => {
      const { targetID, value, applyTo } = action.payload;
      if (applyTo === "front") {
        state.frontTextArray = state.frontTextArray.map((textObj) => {
          if (textObj.id === targetID) {
            return { ...textObj, isSaved: value };
          }
          return textObj;
        });
      }
      if (applyTo === "back") {
        state.backTextArray = state.backTextArray.map((textObj) => {
          if (textObj.id === targetID) {
            return { ...textObj, isSaved: value };
          }
          return textObj;
        });
      }
    },
    changePathObjIsSaved: (state, action) => {
      const { targetID, value, applyTo } = action.payload;
      if (applyTo === "front") {
        state.frontPathsArr = state.frontPathsArr.map((pathObj) => {
          if (pathObj.id === targetID) {
            return { ...pathObj, isSaved: value };
          }
          return pathObj;
        });
      }
      if (applyTo === "back") {
        state.backPathsArr = state.backPathsArr.map((pathObj) => {
          if (pathObj.id === targetID) {
            return { ...pathObj, isSaved: value };
          }
          return pathObj;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomProducts.fulfilled, (state, action) => {
        state.allCustomProducts.isAllCustomProductsLoading = false;
        state.allCustomProducts.data = action.payload;
      })
      .addCase(getAllCustomProducts.rejected, (state, action) => {
        state.allCustomProducts.isAllCustomProductsLoading = false;
        console.log(action.error.message);
      })
      .addCase(getAllCustomProducts.pending, (state, action) => {
        state.allCustomProducts.isAllCustomProductsLoading = true;
      })
      .addCase(fetchProductData.fulfilled, (state, action) => {
        state.productData.loading = false;
        state.productData.data = action.payload;
        state.frontPathsArr = state.productData.data.frontobjects
          ? state.productData.data.frontobjects.map((obj) => ({
              ...obj,
              isSaved: true, // Set isSaved to true initially when retrieving data from DB
            }))
          : [];
        state.backPathsArr = state.productData.data.backobjects
          ? state.productData.data.backobjects.map((obj) => ({
              ...obj,
              isSaved: true, // Set isSaved to true initially when retrieving data from DB
            }))
          : [];
        state.frontTextArray = state.productData.data.fronttext
          ? state.productData.data.fronttext.map((textObj) => ({
              ...textObj,
              isSaved: true, // Set isSaved to true initially
            }))
          : [];

        state.backTextArray = state.productData.data.backtext
          ? state.productData.data.backtext.map((textObj) => ({
              ...textObj,
              isSaved: true, // Set isSaved to true initially
            }))
          : [];

        state.frontMaskImage = state.productData.data.frontmaskimage
          ? state.productData.data.frontmaskimage
          : {};
        state.frontMaskImage.src = state.productData.data?.frontmaskimage?.src
          ? `${baseUrl}/uploads/${state.productData.data?.frontmaskimage?.src}`
          : "";

        state.backMaskImage = state.productData.data?.backmaskimage
          ? state.productData.data?.backmaskimage
          : {};

        state.backMaskImage.src = state.productData.data?.backmaskimage?.src
          ? `${baseUrl}/uploads/${state.productData.data?.backmaskimage?.src}`
          : "";
      })
      .addCase(fetchProductData.rejected, (state, action) => {
        state.productData.loading = false;
        console.error("Failed to fetch product Data: ", action.error.message);
      })
      .addCase(fetchProductData.pending, (state, action) => {
        state.productData.loading = true;
      })
      .addCase(resetState, (state) => {
        state.productData.data = [];
        state.frontPathsArr = [];
        state.backPathsArr = [];
        state.frontTextArray = [];
        state.backTextArray = [];
        state.frontMaskImage = null;
        state.backMaskImage = null;
        return;
      })
      .addCase(createCustomProduct.pending, (state, action) => {
        state.isNewProductCreating = true;
      })
      .addCase(createCustomProduct.fulfilled, (state, action) => {
        state.isNewProductCreating = false; // TODO:
        const newCreatedProduct = action.payload;
        // console.log("Newly Created Product:", newCreatedProduct[0]);
        state.allCustomProducts.data.push(newCreatedProduct[0]);
      })
      .addCase(createCustomProduct.rejected, (state, action) => {
        state.isNewProductCreating = false;
        console.log(action.error.message);
      })
      .addCase(updateCustomProduct.pending, (state, action) => {
        state.isProductUpdating = true;
      })
      .addCase(updateCustomProduct.fulfilled, (state, action) => {
        state.isProductUpdating = false;
        const updatedProduct = action.payload[0];
        state.productData.data = {
          ...state.productData.data,
          ...updatedProduct,
        };
      })
      .addCase(updateCustomProduct.rejected, (state, action) => {
        state.isProductUpdating = false;
        console.log(action.error.message);
      })
      .addCase(deleteCustomProduct.pending, (state, action) => {
        state.isProductDeleting = true;
      })
      .addCase(deleteCustomProduct.fulfilled, (state, action) => {
        state.isProductDeleting = false;
        const deletedProd = action.payload;
        state.allCustomProducts.data = state.allCustomProducts.data.filter(
          (customProd) => customProd.c_id !== deletedProd[0].c_id
        );
      })
      .addCase(deleteCustomProduct.rejected, (state, action) => {
        state.isProductDeleting = false;
        console.log(action.error.message);
      })
      .addCase(addBackPathBackend.fulfilled, (state, action) => {
        state.backPathsArr.push(action.payload);
      })
      .addCase(addFrontPathBackend.fulfilled, (state, action) => {
        state.frontPathsArr.push(action.payload);
      });
  },
});

export const {
  changeFrontPathColor,
  changeBackPathColor,
  changeFrontText,
  changeBackText,
  changeTextFill,
  changeTextStroke,
  changeTextStrokeWidth,
  changeTextFontSize,
  changeFontStyles,
  addFrontLogo,
  addBackLogo,
  changePosition,
  changeLogoPosition,
  changeLogoDimensions,
  exportCanvas,
  updateStageRef,
  changeFrontPath,
  changeBackPath,
  deleteFrontObject,
  deleteBackObject,
  addFrontObject,
  addBackObject,
  changeFrontMaskImage,
  changeBackMaskImage,
  deleteFrontTextObject,
  deleteBackTextObject,
  addFrontTextObject,
  addBackTextObject,
  changeFrontTextPosition,
  changeBackTextPosition,
  changeFrontTitle,
  changeBackTitle,
  changeFrontPathTitle,
  changeBackPathTitle,
  deleteFrontMaskImage,
  deleteBackMaskImage,
  resetState,
  changeTextObjIsSaved,
  changePathObjIsSaved,
} = adminCanvasSlice.actions;
// export { fetchProductData };

export const selectAllCustomProducts = (state) =>
  state.adminCanvasState.allCustomProducts;
export const selectIsCustomProductCreating = (state) =>
  state.adminCanvasState.isNewProductCreating;
export const selectIsCustomProductUpdating = (state) =>
  state.adminCanvasState.isProductUpdating;
export const selectIsCustomProductDeleting = (state) =>
  state.adminCanvasState.isProductDeleting;
export const selectProductData = (state) => state.adminCanvasState.productData;
export const selectAdminCanvasFrontView = (state) =>
  state.adminCanvasState.isFrontView;
export const selectColors = (state) => state.adminCanvasState.colors;
export const selectFrontPaths = (state) => state.adminCanvasState.frontPathsArr;
export const selectBackPaths = (state) => state.adminCanvasState.backPathsArr;
export const selectFrontMaskImage = (state) =>
  state.adminCanvasState.frontMaskImage;
export const selectBackMaskImage = (state) =>
  state.adminCanvasState.backMaskImage;
export const selectFrontTextArray = (state) =>
  state.adminCanvasState.frontTextArray;
export const selectBackTextArray = (state) =>
  state.adminCanvasState.backTextArray;
export const selectFontStyles = (state) => state.adminCanvasState.fontStyles;
export const selectUserFrontLogo = (state) =>
  state.adminCanvasState.userFrontLogo;
export const selectUserBackLogo = (state) =>
  state.adminCanvasState.userBackLogo;
export const selectFrontCanvasExportImage = (state) =>
  state.adminCanvasState.frontCanvasExportImage;
export const selectBackCanvasExportImage = (state) =>
  state.adminCanvasState.backCanvasExportImage;
export const selectFrontCanvasRef = (state) =>
  state.adminCanvasState.frontStageRef;
export const selectBackCanvasRef = (state) =>
  state.adminCanvasState.backStageRef;

export default adminCanvasSlice.reducer;
