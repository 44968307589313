import { Box, Button, Typography } from "@mui/material";
import React from "react";
import ColorPaletteSelector from "./ColorPaletteSelector";
import { setCustomizeTabIndex } from "../../slices/canvasSlice";
import { useDispatch } from "react-redux";

const ColorsPalletteEditor = () => {
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mb: 1,
      }}
    >
      <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>
        Choose the colors for your design
      </Typography>
      <Box sx={{ width: "100%", p: 2, mt: 2 }}>
        <ColorPaletteSelector />
      </Box>
      <Button
        variant="contained"
        disableFocusRipple
        disableElevation
        fullWidth
        sx={{
          bgcolor: "#fff",
          color: "#040707",
          border: "1px solid #040707",
          "&:hover": {
            border: "#f26728 solid 1px",
            color: "#f26728",
            backgroundColor: "rgba(242, 103, 40, 0.1)",
          },
        }}
        onClick={() => {
          dispatch(setCustomizeTabIndex("colors"));
        }}
      >
        Next
      </Button>
    </Box>
  );
};

export default ColorsPalletteEditor;
