import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import React from "react";
import contactFormImage from "../../../assets/images/contact-form-image.png";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axios from "axios";

const ContactForm = () => {
  const initialValues = {
    name: "",
    email: "",
    message: "",
    phoneNumber: "", // Adding phoneNumber field
  };

  const onSubmit = (values, { resetForm }) => {
    // console.log("form submission", values);
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL_LOCAL}/send-enquiry`,
            values
          );
          resolve(response.data);
          resetForm();
        } catch (error) {
          reject(error);
        }
      }),
      {
        loading: "Sending...",
        success: "Message Sent Successfully",
        error: "Something went wrong",
      }
    );
  };

  const validate = (values) => {
    let errors = {};

    if (!values.name) {
      errors.name = "Name is Required!";
    }

    if (!values.email) {
      errors.email = "Email is Required!";
    } else if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(values.email)
    ) {
      errors.email = "Invalid Email Format";
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = "Phone Number is Required!";
    }

    if (!values.message) {
      errors.message = "Message is Required!";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "fit-content",
        height: "fit-content",
        px: { xs: 2, md: 8 },
        py: 3,
        my: 4,
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        justifyContent: "space-between",
      }}
    >
      {/* Box 01 Start */}
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          height: { xs: "fit-content", md: "fit-content" },
          // bgcolor: "#262626",
          p: "40px 40px",
        }}
      >
        <Typography sx={{ fontSize: "42px", fontWeight: 600, color: "#000" }}>
          Tell us about your <span style={{ color: "#f26728" }}>Concerns</span>
        </Typography>
        <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
          <FormControl fullWidth>
            <TextField
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              variant="outlined"
              size="small"
              label="Enter Your Name"
              sx={{
                my: 1,
                width: "100%",
                minWidth: "100%",
                color: "#9D9D9D",
                "& label": {
                  color: "#9D9D9D",
                },
                "& label.Mui-focused": {
                  color: "#9D9D9D",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#9D9D9D",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#9D9D9D",
                  },
                  "&:hover fieldset": {
                    borderColor: "#9D9D9D",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#9D9D9D",
                  },
                },
                input: {
                  color: "#5f5f5f",
                },
              }}
            />
            {formik.errors.name && formik.touched.name ? (
              <Typography sx={{ color: "#FF3333", fontSize: "13px" }}>
                {formik.errors.name}
              </Typography>
            ) : null}
          </FormControl>
          <FormControl fullWidth>
            <TextField
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              variant="outlined"
              size="small"
              label="Enter Email Address"
              sx={{
                my: 1,
                width: "100%",
                minWidth: "100%",
                color: "#9D9D9D",
                "& label": {
                  color: "#9D9D9D",
                },
                "& label.Mui-focused": {
                  color: "#9D9D9D",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#9D9D9D",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#9D9D9D",
                  },
                  "&:hover fieldset": {
                    borderColor: "#9D9D9D",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#9D9D9D",
                  },
                },
                input: {
                  color: "#5f5f5f",
                },
              }}
            />
            {formik.errors.email && formik.touched.email ? (
              <Typography sx={{ color: "#FF3333", fontSize: "13px" }}>
                {formik.errors.email}
              </Typography>
            ) : null}
          </FormControl>
          <FormControl fullWidth>
            <TextField
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="phoneNumber"
              variant="outlined"
              size="small"
              label="Enter Phone Number"
              sx={{
                my: 1,
                width: "100%",
                minWidth: "100%",
                color: "#9D9D9D",
                "& label": {
                  color: "#9D9D9D",
                },
                "& label.Mui-focused": {
                  color: "#9D9D9D",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#9D9D9D",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#9D9D9D",
                  },
                  "&:hover fieldset": {
                    borderColor: "#9D9D9D",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#9D9D9D",
                  },
                },
                input: {
                  color: "#5f5f5f",
                },
              }}
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
              <Typography sx={{ color: "#FF3333", fontSize: "13px" }}>
                {formik.errors.phoneNumber}
              </Typography>
            ) : null}
          </FormControl>
          <FormControl fullWidth>
            <TextField
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="message"
              variant="outlined"
              size="small"
              label="Tell Us About Your Concerns"
              multiline
              rows={6}
              sx={{
                my: 1,
                width: "100%",
                minWidth: "100%",
                color: "#9D9D9D",
                "& label": {
                  color: "#9D9D9D",
                },
                "& label.Mui-focused": {
                  color: "#9D9D9D",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#9D9D9D",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#9D9D9D",
                  },
                  "&:hover fieldset": {
                    borderColor: "#9D9D9D",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#9D9D9D",
                  },
                },
              }}
              inputProps={{
                style: { color: "#5f5f5f" },
              }}
            />
            {formik.errors.message && formik.touched.message ? (
              <Typography sx={{ color: "#FF3333", fontSize: "13px" }}>
                {formik.errors.message}
              </Typography>
            ) : null}
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            size="large"
            sx={{
              width: "100%",
              bgcolor: "#f26728",
              color: "#FFFFFF",
              "&:hover": {
                bgcolor: "#f26728",
                color: "#FFFFFF",
              },
              my: 2,
            }}
            disableElevation
            disabled={!formik.isValid}
          >
            Send
          </Button>
        </Box>
      </Box>
      {/* Box 01 End */}
      {/* Box 02 Start */}
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          // height: "fit-content",
          backgroundImage: `url(${contactFormImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top center",
          display: { xs: "none", md: "flex" },
        }}
      ></Box>
      {/* Box 02 End */}
    </Box>
  );
};

export default ContactForm;
