import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import AllContainer from "../AllContainer/AllContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  selectAllProducts,
} from "../../../slices/productSlice";
import {
  getAllCategories,
  selectAllCategories,
} from "../../../slices/categoriesSlice";
import AddNewProductDialog from "./AddNewProductDialog";
import AddIcon from "@mui/icons-material/Add";

const Product = () => {
  const allProducts = useSelector(selectAllProducts);
  const [allProductsArray, setAllProductsArray] = useState([]);
  const allCategories = useSelector(selectAllCategories);
  const dispatch = useDispatch();
  useEffect(() => {
    const allProductsDataArray = allProducts.data;
    const allCategoriesDataArray = allCategories.data;
    if (allProductsDataArray.length === 0) {
      dispatch(getAllProducts());
    }
    if (allCategoriesDataArray.length === 0) {
      dispatch(getAllCategories());
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (Array.isArray(allProducts?.data)) {
      setAllProductsArray(allProducts?.data);
    }
  }, [allProducts?.data]);

  const [isAddProdDialogOpen, setIsAddProdDialogOpen] = useState(false);

  const handleAddNewProd = () => {
    setIsAddProdDialogOpen(true);
  };

  const productActions = [
    {
      title: "Add New Product",
      handleClick: handleAddNewProd,
      icon: AddIcon,
      color: "#687175",
    },
  ];
  const searchProducts = (searchQuery) => {
    // Convert search query to lowercase for case-insensitive search
    const query = searchQuery.toLowerCase().trim();
    if (query.length === 0) {
      setAllProductsArray(allProducts?.data);
    } else {
      // Filter products based on name or SKU
      const filteredProducts = allProducts?.data.filter((product) => {
        const productName = product.name.toLowerCase();
        const productSKU = product.sku.toLowerCase();
        const productCategoryName = product.category_name.toLowerCase();
        return (
          productName.includes(query) ||
          productSKU.includes(query) ||
          productCategoryName.includes(query)
        );
      });

      setAllProductsArray(filteredProducts);
      return filteredProducts;
    }
  };

  return (
    <Box sx={{ width: "100%", height: "fit-content", p: 2 }}>
      <AddNewProductDialog
        open={isAddProdDialogOpen}
        setOpen={setIsAddProdDialogOpen}
        allCategories={allCategories}
      />
      <AllContainer
        title={"Products"}
        actions={productActions}
        data={allProductsArray}
        searchFunction={searchProducts}
        isLoading={allProducts.isLoading}
        redirectUrl={`/dashboard/products/`}
      />
    </Box>
  );
};

export default Product;
