import { Box, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import ColorsEditor from "./ColorsEditor";
import TextNumbersEditor from "./TextNumbersEditor";
import LogoEditor from "./LogoEditor";
import RosterEditor from "./RosterEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCustomizeTabIndex,
  setCustomizeTabIndex,
} from "../../slices/canvasSlice";
import {
  selectIsAwayActive,
  toggleIsActive,
} from "../../slices/awayCanvasSlice";
import Canvas from "../Common/Canvas";
import ColorsPalletteEditor from "./ColorsPalletteEditor";

const CanvasEditor = () => {
  const dispatch = useDispatch();
  const [isFrontView, setIsFrontView] = useState(true);

  const customizeTabId = useSelector(selectCustomizeTabIndex);
  const isAwayActive = useSelector(selectIsAwayActive);

  const handleChange = (tabId) => {
    dispatch(setCustomizeTabIndex(tabId));
  };
  const tabs = [
    { id: "palette", label: "Palette", component: <ColorsPalletteEditor /> },
    {
      id: "colors",
      label: "Colors",
      component: (
        <ColorsEditor
          isFrontView={isFrontView}
          setIsFrontView={setIsFrontView}
        />
      ),
    },
    {
      id: "textNumbers",
      label: "Text and Numbers",
      component: (
        <TextNumbersEditor
          isFrontView={isFrontView}
          setIsFrontView={setIsFrontView}
        />
      ),
    },
    {
      id: "logos",
      label: "Logos",
      component: (
        <LogoEditor isFrontView={isFrontView} setIsFrontView={setIsFrontView} />
      ),
    },
    { id: "roster", label: "Roster", component: <RosterEditor /> },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Tabs
        value={customizeTabId}
        onChange={(e, tabId) => handleChange(tabId)}
        centered
        TabIndicatorProps={{ style: { backgroundColor: "#040707" } }}
        sx={{
          "& .MuiTab-root": {
            // Target all tabs
            color: "#040707", // Inherit text color (adjust if needed)
            "&.Mui-selected": {
              // Target the active tab bar
              color: "#040707", // Inherit text color (adjust if needed)
            },
            "&:hover": {
              // Simulate ripple effect with hover
              color: "#040707", // Inherit text color (adjust if needed)

              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(4, 7, 7, 0.2)" // Adjust alpha for dark mode
                  : "rgba(4, 7, 7, 0.1)", // Adjust alpha for light mode
            },
          },
        }}
      >
        {tabs.map((tab) => (
          <Tab key={tab.id} label={tab.label} value={tab.id} />
        ))}
      </Tabs>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { sm: "column", md: "row" },
          flexWrap: "wrap",
          alignItems: { sm: "center", md: "start" },
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: {
              sm: "100%",
              md: customizeTabId === "roster" ? "100%" : "50%",
              display: customizeTabId === "roster" && "none",
            },
          }}
        >
          <Canvas isFrontView={isFrontView} setIsFrontView={setIsFrontView} />
        </Box>

        <Box
          sx={{
            mt: 2,
            width: {
              sm: "100%",
              md: customizeTabId === "roster" ? "100%" : "50%",
            },
            zIndex: "2",
          }}
        >
          {tabs.map((tab) =>
            customizeTabId === tab.id ? (
              <React.Fragment key={tab.id}>{tab.component}</React.Fragment>
            ) : null
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CanvasEditor;
