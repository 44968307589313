import React, { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router";

const Searchbar = ({ handleToogleView }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_API_URL_LOCAL;

  useEffect(() => {
    if (searchTerm === "") {
      setResults([]);
      setIsSearching(false);
      return;
    }

    const fetchResults = async () => {
      setIsSearching(true);
      try {
        const response = await fetch(
          `${baseURL}/products/search/${searchTerm}`
        );
        const data = await response.json();
        setResults(data);
      } catch (error) {
        console.error("Error fetching search results:", error);
        setResults([]);
      } finally {
        setIsSearching(false);
      }
    };

    // const debounceFetch = setTimeout(fetchResults, 300);
    fetchResults();

    // return () => clearTimeout(debounceFetch);
  }, [searchTerm, baseURL]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="relative w-72 mx-auto">
      <div className="relative">
        <input
          type="text"
          className="w-full pl-10 pr-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:shadow-lg"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
      </div>
      {results.length > 0 && searchTerm !== "" && (
        <div className="absolute z-10 w-full border rounded-lg shadow-lg mt-2 min-h-[40px] max-h-[80dvh] overflow-y-auto bg-white">
          {isSearching ? (
            <p className="px-4 py-2">Searching...</p>
          ) : results.length > 0 ? (
            <ul>
              {results.map((result, index) => (
                <li
                  key={index}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center justify-between gap-x-2"
                  onClick={() => {
                    navigate(`/product/${result.id}`);
                    setSearchTerm("");
                    handleToogleView();
                  }}
                >
                  <img
                    src={
                      result.product_images
                        ? `${baseURL}/uploads/${
                            result?.product_images.find(
                              (img) => img.type === "primary"
                            ).image_url
                          }`
                        : null
                    }
                    alt="product"
                    className="h-full aspect-square w-[20%]"
                  />
                  <div className="flex flex-col w-[80%]">
                    <h5 className="text-[3.3vw] md:text-[0.9vw]">
                      {result.name}
                    </h5>
                    <p className="text-[2.9vw] md:text-[0.8vw]">{result.sku}</p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="px-4 py-2">No Products Found!</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Searchbar;
