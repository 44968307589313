import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TwitterPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import {
  changeColor,
  selectBackPaths,
  selectColors,
  selectColorsArray,
  selectFrontPaths,
  setCustomizeTabIndex,
} from "../../slices/canvasSlice";
import { twitterPickerStyleClient } from "../../config/constants";

const ColorsEditor = ({ isFrontView, setIsFrontView }) => {
  const colors = useSelector(selectColors);
  const frontPaths = useSelector(selectFrontPaths);
  const backPaths = useSelector(selectBackPaths);
  const dispatch = useDispatch();
  // State to keep track of the expanded accordion panel
  const [expandedPanelFront, setExpandedPanelFront] = useState(
    Array.isArray(frontPaths) && frontPaths[0].title
  );
  const [expandedPanelBack, setExpandedPanelBack] = useState(
    Array.isArray(backPaths) && backPaths[0].title
  );

  // Function to handle accordion panel changes
  const handleChangeExpandedPanelFront = (panel) => (event, isExpanded) => {
    setExpandedPanelFront(isExpanded ? panel : null);
  };
  const handleChangeExpandedPanelBack = (panel) => (event, isExpanded) => {
    setExpandedPanelBack(isExpanded ? panel : null);
  };

  const [frontBackPathsEditorTabsIndex, setFrontBackPathsEditorTabsIndex] =
    useState(0);

  const handlefrontBackPathsEditorTabsIndexChange = (event, newValue) => {
    setFrontBackPathsEditorTabsIndex(newValue);
    if (newValue === 0) {
      setIsFrontView(true);
    } else if (newValue === 1) {
      setIsFrontView(false);
    }
  };

  const selectedColorsPalette = useSelector(selectColorsArray);

  useEffect(() => {
    if (isFrontView) {
      setFrontBackPathsEditorTabsIndex(0);
    } else {
      setFrontBackPathsEditorTabsIndex(1);
    }
  }, [isFrontView]);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Tabs
          value={frontBackPathsEditorTabsIndex}
          onChange={handlefrontBackPathsEditorTabsIndexChange}
          TabIndicatorProps={{ style: { backgroundColor: "#040707" } }}
          sx={{
            "& .MuiTab-root": {
              // Target all tabs
              color: "#040707", // Inherit text color (adjust if needed)
              "&.Mui-selected": {
                // Target the active tab bar
                color: "#040707", // Inherit text color (adjust if needed)
              },
              "&:hover": {
                // Simulate ripple effect with hover
                color: "#040707", // Inherit text color (adjust if needed)

                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(4, 7, 7, 0.2)" // Adjust alpha for dark mode
                    : "rgba(4, 7, 7, 0.1)", // Adjust alpha for light mode
              },
            },
          }}
        >
          <Tab label="Front" />
          <Tab label="Back" />
        </Tabs>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          display: frontBackPathsEditorTabsIndex === 0 ? "block" : "none",
        }}
      >
        {frontPaths?.map((frontPathObj, i) => {
          // console.log(frontPathObj);

          return (
            <Accordion
              expanded={expandedPanelFront === frontPathObj?.title}
              onChange={handleChangeExpandedPanelFront(frontPathObj?.title)}
              sx={{
                bgcolor: "#f6f9fb",
                my: 1,
                borderBottomLeftRadius: "0.5vw",
                borderBottomRightRadius: "0.5vw",
                ...(i === 0 && {
                  borderToprightRadius: "0 !important",
                  borderTopLeftRadius: "0 !important",
                }),
              }}
              elevation={0}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  sx={{ fontSize: "17px", fontWeight: 500, color: "#262626" }}
                >
                  {frontPathObj?.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Array.isArray(selectedColorsPalette) &&
                selectedColorsPalette.length > 0 ? (
                  <TwitterPicker
                    triangle="hide"
                    width="100%"
                    colors={selectedColorsPalette}
                    onChange={(color) =>
                      dispatch(
                        changeColor({
                          targetElements: frontPathObj?.title,
                          newColor: color.hex,
                          applyTo: "front",
                        })
                      )
                    }
                    styles={twitterPickerStyleClient}
                  />
                ) : (
                  <Typography sx={{ color: "#8a8a8a", fontStyle: "italic" }}>
                    No colors selected{" "}
                    <span
                      className="not-italic text-[#f26728] underline cursor-pointer"
                      onClick={() => {
                        dispatch(setCustomizeTabIndex("palette"));
                      }}
                    >
                      Click to select
                    </span>
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "12px",
            marginTop: "16px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "50%",
              bgcolor: "#fff",
              color: "#040707",
              border: "1px solid #040707",
              "&:hover": {
                border: "#f26728 solid 1px",
                color: "#f26728",
                backgroundColor: "rgba(242, 103, 40, 0.1)",
              },
            }}
            disableFocusRipple
            disableElevation
            onClick={() => {
              dispatch(setCustomizeTabIndex("palette"));
            }}
          >
            Back
          </Button>

          <Button
            variant="contained"
            sx={{
              width: "50%",
              bgcolor: "#fff",
              color: "#040707",
              border: "1px solid #040707",
              "&:hover": {
                border: "#f26728 solid 1px",
                color: "#f26728",
                backgroundColor: "rgba(242, 103, 40, 0.1)",
              },
            }}
            disableFocusRipple
            disableElevation
            onClick={() => {
              setFrontBackPathsEditorTabsIndex(1);
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          display: frontBackPathsEditorTabsIndex === 1 ? "block" : "none",
        }}
      >
        {backPaths?.map((backPathObj, i) => {
          // console.log(backPathObj);
          return (
            <Accordion
              expanded={expandedPanelBack === backPathObj?.title}
              onChange={handleChangeExpandedPanelBack(backPathObj?.title)}
              sx={{
                bgcolor: "#f6f9fb",
                my: 1,
                borderBottomLeftRadius: "0.5vw",
                borderBottomRightRadius: "0.5vw",
                ...(i === 0 && {
                  borderToprightRadius: "0 !important",
                  borderTopLeftRadius: "0 !important",
                }),
              }}
              elevation={0}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  sx={{ fontSize: "17px", fontWeight: 500, color: "#262626" }}
                >
                  {backPathObj?.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Array.isArray(selectedColorsPalette) &&
                selectedColorsPalette.length > 0 ? (
                  <TwitterPicker
                    triangle="hide"
                    width="100%"
                    colors={selectedColorsPalette}
                    onChange={(color) =>
                      dispatch(
                        changeColor({
                          targetElements: backPathObj?.title,
                          newColor: color.hex,
                          applyTo: "back",
                        })
                      )
                    }
                    styles={twitterPickerStyleClient}
                  />
                ) : (
                  <Typography sx={{ color: "#8a8a8a", fontStyle: "italic" }}>
                    No colors selected{" "}
                    <span
                      className="not-italic text-[#f26728] underline cursor-pointer"
                      onClick={() => {
                        dispatch(setCustomizeTabIndex("palette"));
                      }}
                    >
                      Click to select
                    </span>
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "12px",
            marginTop: "16px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "50%",
              bgcolor: "#fff",
              color: "#040707",
              border: "1px solid #040707",
              "&:hover": {
                border: "#f26728 solid 1px",
                color: "#f26728",
                backgroundColor: "rgba(242, 103, 40, 0.1)",
              },
            }}
            disableFocusRipple
            disableElevation
            onClick={() => {
              setFrontBackPathsEditorTabsIndex(0);
            }}
          >
            Back
          </Button>

          <Button
            variant="contained"
            sx={{
              width: "50%",
              bgcolor: "#fff",
              color: "#040707",
              border: "1px solid #040707",
              "&:hover": {
                border: "#f26728 solid 1px",
                color: "#f26728",
                backgroundColor: "rgba(242, 103, 40, 0.1)",
              },
            }}
            disableFocusRipple
            disableElevation
            onClick={() => {
              dispatch(setCustomizeTabIndex("textNumbers"));
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ColorsEditor;
