import { Box, Card, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Canvas from "../../components/Common/Canvas";
import FPDCanvas from "../../components/Common/FPDCanvas";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomProductById,
  selectBackCanvasExportImage,
  selectCustomizeTabIndex,
  selectFrontCanvasExportImage,
  selectIsCustomProductLoading,
  selectProductRoster,
} from "../../slices/canvasSlice";
import CanvasEditor from "../../components/Canvas/CanvasEditor";
import SendInquiryForm from "../../components/Forms/SendInquiryForm";
import { useParams } from "react-router";
import { Puff } from "react-loader-spinner";
import formImage from "../../assets/images/contact-form-image.png";
import { Helmet } from "react-helmet";


const Customize = () => {
  const dispatch = useDispatch();
  const frontCanvasImage = useSelector(selectFrontCanvasExportImage);
  const backCanvasImage = useSelector(selectBackCanvasExportImage);
  const customizeTabIndex = useSelector(selectCustomizeTabIndex);

  const { custom_product_id } = useParams();
  // console.log(custom_product_id);

  const isCustomProductLoading = useSelector(selectIsCustomProductLoading);

  useEffect(() => {
    try {
      dispatch(getCustomProductById(custom_product_id));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const productRoster = useSelector(selectProductRoster);

  return (
    <Box sx={{ width: "90%", mx: "auto", my: 3 }}>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Customizer | US Sports</title>
      </Helmet>
      {/* <FPDCanvas /> */}
      {/* <Canvas /> */}
      {isCustomProductLoading ? (
        <Box
          sx={{
            height: "400px",
            width: "80%",
            mx: "auto",
            p: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Puff height={60} width={60} color="#66ccff" />
          <Typography>The custom product is loading...</Typography>
        </Box>
      ) : (
        <CanvasEditor />
      )}

      {/* Selected Product Images... */}
      {customizeTabIndex === "roster" && (
        <>
          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {frontCanvasImage ? (
              <img
                src={frontCanvasImage}
                alt=""
                style={{ width: "50%", aspectRatio: "1/1" }}
              />
            ) : null}
            {backCanvasImage ? (
              <img
                src={backCanvasImage}
                alt=""
                style={{ width: "50%", aspectRatio: "1/1" }}
              />
            ) : null}
          </Box>

          {/* Send Inquiry Form... */}
          <Box
            sx={{
              width: "100%",
              height: "fit-content",
              display: "flex",
              alignItems: "stretch",
              justifyContent: "start",
              flexDirection: { xs: "column", md: "row" },
              p: "16px 10px",
              borderRadius: "6px",
              mt: 2,
            }}
          >
            <Box
              sx={{
                width: "50%",
                p: "0 10px",
                borderRadius: "6px",
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Inquiry Form
              </Typography>
              <SendInquiryForm productRoster={productRoster} />
            </Box>
            <Box
              sx={{
                width: "50%",
                bgcolor: "#fff",
                backgroundImage: `url(${formImage})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top",
                backgroundSize: "cover",
              }}
            ></Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Customize;
