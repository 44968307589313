import { Box, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { buildCategoryTree } from "../../../config/utils";
import { useNavigate } from "react-router";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CategoryDropDown from "./CategoryDropDown";
import Searchbar from "../../AnimatedLandingPage/Searchbar";

function ResponsiveMenuDropDown({ categories, toggleView }) {
  const navigate = useNavigate();
  const [currentMenu, setCurretMenu] = useState("");

  const handleToogleView = () => {
    toggleView();
  };

  const handleOpenResponsiveMenu = (value) => {
    setCurretMenu("");
  };

  const menuItemStyle = {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    p: "16px",
    ":hover": {
      bgcolor: "black",
    },
  };

  const menuItemTextStyle = {
    fontSize: "18px",
    fontWeight: "600",
  };

  return (
    <Box
      // onMouseLeave={handleToogleView}
      sx={{
        bgcolor: "#212121",
        color: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ color: "black", py: 2 }}>
        <Searchbar handleToogleView={handleToogleView}  />
      </Box>

      {/* Mens Category */}
      <Box
        onClick={() => {
          setCurretMenu((prev) => {
            if (prev === "6d1735c4-61f0-4e05-bda4-f7dfc1ffdb7d") {
              return "";
            } else return "6d1735c4-61f0-4e05-bda4-f7dfc1ffdb7d";
          });
        }}
      >
        <Box
          sx={{
            ...menuItemStyle,
            bgcolor:
              currentMenu === "6d1735c4-61f0-4e05-bda4-f7dfc1ffdb7d"
                ? "black"
                : "",
          }}
        >
          <Typography sx={menuItemTextStyle}>Men</Typography>
          <KeyboardArrowDownIcon />
        </Box>
        <Box
          sx={{
            color: "black",
            px: "16px",
            bgcolor: "white",
            display:
              currentMenu === "6d1735c4-61f0-4e05-bda4-f7dfc1ffdb7d"
                ? "block"
                : "none",
          }}
        >
          <CategoryDropDown
            categories={categories}
            parentCategroyId="6d1735c4-61f0-4e05-bda4-f7dfc1ffdb7d"
            handleOpen={() => null}
            flexDirection="column"
            justifyContent="flex-start"
            toggleResponsiveView={handleToogleView}
          />
        </Box>
      </Box>

      {/* Women Category */}
      <Box
        onClick={() => {
          setCurretMenu((prev) => {
            if (prev === "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ed") {
              return "";
            } else return "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ed";
          });
        }}
      >
        <Box
          sx={{
            ...menuItemStyle,
            bgcolor:
              currentMenu === "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ed"
                ? "black"
                : "",
          }}
        >
          <Typography sx={menuItemTextStyle}>Women</Typography>
          <KeyboardArrowDownIcon />
        </Box>
        <Box
          sx={{
            color: "black",
            m: "0 0 0 -16px",
            px: "16px",
            bgcolor: "white",
            display:
              currentMenu === "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ed"
                ? "block"
                : "none",
          }}
        >
          <CategoryDropDown
            categories={categories}
            parentCategroyId="5272b0d9-8871-4f1a-b8bf-f31d2e12e8ed"
            handleOpen={() => null}
            flexDirection="column"
            justifyContent="flex-start"
            toggleResponsiveView={handleToogleView}
          />
        </Box>
      </Box>

      {/* Customizer Category */}
      {/* <Box sx={menuItemStyle}>
        <Typography sx={menuItemTextStyle}>Customizer</Typography>
      </Box> */}

      {/* Catalog Category */}
      {/* <Box sx={menuItemStyle}>
        <Typography sx={menuItemTextStyle}>Catalog</Typography>
      </Box> */}

      {/* Contact Category */}
      <Box
        sx={menuItemStyle}
        onClick={() => {
          navigate("/about");
          handleToogleView();
        }}
      >
        <Typography sx={menuItemTextStyle}>About</Typography>
      </Box>
      <Box
        sx={menuItemStyle}
        onClick={() => {
          navigate("/contact");
          handleToogleView();
        }}
      >
        <Typography sx={menuItemTextStyle}>Contact</Typography>
      </Box>
      <Box
        sx={menuItemStyle}
        onClick={() => {
          navigate("/how-to-order");
          handleToogleView();
        }}
      >
        <Typography sx={menuItemTextStyle}>How to Order</Typography>
      </Box>
    </Box>
  );
}

export default ResponsiveMenuDropDown;
