import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import "../../assets/styles/image.css";

const ParallaxSection = ({
  height,
  imageSrc,
  width,
  rounded,
  noScaleUp,
  categoryName,
  videoSrc,
}) => {
  const containerRef = useRef(null);
  let { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"],
  });

  // Adjust the y value for the video
  let translateY = useTransform(scrollYProgress, [0, 1], ["10%", "-10%"]);

  return (
    <div
      ref={containerRef}
      className={`${rounded ? "rounded-3xl" : ""} overflow-hidden relative`}
      style={{ height: height, width: width }}
    >
      {videoSrc ? (
        <motion.video
          src={videoSrc}
          autoPlay
          loop
          muted
          style={{
            translateY: translateY,
            height: "100%",
            borderRadius: "24px",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center",
            scale: noScaleUp ? 1 : 1.3,
          }}
        />
      ) : (
        <motion.img
          src={imageSrc}
          alt="banner image"
          style={{
            translateY: translateY,
            height: "100%",
            borderRadius: "24px",
            width: "100%",
            objectFit: "cover",
            objectPosition: "top",
            scale: noScaleUp ? 1 : 1.3,
          }}
        />
      )}
      {categoryName && (
        <div
          className="cat-text absolute top-[20%] left-[6%] text-[9dvw] md:text-[3.8vw] font-bold flex flex-col italic"
          style={{ fontFamily: "Titillium Web" }}
        >
          {categoryName.split(" ").map((word, index) => (
            <span
              key={index}
              className={`${
                index === 0 ? `text-[#f26728] ` : `text-[#040707]`
              }`}
            >
              {word}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default ParallaxSection;
