import React from "react";
import {
  Dialog,
} from "@mui/material";

const ImageDialog = ({ imageUrl, open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
        },
      }}
      sx={{
        backgroundColor: "transparent",
      }}
    >
      <img src={imageUrl} alt="Image" style={{ width: "30vw" }} />
    </Dialog>
  );
};

export default ImageDialog;
