import {
  MenuItem,
  TextField,
  Typography,
  Grid,
  IconButton,
  Select,
} from "@mui/material";
import React, { Fragment } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { bottomSizeOptions, topSizeOptions } from "../../config/constants";
import { useDispatch } from "react-redux";
import {
  deleteRosterItem,
  updateRosterItem,
} from "../../slices/virtualCartSlice";
import { deleteItemInRoster, updateItemInRoster } from "../../slices/canvasSlice";

function RosterItem({ item, unitPrice, productId }) {
  const dispatch = useDispatch();

  const handleChange = (prop) => (event) => {
    // let value = event.target.value;
    // if (prop === "number" ) {
    //   value = event.target.value.replace(/[^0-9]/g, "");
    // }
    // if (prop === "qty") {
    //     value = event.target.value.replace(/[^0-9]/g, "");
    //     if (!isNaN(parseInt(value)) && parseInt(value) !==0) {
    //         value = parseInt(value, 10).toString();
    //       } else {
    //         value = "";
    //       }
    //   }
      
    // dispatch(
    //   updateRosterItem({
    //     productId,
    //     updatedRosterItem: { ...item, [prop]: value },
    //   })
    // );
  };

  // console.log(item);

  return (
    // <Grid item >
      <Grid container spacing={2} alignItems="center" sx={{mt:"2px"}}>

        <Grid item xs={12} md={1}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={3}
              md={0}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}
              >
                Number
              </Typography>
            </Grid>
            <Grid item xs={9} md={12}>
              <TextField
                value={item?.number ? item?.number : ""}
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => dispatch(updateItemInRoster({newRosterItem:{...item, number: e.target.value}, Id: item?.id}))}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={3}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={3}
              md={0}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}
              >
                Player Name
              </Typography>
            </Grid>
            <Grid item xs={9} md={12}>
              <TextField
                value={item?.name ? item?.name : ""}
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => dispatch(updateItemInRoster({newRosterItem:{...item, name: e.target.value}, Id: item?.id}))}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={2.5}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={3}
              md={0}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}
              >
                Top Size
              </Typography>
            </Grid>
            <Grid item xs={9} md={12}>
              <TextField
                select
                value={item?.topSize ? item?.topSize : topSizeOptions[0]}
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => dispatch(updateItemInRoster({newRosterItem:{...item, topSize: e.target.value}, Id: item?.id}))}
              >
                {topSizeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={2.5}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={3}
              md={0}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}
              >
                Bottom Size
              </Typography>
            </Grid>
            <Grid item xs={9} md={12}>
              <TextField
                select
                value={item?.bottomSize ? item?.bottomSize : bottomSizeOptions[0]}
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => dispatch(updateItemInRoster({newRosterItem:{...item, bottomSize: e.target.value}, Id: item?.id}))}
              >
                {bottomSizeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={2}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={3}
              md={0}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}
              >
                Quantity
              </Typography>
            </Grid>
            <Grid item xs={9} md={12}>
              <TextField
                value={item?.qty ? item?.qty : ""}
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => dispatch(updateItemInRoster({newRosterItem:{...item, qty: e.target.value}, Id: item?.id}))}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={12} md={1}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={3}
              md={0}
              sx={{ display: { xs: "block", md: "none" } }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}
              >
                Quantity
              </Typography>
            </Grid>
            <Grid item xs={9} md={12}>
              <TextField
                value={unitPrice}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid> */}

        <Grid item xs={12} md={1}>
          <IconButton
            onClick={() =>
              dispatch(deleteItemInRoster({ rosterItemId: item?.id }))
            }
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Grid>

      </Grid>
    // </Grid>
  );
}

export default RosterItem;
