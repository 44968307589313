import React from "react";
import ContactForm from "../../../components/LandingPage/Contact/ContactForm";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Contact | US Sports</title>
      </Helmet>
      {/* <ContactSection1 /> */}
      <ContactForm />
    </>
  );
};

export default Contact;
