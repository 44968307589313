import React from "react";

const SizeChartTab = () => {
  return (
    <div className="w-full py-6 px-10">
      <table class="min-w-full divide-y divide-gray-100">
        <thead class="bg-gray-50 ">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-sm font-semibold text-black uppercase tracking-wider"
            >
              SIZE
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-sm font-semibold text-black uppercase tracking-wider"
            >
              CHEST(IN.)
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-sm font-semibold text-black uppercase tracking-wider"
            >
              WAIST(IN.)
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-sm font-semibold text-black uppercase tracking-wider"
            >
              HIPS(IN.)
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-100">
          <tr class="bg-white">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              XS
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              34-36
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              27-29
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              34.5-36.5
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              S
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              36-38
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              29-31
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              36.5-38.5
            </td>
          </tr>
          <tr class="bg-white">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              M
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              38-40
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              31-33
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              38.5-40.5
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              L
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              40-42
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              33-36
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              40.5-43.5
            </td>
          </tr>
          <tr class="bg-white">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              XL
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              42-45
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              36-40
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              43.5-47.5
            </td>
          </tr>
          <tr class="bg-gray-100">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              XXL
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              45-48
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              40-44
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              47.5-51.5
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SizeChartTab;
