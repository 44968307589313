import { motion } from "framer-motion";
import React from "react";
import ParallaxSection from "./ParallaxSection";
import bottomBanner from "../../assets/images/bottom banner.jpg";

function About() {
  return (
    <div className="relative w-full p-[30px] md:px-[3vw] md:py-[3vw] leading-none bg-white h-fit flex items-center justify-center flex-col rounded-t-[2vw] -mt-[30px] z-[100]">
      <div className="w-full h-fit my-[30px] border-b-2">
        <motion.h2
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ ease: "easeIn", duration: 0.6, delay: 0.1 }}
          className="text-[8dvw] md:text-[3vw] mb-[60px] font-normal"
        >
          US Sports
        </motion.h2>
      </div>
      <div className="w-full flex justify-between items-stretch h-fit flex-col md:flex-row mb-7">
        <div className="w-full md:w-1/2 h-fit">
          <p className="text-[20px] md:text-[1.2vw] mb-5 underline md:mb-0 leading-[4dvw] md:leading-[1.6vw]">
            About Us
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ ease: "easeIn", duration: 0.6, delay: 0.2 }}
            className="text-[20px] md:text-[1.2vw] leading-[6.5dvw] md:leading-[1.6vw]"
          >
            US Sports is your go-to partner for sports custom Uniform
            manufacturing needs. Specializing in crafting top-quality athletic
            apparel, we help fast-growing sports businesses make a lasting
            impression on and off the field. Whether it's raising funds,
            showcasing products, or building a winning team, count on
            US Sports to deliver excellence every step of the way.
          </motion.p>
        </div>
      </div>
      <div className="w-full">
        <ParallaxSection
          rounded={true}
          height={window.innerWidth < 700 ? "30dvh" : "95vh"}
          // width={"100%"}
          imageSrc={bottomBanner}
        />
      </div>
    </div>
  );
}

export default About;
