import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL_LOCAL;
export const getAllProducts = createAsyncThunk(
  "productSlice/getAllProducts",
  async () => {
    try {
      const response = await axios.get(`${baseUrl}/products`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProductById = createAsyncThunk(
  "productSlice/getProductById",
  async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/products/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const createNewProduct = createAsyncThunk(
  "productSlice/createNewProduct",
  async (data) => {
    try {
      const response = await axios.post(`${baseUrl}/products`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateProduct = createAsyncThunk(
  "productSlice/updateProduct",
  async (product) => {
    try {
      const response = await axios.put(
        `${baseUrl}/products/${product.id}`,
        product.data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "productSlice/deleteProduct",
  async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/products/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addPrimaryImage = createAsyncThunk(
  "productSlice/addPrimaryImage",
  async (data) => {
    try {
      const response = await axios.post(
        `${baseUrl}/products/${data.id}/primary-image`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updatePrimaryImage = createAsyncThunk(
  "productSlice/updatePrimaryImage",
  async (data) => {
    try {
      const response = await axios.put(
        `${baseUrl}/products/${data.id}/primary-image`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addSecondaryImage = createAsyncThunk(
  "productSlice/addSecondaryImage",
  async (data) => {
    try {
      const response = await axios.post(
        `${baseUrl}/products/${data.id}/secondary-image`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const updateSecondaryImage = createAsyncThunk(
  "productSlice/updateSecondaryImage",
  async (data) => {
    try {
      const response = await axios.put(
        `${baseUrl}/products/${data.id}/secondary-image`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteSecondaryImage = createAsyncThunk(
  "productSlice/deleteSecondaryImage",
  async (data) => {
    try {
      const { id, formData } = data; // Assuming data is an object with id and formData

      // console.log(data); // Log the entire data object for debugging

      const response = await axios.delete(
        `${baseUrl}/products/${id}/secondary-image`,
        { data: formData } // Send formData in the request body
      );

      // console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const addProductTag = createAsyncThunk(
  "productSlice/addProductTag",
  async (data) => {
    try {
      const response = await axios.post(`${baseUrl}/products/tag`, data, {
        headers: { "Content-Type": "application/json" },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteProductTag = createAsyncThunk(
  "productSlice/deleteProductTag",
  async (data) => {
    try {
      const response = await axios.delete(`${baseUrl}/products/tag`, {
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify(data),
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteProductReview = createAsyncThunk(
  "productSlice/deleteProductReview",
  async ({ reviewId, productId }) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/products/${productId}/reviews`,
        {
          headers: { "Content-Type": "application/json" },
          data: { id: reviewId },
        }
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const productSlice = createSlice({
  name: "productSlice",
  initialState: {
    allProducts: { isLoading: false, data: [] },
    isNewProductCreating: false,
    isProductDeleting: false,
    singleProduct: { isLoading: false, data: null },
    updatingProduct: false,
  },
  reducers: {
    resetSingleProduct: (state, action) => {
      state.singleProduct.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state, action) => {
        state.allProducts.isLoading = true;
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.allProducts.isLoading = false;
        console.error(action.error.message);
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.allProducts.isLoading = false;
        state.allProducts.data = action.payload;
      })
      .addCase(createNewProduct.pending, (state, action) => {
        state.isNewProductCreating = true;
      })
      .addCase(createNewProduct.rejected, (state, action) => {
        state.isNewProductCreating = false;
        console.error(action.error.message);
      })
      .addCase(createNewProduct.fulfilled, (state, action) => {
        state.isNewProductCreating = false;
        const createdProduct = action.payload;
        state.allProducts.data.push(createdProduct);
      })
      .addCase(deleteProduct.pending, (state, action) => {
        state.isProductDeleting = true;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isProductDeleting = false;
        console.error(action.error.message);
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isProductDeleting = false;
        const deletedProduct = action.payload;
        state.allProducts.data = state.allProducts.data.filter((product) => {
          return product.id !== deletedProduct.id;
        });
      })
      .addCase(getProductById.pending, (state, action) => {
        state.singleProduct.isLoading = true;
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.singleProduct.isLoading = false;
        console.error(action.error.message);
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.singleProduct.isLoading = false;
        const product = action.payload;
        state.singleProduct.data = product;
        // console.log(state.singleProduct.data);
      })
      .addCase(updateProduct.pending, (state, action) => {
        state.updatingProduct = true;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.updatingProduct = false;
        console.error(action.error.message);
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.updatingProduct = false;
        const updatedProduct = action.payload;
        state.singleProduct.data = {
          ...state.singleProduct.data,
          ...updatedProduct,
        };
      })
      .addCase(addSecondaryImage.pending, (state, action) => {
        state.singleProduct.isLoading = true;
      })
      .addCase(addSecondaryImage.rejected, (state, action) => {
        state.singleProduct.isLoading = false;
        console.error(action.error.message);
      })
      .addCase(addSecondaryImage.fulfilled, (state, action) => {
        state.singleProduct.isLoading = false;
        const secondaryImage = action.payload;
        if (Array.isArray(state.singleProduct.data.product_images)) {
          state.singleProduct.data.product_images =
            state.singleProduct.data.product_images.concat(secondaryImage);
        } else {
          state.singleProduct.data.product_images = [secondaryImage];
        }
      })
      .addCase(addPrimaryImage.pending, (state, action) => {
        state.singleProduct.isLoading = true;
      })
      .addCase(addPrimaryImage.rejected, (state, action) => {
        state.singleProduct.isLoading = false;
        console.log(action.error.message);
      })
      .addCase(addPrimaryImage.fulfilled, (state, action) => {
        state.singleProduct.isLoading = false;
        const primaryImage = action.payload;
        // console.log(primaryImage);
        if (Array.isArray(state.singleProduct.data.product_images)) {
          state.singleProduct.data.product_images =
            state.singleProduct.data.product_images.concat(primaryImage);
        } else {
          state.singleProduct.data.product_images = [primaryImage];
        }
      })
      .addCase(updateSecondaryImage.pending, (state, action) => {
        state.singleProduct.isLoading = true;
      })
      .addCase(updateSecondaryImage.rejected, (state, action) => {
        state.singleProduct.isLoading = false;
        console.error(action.error.message);
      })
      .addCase(updateSecondaryImage.fulfilled, (state, action) => {
        state.singleProduct.isLoading = false;
        const updatedSecondaryImage = action.payload;
        state.singleProduct.data.product_images =
          state.singleProduct.data.product_images.map((image) => {
            if (image.id === updatedSecondaryImage.id) {
              return updatedSecondaryImage;
            }
            return image;
          });
      })
      .addCase(updatePrimaryImage.pending, (state, action) => {
        state.singleProduct.isLoading = true;
      })
      .addCase(updatePrimaryImage.rejected, (state, action) => {
        state.singleProduct.isLoading = false;
        console.error(action.error.message);
      })
      .addCase(updatePrimaryImage.fulfilled, (state, action) => {
        state.singleProduct.isLoading = false;
        const updatedPrimaryImage = action.payload;
        state.singleProduct.data.product_images =
          state.singleProduct.data.product_images.map((image) => {
            if (image.id === updatedPrimaryImage.id) {
              return updatedPrimaryImage;
            }
            return image;
          });
      })
      .addCase(deleteSecondaryImage.pending, (state, action) => {
        state.singleProduct.isLoading = true;
      })
      .addCase(deleteSecondaryImage.rejected, (state, action) => {
        state.singleProduct.isLoading = false;
        console.error(action.error.message);
      })
      .addCase(deleteSecondaryImage.fulfilled, (state, action) => {
        state.singleProduct.isLoading = false;
        const deletedSecondaryImageId = action.payload.id;
        state.singleProduct.data.product_images =
          state.singleProduct.data.product_images.filter((image) => {
            return image.id !== deletedSecondaryImageId;
          });
      })
      .addCase(addProductTag.rejected, (state, action) => {
        // state.singleProduct.isLoading = false;
        console.error(action.error.message);
      })
      .addCase(addProductTag.fulfilled, (state, action) => {
        const tagsArray = state.singleProduct.data.tags;
        if (!tagsArray) {
          state.singleProduct.data.tags = [];
        }

        const addedTag = action.payload;

        // console.log(addedTag);

        state.singleProduct.data.tags =
          state.singleProduct.data.tags.concat(addedTag);
      })
      .addCase(deleteProductTag.rejected, (state, action) => {
        console.error(action.error.message);
      })
      .addCase(deleteProductTag.fulfilled, (state, action) => {
        const deletedTag = action.payload;

        // console.log(deletedTag);

        state.singleProduct.data.tags = state.singleProduct.data.tags.filter(
          (tag) => {
            return tag.id !== deletedTag.id;
          }
        );
      })
      .addCase(deleteProductReview.rejected, (state, action) => {
        console.error(action.error.message);
      })
      .addCase(deleteProductReview.fulfilled, (state, action) => {
        const deletedReview = action.payload;

        // console.log(deletedReview);

        state.singleProduct.data.reviews =
          state.singleProduct.data.reviews.filter((review) => {
            return review.id !== deletedReview.id;
          });
      });
  },
});

export const { resetSingleProduct } = productSlice.actions;

export const selectAllProducts = (state) => state.productSlice.allProducts;
export const selectSingleProduct = (state) => state.productSlice.singleProduct;

export const selectIsProductUpdating = (state) =>
  state.productSlice.updatingProduct;

export const selectIsNewProductCreating = (state) =>
  state.productSlice.isNewProductCreating;

export default productSlice.reducer;
