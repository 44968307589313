import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";

const Topbar = ({ title, actions, currentCategory, searchFunction }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        my: "5px",
        // mb: 1,
      }}
    >
      {title && <Typography sx={{ fontWeight: 600 }}>All {title}</Typography>}
      <TextField
        size="small"
        sx={{ width: "40%" }}
        placeholder="Search for..."
        InputProps={{
          startAdornment: <SearchIcon className="mr-2" />,
        }}
        onChange={(e) => searchFunction(e.target.value)}
      />
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {actions?.map((action) => {
            return (
              <MenuItem
                onClick={() => {
                  handleClose();
                  if (action?.handleClick) {
                    action.handleClick();
                  }
                }}
                sx={{ color: action?.color }}
              >
                <action.icon sx={{ mr: 1, color: action?.color }} />

                {action.title}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </Box>
  );
};

export default Topbar;
