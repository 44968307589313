import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import axios from "axios";

const LineReviewChart = () => {
  const [reviewsArray, setReviewsArray] = useState([]);

  const getData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_LOCAL}/products/all-reviews`
      );
      setReviewsArray(
        response.data.map((review) => ({
          x: review.date, // Assuming date is used for x-axis
          y: review.rating,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ResponsiveBar
      data={reviewsArray}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      indexBy="x" // Use "x" for data point identification
      keys={["y"]} // Specify data key for bar heights
      // layout="horizontal" // Optional: Set horizontal bars
      xScale={{ type: "time", format: "%b %d" }} // Optional: Format x-axis labels (month, day)
      yScale={{ type: "linear" }}
      // axisLeft={{reviewsArray[0].data.map((d) => d.y)}} // Hide left axis (optional)
      axisBottom={null}
      legends={[]} // Hide legends (optional)
      colors={["#d1e2eb"]}
    />
  );
};

export default LineReviewChart;
