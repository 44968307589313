import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFrontTextBackend,
  addFrontTextObject,
  changeBackText,
  changeFontStyles,
  changeFrontText,
  changeFrontTextPosition,
  changeFrontTitle,
  changeTextFill,
  changeTextFontSize,
  changeTextObjIsSaved,
  changeTextStroke,
  changeTextStrokeWidth,
  deleteFrontTextBackend,
  deleteFrontTextObject,
  selectFrontTextArray,
  updateFrontTextBackend,
} from "../../slices/adminCanvasSlice";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  clothingColors,
  fontStyles,
  twitterPickerStyle,
} from "../../config/constants";
import { TwitterPicker } from "react-color";
import { useParams } from "react-router";
import ControlMenuButton from "./ControlMenuButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import toast from "react-hot-toast";

const FrontTextNumbersEditor = () => {
  const { c_id } = useParams();
  const [apiLoading, setApiLoading] = useState(false);

  const handleDeleteText = (textObj) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          setApiLoading(true);
          await dispatch(deleteFrontTextBackend(textObj?.id));
          dispatch(deleteFrontTextObject({ objectID: textObj?.id }));
          resolve("Deleted!");
        } catch (error) {
          console.error(error);
          reject(error);
        } finally {
          setApiLoading(false);
        }
      }),
      {
        loading: "Deleting...",
        success: "Deleted!",
        error: "Error",
      }
    );
  };

  const handleUpdateText = (textObj) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const updated = await dispatch(
            updateFrontTextBackend({
              ...textObj,
            })
          );
          if (updated) {
            dispatch(
              changeTextObjIsSaved({
                targetID: textObj.id,
                value: true,
                applyTo: "front",
              })
            );
          }
          resolve("Saved!");
        } catch (error) {
          console.error(error);
          reject(error);
        }
      }),
      {
        loading: "Saving...",
        success: "Saved!",
        error: "Error",
      }
    );
  };

  const textActions = [
    {
      title: "Delete",
      handleClick: handleDeleteText,
      icon: DeleteIcon,
      color: "#be0000",
    },
    {
      title: "Save",
      handleClick: handleUpdateText,
      icon: SaveIcon,
      color: "#008000",
    },
  ];

  const dispatch = useDispatch();
  const frontTextArr = useSelector(selectFrontTextArray);

  return ( 
    <div>
      <Grid container>
        {frontTextArr?.map((textObj, index) => {
          return (
            <Grid item xs={12} md={6} lg={4} xl={3} sx={{}}>
              <Box sx={{ m: 1, p: 1 }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "fit-content",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px", fontWeight: 500, color: "#262626" }}
                  >
                    Edit Layer {index + 1}
                  </Typography>
                  <ControlMenuButton actions={textActions} textOrPathObj={textObj} />
                </Box>
                <TextField
                  size="small"
                  placeholder="Enter Title For Your Text Object"
                  label={"Title"}
                  value={textObj.title}
                  onChange={async (e) => {
                    dispatch(
                      changeFrontTitle({
                        targetID: textObj?.id,
                        newTitle: e.target.value,
                      })
                    );
                  }}
                  fullWidth
                  variant="outlined"
                  sx={{
                    my: 1,
                    width: "100%",
                    minWidth: "100%",
                    color: "#7d888d",
                    "& label": {
                      color: "#697176",
                      fontSize: "18px",
                    },
                    "& label.Mui-focused": {
                      color: "#697176",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#7d888d",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&:hover fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#7d888d",
                      },
                    },
                    input: {
                      color: "#262626",
                    },
                  }}
                />
                <TextField
                  size="small"
                  placeholder="Enter new Text"
                  label={"Text"}
                  value={textObj.text}
                  onChange={(e) => {
                    dispatch(
                      changeFrontText({
                        targetTitle: textObj.title,
                        newText: e.target.value,
                      })
                    );
                    dispatch(
                      changeBackText({
                        targetTitle: textObj.title,
                        newText: e.target.value,
                      })
                    );
                  }}
                  fullWidth
                  variant="outlined"
                  sx={{
                    my: 1,
                    width: "100%",
                    minWidth: "100%",
                    color: "#7d888d",
                    "& label": {
                      color: "#697176",
                      fontSize: "18px",
                    },
                    "& label.Mui-focused": {
                      color: "#697176",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#7d888d",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&:hover fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#7d888d",
                      },
                    },
                    input: {
                      color: "#262626",
                    },
                  }}
                />
                <TextField
                  size="small"
                  placeholder="Position X"
                  label={"Position X"}
                  value={textObj.x}
                  type="number"
                  onChange={(e) =>
                    dispatch(
                      changeFrontTextPosition({
                        targetID: textObj.id,
                        newX: parseFloat(e.target.value) || 0,
                        newY: textObj.y,
                      })
                    )
                  }
                  fullWidth
                  variant="outlined"
                  sx={{
                    my: 1,
                    width: "100%",
                    minWidth: "100%",
                    color: "#7d888d",
                    "& label": {
                      color: "#697176",
                      fontSize: "18px",
                    },
                    "& label.Mui-focused": {
                      color: "#697176",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#7d888d",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&:hover fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#7d888d",
                      },
                    },
                    input: {
                      color: "#262626",
                    },
                  }}
                />
                <TextField
                  size="small"
                  placeholder="Position Y"
                  label={"Position Y"}
                  value={textObj.y}
                  type="number"
                  onChange={(e) =>
                    dispatch(
                      changeFrontTextPosition({
                        targetID: textObj.id,
                        newX: textObj.x,
                        newY: parseFloat(e.target.value) || 0,
                      })
                    )
                  }
                  fullWidth
                  variant="outlined"
                  sx={{
                    my: 1,
                    width: "100%",
                    minWidth: "100%",
                    color: "#7d888d",
                    "& label": {
                      color: "#697176",
                      fontSize: "18px",
                    },
                    "& label.Mui-focused": {
                      color: "#697176",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#7d888d",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&:hover fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#7d888d",
                      },
                    },
                    input: {
                      color: "#262626",
                    },
                  }}
                />
                <TextField
                  size="small"
                  placeholder="Stroke Width"
                  label={"Stroke Width"}
                  value={textObj.strokeWidth}
                  type="number"
                  inputProps={{
                    step: "0.1",
                  }}
                  onChange={(e) =>
                    dispatch(
                      changeTextStrokeWidth({
                        targetElements: textObj.title,
                        newStrokeWidth: e.target.value,
                      })
                    )
                  }
                  fullWidth
                  variant="outlined"
                  sx={{
                    my: 1,
                    width: "100%",
                    minWidth: "100%",
                    color: "#7d888d",
                    "& label": {
                      color: "#697176",
                      fontSize: "18px",
                    },
                    "& label.Mui-focused": {
                      color: "#697176",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#7d888d",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&:hover fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#7d888d",
                      },
                    },
                    input: {
                      color: "#262626",
                    },
                  }}
                />
                <TextField
                  size="small"
                  placeholder="Font Size"
                  label={"Font Size"}
                  value={textObj.fontSize}
                  type="number"
                  onChange={(e) =>
                    dispatch(
                      changeTextFontSize({
                        targetElements: textObj.title,
                        newFontSize: e.target.value,
                      })
                    )
                  }
                  fullWidth
                  variant="outlined"
                  sx={{
                    my: 1,
                    width: "100%",
                    minWidth: "100%",
                    color: "#7d888d",
                    "& label": {
                      color: "#697176",
                      fontSize: "18px",
                    },
                    "& label.Mui-focused": {
                      color: "#697176",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#7d888d",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&:hover fieldset": {
                        borderColor: "#7d888d",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#7d888d",
                      },
                    },
                    input: {
                      color: "#262626",
                    },
                  }}
                />
                <FormControl fullWidth>
                  <InputLabel
                    id="font-family-select-label"
                    sx={{
                      color: "#697176",
                      "&.Mui-focused": {
                        color: "#35393b",
                      },
                    }}
                  >
                    Font Style
                  </InputLabel>
                  <Select
                    labelId="font-family-select-label"
                    id="font-family-select"
                    value={textObj.fontFamily}
                    label="Font Style"
                    onChange={(e) =>
                      dispatch(
                        changeFontStyles({
                          targetElements: textObj.title,
                          newFont: e.target.value,
                        })
                      )
                    }
                    sx={{
                      width: "100%",
                      minWidth: "100%",
                      my: "3px",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7d888d",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7d888d",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7d888d",
                      },
                    }}
                  >
                    {fontStyles.map((fontStyle) => (
                      <MenuItem value={fontStyle}>
                        <Typography sx={{ fontFamily: fontStyle }}>
                          {fontStyle}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#262626",
                    my: "5px",
                  }}
                >
                  Change Fill Color
                </Typography>
                <TwitterPicker
                  styles={twitterPickerStyle}
                  color={textObj.fill}
                  width="100%"
                  triangle="hide"
                  colors={clothingColors}
                  onChange={(e) => {
                    dispatch(
                      changeTextFill({
                        targetElements: textObj.title,
                        newColor: e.hex,
                      })
                    );
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#262626",
                    my: "5px",
                  }}
                >
                  Change Stroke Color
                </Typography>
                <TwitterPicker
                  styles={twitterPickerStyle}
                  color={textObj.stroke}
                  width="100%"
                  triangle="hide"
                  colors={clothingColors}
                  onChange={(e) => {
                    dispatch(
                      changeTextStroke({
                        targetElements: textObj.title,
                        newColor: e.hex,
                      })
                    );
                  }}
                />
              </Box>
            </Grid>
          );
        })}
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <Box
            sx={{
              m: 1,
              p: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              fullWidth
              size="small"
              variant="contained"
              disableElevation
              disableRipple
              sx={{
                bgcolor: "#262626",
                color: "#fff",
                "&:hover": {
                  color: "#fff",
                  bgcolor: "#262626",
                },
              }}
              disabled={apiLoading ? true : false}
              onClick={() => {
                toast.promise(
                  new Promise(async (resolve, reject) => {
                    const randomTitle = Math.random()
                      .toString(36)
                      .substring(2, 7);
                    const randomId = Math.random().toString(36).substring(2, 7);
                    try {
                      setApiLoading(true);
                      await dispatch(
                        addFrontTextBackend({
                          y: 150,
                          x: 120,
                          title: randomTitle,
                          text: "New Text",
                          strokeWidth: 0.5,
                          fontSize: 30,
                          draggable: true,
                          align: "center",
                          width: "auto",
                          height: "auto",
                          fill: "#fff",
                          stroke: "#ff0",
                          fontFamily: "Protest Riot",
                          id: randomId,
                          c_id, 
                        })
                      );
                      dispatch(
                        addFrontTextObject({
                          newTextObject: {
                            y: 150,
                            x: 120,
                            title: randomTitle,
                            text: "New Text",
                            strokeWidth: 0.5,
                            fontSize: 30,
                            draggable: true,
                            align: "center",
                            width: "auto",
                            height: "auto",
                            fill: "#fff",
                            stroke: "#ff0",
                            fontFamily: "Protest Riot",
                            id: randomId,
                            c_id,
                          },
                        })
                      );
                      resolve("Added!");
                    } catch (error) {
                      console.error(error);
                      reject(error);
                    } finally {
                      setApiLoading(false);
                    }
                  }),
                  {
                    loading: "Adding...",
                    success: "Added!",
                    error: "Error",
                  }
                );
              }}
            >
              Add New Text
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default FrontTextNumbersEditor;
