import catImg01 from "../assets/images/category images/American Football.jpg";
import catImg02 from "../assets/images/category images/baseball.jpg";
import catImg03 from "../assets/images/category images/basketball.jpg";
import catImg04 from "../assets/images/category images/ice hockey.jpg";

export const footerData = [
  {
    heading: "Sports",
    items: [
      { id: "e3e268b3-7243-4c15-bc1c-9038b5183702", name: "Baseball" },
      { id: "4c3d6a27-7a5d-4fc7-af78-d0e049bfb1f3", name: "Basketball" },
      { id: "cc155c4f-3bc0-44e1-b537-4f7a5c191e82", name: "Football" },
      { id: "32949a30-94e8-4cc4-8e24-4566a5c08c2e", name: "Hockey" },
      { id: "665303f7-3ee2-4d9c-85f6-5bc0784f8029", name: "Tennis" },
      { id: "a80d4cb0-cf3e-4e0e-a0e5-7d39c9d2a36b", name: "Track And Field" },
      { id: "87b2e46a-7cd2-4f85-869b-9d0149f9e55f", name: "Lacroose" },
      { id: "c891c72d-22b8-4e7a-bc82-25b4f01d93b3", name: "Soccer" },
      { id: "cd6c8ed5-9fc3-4ee3-92e3-670f7bc8c2e2", name: "Volleyball" },
      { id: "6d22dadc-fd2c-43c8-bf71-8d18a4d52d39", name: "Wrestling" },
      { id: "7d68345d-4b10-4c32-bb4e-1a6834d40e3a", name: "eSports" },
    ],
  },
  {
    heading: "Apparel",
    items: [
      { id: "b6b394fb-9613-4f59-b4af-08d4d96cc47c", name: "Cage Jackets" },
      { id: "12a02a0a-334d-4881-af89-40b366fe7c32", name: "Cinch Sack" },
      { id: "739e3e0d-46ee-4a8a-b105-7e52b5cf02fb", name: "Compression" },
      { id: "fc641f6f-7f8c-4048-b0d0-cc7479ae9f41", name: "Pant" },
      {
        id: "0de6bb43-0f2b-4940-9961-4d09f22713f3",
        name: "Fan Replica Jersey",
      },
      { id: "5a34e00b-011f-4ef5-bb79-cb0f54029b39", name: "Game Day Jackets" },
    ],
  },
  {
    heading: "",
    items: [
      { id: "8a56c8ae-3a86-4208-b3b7-960b3e3082ac", name: "Hoodies" },
      { id: "db94fd19-fa26-4c6a-875e-bf86e73ff04d", name: "Polo Man" },
      {
        id: "ef01006c-f5c0-44f1-b6cf-989a798d6b87",
        name: "Quarter Zip Jackets",
      },
      { id: "d57be762-0d23-45b8-92a7-98f51a8ed61d", name: "SFN Jooger" },
      { id: "facd7a57-1b7a-490f-bb2d-9c5b18a20a07", name: "Socks" },
      {
        id: "42973e1d-5e19-420d-a071-7e392f45bcac",
        name: "Team Short With Pockets",
      },
      { id: "6868f8ff-5868-4e6b-88dc-3d9e18f4b739", name: "Tech Tee" },
    ],
  },
  {
    heading: "Useful Links",
    items: [
      { name: "Home", url: "/" },
      { name: "Contact", url: "/contact" },
      { name: "About", url: "/about" },
      {
        name: "How to Order",
        url: "/how-to-order",
      },
    ],
  },
];

export const signInFormData = {
  form: "signin",
  inputFields: [
    { label: "Enter Email Address", type: "text" },
    { label: "Enter Password", type: "password" },
  ],
  btnText: "Sign In",
};
export const signUpFormData = {
  form: "signup",
  inputFields: [
    { label: "Enter Name", type: "text" },
    { label: "Enter Email Address", type: "text" },
    { label: "Enter Password", type: "password" },
  ],
  btnText: "Sign Up",
};

export const orderSummary = [
  { title: "Price", price: "$83" },
  { title: "Shipping", price: "$32" },
  { title: "Tax", price: "$23" },
];

export const country_list = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua &amp; Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia &amp; Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre &amp; Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts &amp; Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad &amp; Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks &amp; Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const fontStyles = [
  "Protest Guerrilla",
  "Protest Riot",
  "Protest Revolution",
  "Sixtyfour",
  "Bungee",
  "Russo One",
];

export const clothingColors = [
  // Dull Shades
  "#8c8771", // Dull Grayish Brown
  "#5f7d8d", // Dull Slate Blue
  "#7a6d83", // Dull Mauve
  "#6b8471", // Dull Sage Green
  "#8e7a5b", // Dull Mustard
  "#7d6b5f", // Dull Dusty Rose
  "#5f758d", // Dull Steel Blue
  "#8c7a8e", // Dull Lavender Gray
  "#6b7d83", // Dull Teal
  "#8e7a77", // Dull Brick Red

  // Shiny Shades
  "#ffcc00", // Shiny Yellow
  "#ff6699", // Shiny Pink
  "#3399ff", // Shiny Blue
  "#ff9966", // Shiny Orange
  "#99cc33", // Shiny Green
  "#ff66cc", // Shiny Magenta
  "#66ccff", // Shiny Light Blue
  "#ff9933", // Shiny Amber
  "#cc66ff", // Shiny Purple
  "#66ff99", // Shiny Mint Green
];

// You can use these colors for styling your clothing products, with a mix of dull and shiny shades for variety.
export const twitterPickerStyle = {
  default: {
    card: {
      boxShadow: "none", // Remove default box shadow
      border: "none", // Remove default border
      borderRadius: "6px", // Apply border-radius to color boxes
    },
    colors: {
      width: "100%", // Set the width of the color picker to 100%
      // Adjust spacing between color boxes (optional)
    },
    swatch: {
      width: "20px", // Adjust the width of individual color boxes
      height: "20px", // Adjust the height of individual color boxes
      borderRadius: "3px", // Apply border-radius to individual color boxes
      boxShadow: "none", // Add a border to individual color boxes (optional)
    },
    input: {
      height: "20px",
    },
    hash: {
      height: "20px",
    },
  },
};
export const twitterPickerStyleClient = {
  default: {
    card: {
      boxShadow: "none", // Remove default box shadow
      border: "none", // Remove default border
      borderRadius: "6px", // Apply border-radius to color boxes
      backgroundColor: "transparent",
    },
    colors: {
      width: "100%", // Set the width of the color picker to 100%
      // Adjust spacing between color boxes (optional)
    },
    swatch: {
      width: "30px", // Adjust the width of individual color boxes
      height: "30px", // Adjust the height of individual color boxes
      borderRadius: "3px", // Apply border-radius to individual color boxes
      // boxShadow: "0px 5px 8px -3px rgba(0,0,0,0.75)", // Add a border to individual color boxes (optional)
    },
    input: {
      height: "30px",
      display: "none",
    },
    hash: {
      height: "30px",
      display: "none",
    },
  },
};

export const dashboardLinkItems = [
  { text: "Overview", to: "/dashboard" },
  { text: "Custom Product", to: "/dashboard/custom-products" },
  { text: "Product", to: "/dashboard/products" },
  { text: "Categories", to: "/dashboard/categories" },
  { text: "Users", to: "/dashboard/users" },
];

export const textFieldStyle = {
  my: 1,
  width: "100%",
  minWidth: "100%",
  color: "#9D9D9D",
  "& label": {
    color: "#9D9D9D",
    opacity: 1,
    display: "block",
  },
  "& label.Mui-focused": {
    color: "#555555",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#9D9D9D",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#9D9D9D",
    },
    "&:hover fieldset": {
      borderColor: "#9D9D9D",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#9D9D9D",
    },
  },
  "&.MuiOutlinedInput-notchedOutline": {
    borderColor: "#9D9D9D",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#9D9D9D",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#9D9D9D",
  },
  input: {
    color: "#555555",
  },
};

export const buttonStyleMui = {
  bgcolor: "#434343",
  color: "#FFFFFF",
  "&:hover": {
    bgcolor: "#2f2f2f",
    color: "#FFFFFF",
  },
};
export const topSizeOptions = [
  "YXS",
  "YS",
  "YM",
  "YL",
  "YXL",
  "S",
  "M",
  "L",
  "XL",
  "2XL",
  "3XL",
];
export const bottomSizeOptions = [
  "YXS",
  "YS",
  "YM",
  "YL",
  "YXL",
  "S",
  "M",
  "L",
  "XL",
  "2XL",
  "3XL",
];

export const imageApiUrl = process.env.REACT_APP_API_URL_LOCAL;

export const featuredCategories = [
  {
    img: catImg01,
    // img: catImg01withText,
    name: "American Football Uniform",
    id: "95918240-b432-4e44-b877-705538c4d45d",
  },
  {
    img: catImg02,
    // img: catImg02withText,
    name: "Baseball Uniform",
    id: "e3e268b3-7243-4c15-bc1c-9038b5183702",
  },
  {
    img: catImg03,
    // img: catImg03withText,
    name: "Basketball Uniform",
    id: "2d09c6e1-b958-4470-8cff-3c1c63f28de4",
  },
  {
    img: catImg04,
    // img: catImg04withText,
    name: "Ice Hockey Uniform",
    id: "2fb7086b-7a21-4cf9-95d3-c52575dfec04",
  },
];
