import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import ProductDetailsTab from "./ProductDetailsTab";
import ReviewsTab from "./ReviewsTab";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProductPageTabIndex,
  setProductPageTabIndex,
} from "../../slices/tabIndexSlice";
import SizeChartTab from "./SizeChartTab";

const ProductTabs = () => {
  const dispatch = useDispatch();
  const tabIndex = useSelector(selectProductPageTabIndex);

  const { userSingleProduct: product } = useSelector(
    (state) => state.userCategoryState
  );

  const reviews = product?.reviews || [];

  const handleChange = (e, newValue) => {
    dispatch(setProductPageTabIndex(newValue));
    // console.log(newValue);
  };
  return (
    <Box sx={{ mx: { xs: 1, md: 8 }, my: 4 }}>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        centered
        TabIndicatorProps={{ style: { backgroundColor: "#040707" } }}
        sx={{
          "& .MuiTab-root": {
            // Target all tabs
            color: "#040707", // Inherit text color (adjust if needed)
            "&.Mui-selected": {
              // Target the active tab bar
              color: "#040707", // Inherit text color (adjust if needed)
            },
            "&:hover": {
              // Simulate ripple effect with hover
              color: "#040707", // Inherit text color (adjust if needed)

              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(4, 7, 7, 0.2)" // Adjust alpha for dark mode
                  : "rgba(4, 7, 7, 0.1)", // Adjust alpha for light mode
            },
          },
        }}
      >
        <Tab label="Product Details" />
        <Tab label="Size Chart" />
        <Tab
          label={reviews.length > 0 ? `Reviews (${reviews.length})` : `Reviews`}
        />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        {tabIndex === 0 && <ProductDetailsTab />}
        {tabIndex === 1 && <SizeChartTab />}
        {tabIndex === 2 && <ReviewsTab />}
      </Box>
    </Box>
  );
};

export default ProductTabs;
