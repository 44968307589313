import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Rating,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddReviewForm from "../Forms/AddReviewForm";
import { useSelector } from "react-redux";
import { buttonStyleMui } from "../../config/constants";

const ReviewsTab = () => {
  const {
    userSingleProduct: product,
    userSingleProductLoading: loading,
    userSingleProductError: error,
  } = useSelector((state) => state.userCategoryState);

  const [visibleReviews, setVisibleReviews] = useState(2); // Initially, show 2 reviews

  const handleShowMoreReviews = () => {
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 2); // Show 2 more reviews when clicked
  };

  if (loading) return <>loading...</>;
  if (error) return <>Something went wrong</>;

  return (
    <>
      {/* Reviews */}
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {product.reviews?.length > 0 ? (
          <>
            {product.reviews.slice(0, visibleReviews).map((review) => (
              <Box
                key={review.id}
                sx={{
                  width: "100%",
                  height: "fit-content",
                  p: "10px 15px",
                }}
              >
                <Card sx={{ bgcolor: "#fafcfd", p: "20px 10px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      alt={review.name}
                      src=""
                      sx={{ mr: 1, width: "40px", height: "40px" }}
                    />
                    <Box>
                      <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
                        {review.name}
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: 400 }}>
                        <Rating readOnly size="small" value={review.rating} />
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography sx={{ fontSize: "14px", p: "8px" }}>
                      {review.content}
                    </Typography>
                  </Box>
                </Card>
              </Box>
            ))}
            {visibleReviews < product.reviews.length && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleShowMoreReviews}
                sx={{
                  bgcolor: "#fff",
                  color: "#040707",
                  border: "1px solid #040707",
                  "&:hover": {
                    border: "#f26728 solid 1px",
                    color: "#f26728",
                    backgroundColor: "rgba(242, 103, 40, 0.1)",
                  },
                }}
                disableElevation
              >
                Show More
              </Button>
            )}
          </>
        ) : (
          <Typography>No reviews available</Typography>
        )}
      </Box>

      {/* Add Review Form */}
      <Box id="review-form" sx={{ mt: "4px", p: "20px 10px" }}>
        <Card sx={{ bgcolor: "#F9FAFB", p: "10px 18px" }}>
          <Typography
            variant="h5"
            sx={{ mt: "8px", p: "1px 16px", fontWeight: "500" }}
          >
            Add a review
          </Typography>
          <Box
            sx={{
              mt: "4px",
              p: "12px 16px",
              // bgcolor: "#eeeeee",
              borderRadius: "4px",
            }}
          >
            <AddReviewForm id={product?.id} />
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default ReviewsTab;
