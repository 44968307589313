import {
  Box,
  Button,
  Card,
  CardContent,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import sampleImage from "../../assets/images/no-image-icon-23494.png";
import { imageApiUrl } from "../../config/constants";

const ProductCard = ({ productData }) => {
  const productPageUrl = `/product/${productData.id}`;

  const navigate = useNavigate();
  const goTo = (url) => {
    navigate(url);
  };
  // console.log(productData,productData.id);

  return (
    <Card
      sx={{
        // maxWidth: { xs: "100%", sm: "200px" },
        width: "100%",
        borderRadius: "10px",
        m: "0 16px 16px 0",
        height: "fit-content",
      }}
      elevation={3}
    >
      <img
        src={
          productData.product_images
            ? `${imageApiUrl}/uploads/${
                productData?.product_images.find(
                  (img) => img.type === "primary"
                ).image_url
              }`
            : sampleImage
        }
        alt="product image"
        // sx={{
        //   objectFit: "contain",
        //   cursor: "pointer",
        //   // objectPosition: { xs: "top center", lg: "center top" },
        //   width: "100%",
        //   maxHeight: "100%",
        //   aspectRatio: "1/1",
        // }}
        className="object-contain cursor-pointer w-full h-full aspect-square"
        onClick={() => {
          goTo(productPageUrl);
        }}
      />
      <CardContent
        style={{ padding: "16px" }}
        sx={{
          // bgcolor: "#262626",
          width: "100%",
          height: "20%",
          minHeight: "fit-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "4px",
          mt: "6px",
        }}
      >
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            goTo(productPageUrl);
          }}
        >
          <Tooltip title={productData.name} placement="top">
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "12rem",
              }}
            >
              <Typography
                noWrap
                sx={{
                  color: "#262626",
                }}
                fontSize="16px"
                fontWeight={700}
              >
                {productData.name}
              </Typography>
            </div>
          </Tooltip>
          {/* <Tooltip title={productData.category_name}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "11rem",
              }}
            >
              <Typography
                noWrap
                fontWeight={400}
                pt={"2px"}
                sx={{
                  fontSize: { xs: "12px", md: "14px" },
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  p: 0,
                }}
              >
                {productData.category_name}
              </Typography>
            </div>
          </Tooltip> */}
          <div className="flex items-center justify-start gap-2">
            <Typography fontSize={"12px"}>
              {productData.reviews?.length
                ? (
                    productData.reviews.reduce(
                      (acc, review) => acc + review.rating,
                      0
                    ) / productData.reviews.length
                  ).toFixed(2)
                : "No Reviews"}
            </Typography>
            <Rating
              size="small"
              name="read-only"
              value={(productData.reviews?.length
                ? productData.reviews.reduce(
                    (acc, review) => acc + review.rating,
                    0
                  ) / productData.reviews.length
                : 0
              ).toFixed(2)}
              readOnly
            />
          </div>
        </Box>

        {productData.custom_product_id && (
          <Box sx={{ width: "100%", display: "flex" }}>
            <Button
              size="small"
              variant="outlined"
              disableElevation
              fullWidth
              sx={{
                // bgcolor: "#f26728",
                color: "#f26728",
                borderColor: "#f26728",
                "&:hover": {
                  bgcolor: "#f26728",
                  color: "#fff",
                  borderColor: "#f26728",
                },
              }}
              onClick={() =>
                navigate(`/customize/${productData.custom_product_id}`)
              }
            >
              Customize
            </Button>
          </Box>
        )}

        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "90%",
            mx: "auto",
            pt: 2,
            pb: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography pr={3} fontSize="18px" fontWeight={800} color="red">
              {productData.price}
            </Typography>
            <Typography pr={3} fontSize="18px" fontWeight={400} color="#9D9D9D">
              {productData.price}
            </Typography>
            <Typography pr={3} fontSize="14px" fontWeight={400} color="#FF2E00">
              {productData.price}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "90%",
            mx: "auto",
            // pb: 2,
          }}
        >
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <Typography fontSize="14px" fontWeight={400} color={"#555555"} pl={2}>
            (289)
          </Typography>
        </Box> */}
      </CardContent>
    </Card>
  );
};

export default ProductCard;
