import React from "react";
import LoginSignupForm from "../../components/Common/LoginSignupForm";
import { Helmet } from "react-helmet";


const SignUp = () => {
  return (
    <>
    <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Sign Up | US Sports</title>
      </Helmet>
      <LoginSignupForm />
    </>
  );
};

export default SignUp;
