import React from "react";
import AdminLoginForm from "../../components/Forms/AdminLoginFrom";
import { Helmet } from "react-helmet";

function AdminLogin() {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Admin Login | US Sports</title>
      </Helmet>
      <AdminLoginForm />
    </>
  );
}

export default AdminLogin;
