import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import {
  addTextOnCanvas,
  changeFontStyles,
  changeText,
  changeTextFill,
  changeTextFontSize,
  changeTextStroke,
  changeTextStrokeWidth,
  deleteTextFromCanvas,
  selectBackTextArray,
  selectColorsArray,
  selectFontStyles,
  selectFrontTextArray,
  setCustomizeTabIndex,
} from "../../slices/canvasSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  buttonStyleMui,
  twitterPickerStyleClient,
} from "../../config/constants";

const TextNumbersEditor = ({ isFrontView, setIsFrontView }) => {
  const frontTextArray = useSelector(selectFrontTextArray);
  const backTextArray = useSelector(selectBackTextArray);
  const dispatch = useDispatch();
  // State to keep track of the expanded accordion panel
  const [expandedPanelFront, setExpandedPanelFront] = useState(
    Array.isArray(frontTextArray) && frontTextArray.length > 0
      ? frontTextArray[0].title
      : ""
  );
  const [expandedPanelBack, setExpandedPanelBack] = useState(
    Array.isArray(backTextArray) && backTextArray.length > 0
      ? backTextArray[0].title
      : ""
  );

  // Function to handle accordion panel changes
  const handleChangeExpandedPanelFront = (panel) => (event, isExpanded) => {
    setExpandedPanelFront(isExpanded ? panel : null);
  };
  const handleChangeExpandedPanelBack = (panel) => (event, isExpanded) => {
    setExpandedPanelBack(isExpanded ? panel : null);
  };
  const fontStyles = useSelector(selectFontStyles);

  useEffect(() => {
    setExpandedPanelFront(
      Array.isArray(frontTextArray) && frontTextArray.length > 0
        ? frontTextArray[0].title
        : ""
    );
    setExpandedPanelBack(
      Array.isArray(backTextArray) && backTextArray.length > 0
        ? backTextArray[0].title
        : ""
    );
  }, []);

  const [frontBackTextEditorTabsIndex, setFrontBackTextEditorTabsIndex] =
    useState(0);

  const handlefrontBackTextEditorTabsIndexChange = (event, newValue) => {
    setFrontBackTextEditorTabsIndex(newValue);
    if (newValue === 0) {
      setIsFrontView(true);
    } else if (newValue === 1) {
      setIsFrontView(false);
    }
  };

  const selectedColorsPalette = useSelector(selectColorsArray);

  useEffect(() => {
    if (isFrontView) {
      setFrontBackTextEditorTabsIndex(0);
    } else {
      setFrontBackTextEditorTabsIndex(1);
    }
  }, [isFrontView]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Tabs
          value={frontBackTextEditorTabsIndex}
          onChange={handlefrontBackTextEditorTabsIndexChange}
          TabIndicatorProps={{ style: { backgroundColor: "#040707" } }}
          sx={{
            "& .MuiTab-root": {
              // Target all tabs
              color: "#040707", // Inherit text color (adjust if needed)
              "&.Mui-selected": {
                // Target the active tab bar
                color: "#040707", // Inherit text color (adjust if needed)
              },
              "&:hover": {
                // Simulate ripple effect with hover
                color: "#040707", // Inherit text color (adjust if needed)

                backgroundColor: (theme) =>
                  theme.palette.mode === "dark"
                    ? "rgba(4, 7, 7, 0.2)" // Adjust alpha for dark mode
                    : "rgba(4, 7, 7, 0.1)", // Adjust alpha for light mode
              },
            },
          }}
        >
          <Tab label="Front" />
          <Tab label="Back" />
        </Tabs>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          display: frontBackTextEditorTabsIndex === 0 ? "block" : "none",
        }}
      >
        {(!Array.isArray(frontTextArray) || frontTextArray?.length === 0) && (
          <Button
            variant="contained"
            disableRipple
            disableElevation
            onClick={() => {
              dispatch(addTextOnCanvas({ textLocation: "front" }));
            }}
            sx={{
              border: "#f26728 solid 1px",
              color: "#f26728",
              bgcolor: "#fff",
              "&:hover": {
                backgroundColor: "rgba(242, 103, 40, 0.1)",
              },
            }}
          >
            Add New Text
          </Button>
        )}

        {Array.isArray(frontTextArray) &&
          frontTextArray.length > 0 &&
          frontTextArray?.map((textObject, i) => {
            // console.log(textObject);
            return (
              <Accordion
                expanded={expandedPanelFront === textObject?.title}
                onChange={handleChangeExpandedPanelFront(textObject?.title)}
                sx={{
                  bgcolor: "#f6f9fb",
                  my: 1,
                  borderBottomLeftRadius: "0.5vw",
                  borderBottomRightRadius: "0.5vw",
                  ...(i === 0 && {
                    borderToprightRadius: "0 !important",
                    borderTopLeftRadius: "0 !important",
                  }),
                }}
                elevation={0}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: 500,
                        color: "#262626",
                      }}
                    >
                      Edit {textObject?.text}
                    </Typography>
                    <Box>
                      <Tooltip title="Add New Text">
                        <IconButton
                          disableRipple
                          sx={{ p: 0 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(
                              addTextOnCanvas({ textLocation: "front" })
                            );
                          }}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete this text">
                        <IconButton
                          disableRipple
                          sx={{ p: 0 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            const confirmDelete = window.confirm(
                              "Are you sure want to delete?"
                            );
                            if (confirmDelete) {
                              dispatch(
                                deleteTextFromCanvas({
                                  objectID: textObject?.id,
                                  textLocation: "front",
                                })
                              );
                            }
                          }}
                        >
                          <DeleteIcon sx={{ color: "#C40234" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    size="small"
                    placeholder="Edit Text"
                    label={"Edit Text"}
                    sx={{ mb: 2 }}
                    fullWidth
                    value={textObject?.text}
                    onChange={(e) =>
                      dispatch(
                        changeText({
                          targetElements: textObject?.title,
                          newText: e.target.value,
                        })
                      )
                    }
                  />
                  <FormLabel>Font Size</FormLabel>
                  <FormControl
                    sx={{
                      width: "100%",
                      mb: 2,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <TextField
                      size="small"
                      placeholder="Font Size"
                      value={textObject?.fontSize}
                      sx={{ width: "20%" }}
                      type="number"
                      onChange={(e) =>
                        dispatch(
                          changeTextFontSize({
                            targetElements: textObject?.title,
                            newFontSize: e.target.value,
                          })
                        )
                      }
                      fullWidth
                      variant="outlined"
                    />
                    <Slider
                      valueLabelDisplay="auto"
                      value={textObject?.fontSize}
                      size="medium"
                      sx={{
                        width: "80%",
                        color: "black",
                        "& .MuiSlider-thumb": {
                          "&:focus, &:hover, &.Mui-active": {
                            boxShadow: "0px 0px 0px 8px rgba(0, 0, 0, 0.1)",
                          },
                        },
                      }}
                      onChange={(e) =>
                        dispatch(
                          changeTextFontSize({
                            targetElements: textObject?.title,
                            newFontSize: e.target.value,
                          })
                        )
                      }
                    />
                  </FormControl>
                  <FormLabel>Stroke Width</FormLabel>
                  <FormControl
                    sx={{
                      width: "100%",
                      mb: 2,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <TextField
                      size="small"
                      placeholder="Stroke Width"
                      value={textObject?.strokeWidth}
                      type="number"
                      inputProps={{
                        step: "0.1",
                      }}
                      sx={{ width: "20%" }}
                      onChange={(e) =>
                        dispatch(
                          changeTextStrokeWidth({
                            targetElements: textObject?.title,
                            newStrokeWidth: e.target.value,
                          })
                        )
                      }
                      variant="outlined"
                    />
                    <Slider
                      valueLabelDisplay="auto"
                      value={textObject?.strokeWidth}
                      size="medium"
                      step={0.1}
                      min={0}
                      max={5}
                      sx={{
                        width: "80%",
                        color: "black",
                        "& .MuiSlider-thumb": {
                          "&:focus, &:hover, &.Mui-active": {
                            boxShadow: "0px 0px 0px 8px rgba(0, 0, 0, 0.1)",
                          },
                        },
                      }}
                      onChange={(e) =>
                        dispatch(
                          changeTextStrokeWidth({
                            targetElements: textObject?.title,
                            newStrokeWidth: e.target.value,
                          })
                        )
                      }
                    />
                  </FormControl>
                  <Box sx={{ width: "100%", height: "fit-content" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#555555",
                        my: 2,
                      }}
                    >
                      Fill Color
                    </Typography>

                    {Array.isArray(selectedColorsPalette) &&
                    selectedColorsPalette.length > 0 ? (
                      <TwitterPicker
                        width="100%"
                        colors={selectedColorsPalette}
                        onChange={(color) =>
                          dispatch(
                            changeTextFill({
                              targetElements: textObject?.title,
                              newColor: color.hex,
                            })
                          )
                        }
                        triangle="hide"
                        styles={twitterPickerStyleClient}
                      />
                    ) : (
                      <Typography
                        sx={{ color: "#8a8a8a", fontStyle: "italic" }}
                      >
                        No colors selected{" "}
                        <span
                          className="not-italic text-[#f26728] underline cursor-pointer"
                          onClick={() => {
                            dispatch(setCustomizeTabIndex("palette"));
                          }}
                        >
                          Click to select
                        </span>
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ width: "100%", height: "fit-content" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#555555",
                        my: 2,
                      }}
                    >
                      Stroke Color
                    </Typography>
                    {Array.isArray(selectedColorsPalette) &&
                    selectedColorsPalette.length > 0 ? (
                      <TwitterPicker
                        width="100%"
                        colors={selectedColorsPalette}
                        onChange={(color) =>
                          dispatch(
                            changeTextStroke({
                              targetElements: textObject?.title,
                              newColor: color.hex,
                            })
                          )
                        }
                        triangle="hide"
                        styles={twitterPickerStyleClient}
                      />
                    ) : (
                      <Typography
                        sx={{ color: "#8a8a8a", fontStyle: "italic" }}
                      >
                        No colors selected{" "}
                        <span
                          className="not-italic text-[#f26728] underline cursor-pointer"
                          onClick={() => {
                            dispatch(setCustomizeTabIndex("palette"));
                          }}
                        >
                          Click to select
                        </span>
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ width: "100%", height: "fit-content" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#555555",
                        my: 2,
                      }}
                    >
                      Font Style
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Font Style
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={textObject?.fontFamily}
                        label="Font Style"
                        onChange={(e) =>
                          dispatch(
                            changeFontStyles({
                              targetElements: textObject?.title,
                              newFont: e.target.value,
                            })
                          )
                        }
                      >
                        {fontStyles.map((fontStyle) => (
                          <MenuItem value={fontStyle}>
                            <Typography sx={{ fontFamily: fontStyle }}>
                              {fontStyle}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "12px",
            marginTop: "16px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "50%",
              bgcolor: "#fff",
              color: "#040707",
              border: "1px solid #040707",
              "&:hover": {
                border: "#f26728 solid 1px",
                color: "#f26728",
                backgroundColor: "rgba(242, 103, 40, 0.1)",
              },
            }}
            disableFocusRipple
            disableElevation
            onClick={() => {
              dispatch(setCustomizeTabIndex("colors"));
            }}
          >
            Back
          </Button>

          <Button
            variant="contained"
            sx={{
              width: "50%",
              bgcolor: "#fff",
              color: "#040707",
              border: "1px solid #040707",
              "&:hover": {
                border: "#f26728 solid 1px",
                color: "#f26728",
                backgroundColor: "rgba(242, 103, 40, 0.1)",
              },
            }}
            disableFocusRipple
            disableElevation
            onClick={() => {
              // dispatch(setCustomizeTabIndex("logos"));
              setFrontBackTextEditorTabsIndex(1);
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          display: frontBackTextEditorTabsIndex === 1 ? "block" : "none",
        }}
      >
        {(!Array.isArray(backTextArray) || backTextArray?.length === 0) && (
          <Button
            variant="contained"
            disableRipple
            disableElevation
            onClick={() => {
              dispatch(addTextOnCanvas({ textLocation: "back" }));
            }}
            sx={{
              border: "#f26728 solid 1px",
              color: "#f26728",
              bgcolor: "#fff",
              "&:hover": {
                backgroundColor: "rgba(242, 103, 40, 0.1)",
              },
            }}
          >
            Add New Text
          </Button>
        )}

        {Array.isArray(backTextArray) &&
          backTextArray.length > 0 &&
          backTextArray?.map((textObject, i) => {
            // console.log(textObject);
            return (
              <Accordion
                expanded={expandedPanelBack === textObject?.title}
                onChange={handleChangeExpandedPanelBack(textObject?.title)}
                sx={{
                  bgcolor: "#f6f9fb",
                  my: 1,
                  borderBottomLeftRadius: "0.5vw",
                  borderBottomRightRadius: "0.5vw",
                  ...(i === 0 && {
                    borderToprightRadius: "0 !important",
                    borderTopLeftRadius: "0 !important",
                  }),
                }}
                elevation={0}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: 500,
                        color: "#262626",
                      }}
                    >
                      Edit {textObject?.text}
                    </Typography>
                    <Box>
                      <Tooltip title="Add New Text">
                        <IconButton
                          disableRipple
                          sx={{ p: 0 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(addTextOnCanvas({ textLocation: "back" }));
                          }}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete this text">
                        <IconButton
                          disableRipple
                          sx={{ p: 0 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            const confirmDelete = window.confirm(
                              "Are you sure want to delete?"
                            );
                            if (confirmDelete) {
                              dispatch(
                                deleteTextFromCanvas({
                                  objectID: textObject?.id,
                                  textLocation: "back",
                                })
                              );
                            }
                          }}
                        >
                          <DeleteIcon sx={{ color: "#C40234" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    size="small"
                    placeholder="Edit Text"
                    label={"Edit Text"}
                    sx={{ mb: 2 }}
                    fullWidth
                    value={textObject?.text}
                    onChange={(e) =>
                      dispatch(
                        changeText({
                          targetElements: textObject?.title,
                          newText: e.target.value,
                        })
                      )
                    }
                  />
                  <FormLabel>Font Size</FormLabel>
                  <FormControl
                    sx={{
                      width: "100%",
                      mb: 2,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <TextField
                      size="small"
                      placeholder="Font Size"
                      value={textObject?.fontSize}
                      sx={{ width: "20%" }}
                      type="number"
                      onChange={(e) =>
                        dispatch(
                          changeTextFontSize({
                            targetElements: textObject?.title,
                            newFontSize: e.target.value,
                          })
                        )
                      }
                      fullWidth
                      variant="outlined"
                    />
                    <Slider
                      valueLabelDisplay="auto"
                      value={textObject?.fontSize}
                      size="medium"
                      sx={{
                        width: "80%",
                        color: "black",
                        "& .MuiSlider-thumb": {
                          "&:focus, &:hover, &.Mui-active": {
                            boxShadow: "0px 0px 0px 8px rgba(0, 0, 0, 0.1)",
                          },
                        },
                      }}
                      onChange={(e) =>
                        dispatch(
                          changeTextFontSize({
                            targetElements: textObject?.title,
                            newFontSize: e.target.value,
                          })
                        )
                      }
                    />
                  </FormControl>
                  <FormLabel>Stroke Width</FormLabel>
                  <FormControl
                    sx={{
                      width: "100%",
                      mb: 2,
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <TextField
                      size="small"
                      placeholder="Stroke Width"
                      value={textObject?.strokeWidth}
                      type="number"
                      inputProps={{
                        step: "0.1",
                      }}
                      sx={{ width: "20%" }}
                      onChange={(e) =>
                        dispatch(
                          changeTextStrokeWidth({
                            targetElements: textObject?.title,
                            newStrokeWidth: e.target.value,
                          })
                        )
                      }
                      variant="outlined"
                    />
                    <Slider
                      valueLabelDisplay="auto"
                      value={textObject?.strokeWidth}
                      size="medium"
                      step={0.1}
                      min={0}
                      max={5}
                      sx={{
                        width: "80%",
                        color: "black",
                        "& .MuiSlider-thumb": {
                          "&:focus, &:hover, &.Mui-active": {
                            boxShadow: "0px 0px 0px 8px rgba(0, 0, 0, 0.1)",
                          },
                        },
                      }}
                      onChange={(e) =>
                        dispatch(
                          changeTextStrokeWidth({
                            targetElements: textObject?.title,
                            newStrokeWidth: e.target.value,
                          })
                        )
                      }
                    />
                  </FormControl>
                  <Box sx={{ width: "100%", height: "fit-content" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#555555",
                        my: 2,
                      }}
                    >
                      Fill Color
                    </Typography>
                    {Array.isArray(selectedColorsPalette) &&
                    selectedColorsPalette.length > 0 ? (
                      <TwitterPicker
                        width="100%"
                        colors={selectedColorsPalette}
                        onChange={(color) =>
                          dispatch(
                            changeTextFill({
                              targetElements: textObject?.title,
                              newColor: color.hex,
                            })
                          )
                        }
                        triangle="hide"
                        styles={twitterPickerStyleClient}
                      />
                    ) : (
                      <Typography
                        sx={{ color: "#8a8a8a", fontStyle: "italic" }}
                      >
                        No colors selected{" "}
                        <span
                          className="not-italic text-[#f26728] underline cursor-pointer"
                          onClick={() => {
                            dispatch(setCustomizeTabIndex("palette"));
                          }}
                        >
                          Click to select
                        </span>
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ width: "100%", height: "fit-content" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#555555",
                        my: 2,
                      }}
                    >
                      Stroke Color
                    </Typography>
                    {Array.isArray(selectedColorsPalette) &&
                    selectedColorsPalette.length > 0 ? (
                      <TwitterPicker
                        width="100%"
                        colors={selectedColorsPalette}
                        onChange={(color) =>
                          dispatch(
                            changeTextStroke({
                              targetElements: textObject?.title,
                              newColor: color.hex,
                            })
                          )
                        }
                        styles={twitterPickerStyleClient}
                        triangle="hide"
                      />
                    ) : (
                      <Typography
                        sx={{ color: "#8a8a8a", fontStyle: "italic" }}
                      >
                        No colors selected{" "}
                        <span
                          className="not-italic text-[#f26728] underline cursor-pointer"
                          onClick={() => {
                            dispatch(setCustomizeTabIndex("palette"));
                          }}
                        >
                          Click to select
                        </span>
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ width: "100%", height: "fit-content" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "#555555",
                        my: 2,
                      }}
                    >
                      Font Style
                    </Typography>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Font Style
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={textObject?.fontFamily}
                        label="Font Style"
                        onChange={(e) =>
                          dispatch(
                            changeFontStyles({
                              targetElements: textObject?.title,
                              newFont: e.target.value,
                            })
                          )
                        }
                      >
                        {fontStyles.map((fontStyle) => (
                          <MenuItem value={fontStyle}>
                            <Typography sx={{ fontFamily: fontStyle }}>
                              {fontStyle}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "12px",
            marginTop: "16px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "50%",
              bgcolor: "#fff",
              color: "#040707",
              border: "1px solid #040707",
              "&:hover": {
                border: "#f26728 solid 1px",
                color: "#f26728",
                backgroundColor: "rgba(242, 103, 40, 0.1)",
              },
            }}
            disableFocusRipple
            disableElevation
            onClick={() => {
              // dispatch(setCustomizeTabIndex("colors"));
              setFrontBackTextEditorTabsIndex(0);
            }}
          >
            Back
          </Button>

          <Button
            variant="contained"
            sx={{
              width: "50%",
              bgcolor: "#fff",
              color: "#040707",
              border: "1px solid #040707",
              "&:hover": {
                border: "#f26728 solid 1px",
                color: "#f26728",
                backgroundColor: "rgba(242, 103, 40, 0.1)",
              },
            }}
            disableFocusRipple
            disableElevation
            onClick={() => {
              dispatch(setCustomizeTabIndex("logos"));
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TextNumbersEditor;
