import { Box, Typography } from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { buildCategoryTree } from "../../../config/utils";
import { useNavigate } from "react-router";

function CategoryDropDown({
  handleOpen,
  categories,
  parentCategroyId = null,
  justifyContent = "center",
  toggleResponsiveView,
}) {
  const navigate = useNavigate();

  const categoryTree = buildCategoryTree(categories || [], parentCategroyId);

  const closeMenu = () => {
    handleOpen(false);
  };

  const mainCategoryStyle = {
    fontSize: "16px",
    fontWeight: "500",
    mb: "4px",
  };

  const childCategoryStyle = {
    fontSize: "14px",
    fontWeight: "500",
    height: "fit-content",
    color: "#666666",
    cursor: "pointer",
    "&:hover": {
      color: "darkorange",
    },
  };
  return (
    <Box
      onMouseLeave={closeMenu}
      sx={{
        p: "12px 12px 98px 18px",
        bgcolor: "white",
        display: "flex",
        gap: "34px",
        width: "100%",
        flexWrap: "wrap",
        minHeight: "150px",
        cursor: "pointer",
        borderTop: "1px solid lightgray",
        justifyContent,
      }}
    >
      {/* Main Categories Div */}
      {categoryTree?.map((mainCategory) => (
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            flexDirection: "column",
          }}
          key={mainCategory.category.id}
        >
          <Typography sx={mainCategoryStyle}>
            {mainCategory.category.name}
          </Typography>

          {/* Second Layer of Categories I mean subcategories and sub sub categories */}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2,minmax(100px, 1fr))",
              rowGap: 1,
              columnGap: 2,
              // gridAutoRows:"20px"
              alignItems: "center",
            }}
          >
            {mainCategory.subcategories?.map((subcategory) => (
              <Typography
                onClick={() =>
                  {
                    navigate(`/category/subcategory/${subcategory.category.id}`)
                    // navigate(`/under-construction`);
                    toggleResponsiveView?.();
                  }
                }
                key={subcategory.category.id}
                sx={childCategoryStyle}
              >
                {subcategory.category.name}
              </Typography>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default CategoryDropDown;
