import { motion, useAnimation } from "framer-motion";
import logo from "../../assets/images/logo copy.png";
import React, { useCallback, useEffect, useState } from "react";
import { IoMdMenu } from "react-icons/io";
import { Box, IconButton } from "@mui/material";
import CategoryDropDown from "../LandingPage/Header/CategoryDropDown";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../slices/userCategorySlice";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ResponsiveMenuDropDown from "../LandingPage/Header/ResponsiveMenuDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Searchbar from "./Searchbar";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const controls = useAnimation();
  const [scrollDirection, setScrollDirection] = useState("none");
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const [openCollapsable, setOpenCollapsable] = useState(false);
  const [parentCategroyId, setParentCategoryId] = useState(null);

  const [openResponsiveCollapsable, setOpenResponsiveCollapsable] =
    React.useState(false);

  const handleClickCollapsable = (open) => {
    setOpenCollapsable(open);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos > prevScrollPos) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    if (scrollDirection === "down") {
      controls.start({ y: "-100%", transition: { duration: 0.5 } });
    } else if (scrollDirection === "up") {
      controls.start({ y: "0%", transition: { duration: 0.5 } });
    }
  }, [controls, scrollDirection]);

  const { userCategories, catLoading, catError } = useCallback(
    useSelector((state) => state.userCategoryState)
  );

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleClickResponsiveCollapsable = (open) => {
    setOpenResponsiveCollapsable((prev) => !prev);
  };

  return (
    <div
      className="relative pb-[4.8dvw]"
      // style={{
      //   background:
      //     "linear-gradient(156deg, rgba(255,255,255,1) 0%, rgba(9,56,52,1) 59%, rgba(0,0,0,1) 100%)",
      //   color: "#fff",
      // }}
    >
      <motion.div
        className="fixed z-[999] w-full px-[5dvw] py-[3dvw] md:px-[4vw] md:py-[1vw] backdrop-blur-md flex justify-between items-center"
        animate={controls}
      >
        <div className="logo">
          <Link to={"/"}>
            <img src={logo} alt="logo" className="h-[9dvw] md:h-[2.6vw]" />
          </Link>
        </div>
        <div className="links flex items-center gap-[2vw] max-[768px]:hidden">
          <motion.a
            onMouseEnter={(e) => {
              setParentCategoryId("6d1735c4-61f0-4e05-bda4-f7dfc1ffdb7d");
              handleClickCollapsable(true);
            }}
            onMouseLeave={(e) => {
              handleClickCollapsable(false);
            }}
            onClick={() => {
              navigate(
                `/category/subcategory/${`6d1735c4-61f0-4e05-bda4-f7dfc1ffdb7d`}`
              );
            }}
            className="text-[1.2vw] font-light capitalize cursor-pointer"
          >
            Men
          </motion.a>
          <motion.a
            onMouseEnter={(e) => {
              setParentCategoryId("5272b0d9-8871-4f1a-b8bf-f31d2e12e8ed");
              handleClickCollapsable(true);
            }}
            onMouseLeave={(e) => {
              handleClickCollapsable(false);
            }}
            onClick={() => {
              navigate(
                `/category/subcategory/${`5272b0d9-8871-4f1a-b8bf-f31d2e12e8ed`}`
              );
            }}
            className="text-[1.2vw] font-light capitalize cursor-pointer"
          >
            Women
          </motion.a>
          {/* <motion.a
            onClick={() => navigate("/under-construction")}
            className="text-[1.2vw] font-light capitalize cursor-pointer"
          >
            Customize
          </motion.a> */}
          <motion.a
            onClick={() => navigate("/about")}
            className="text-[1.2vw] font-light capitalize cursor-pointer"
          >
            About
          </motion.a>
          <motion.a
            onClick={() => navigate("/contact")}
            className="text-[1.2vw] font-light capitalize cursor-pointer"
          >
            Contact
          </motion.a>
          <motion.a
            onClick={() => navigate("/how-to-order")}
            className="text-[1.2vw] font-light capitalize cursor-pointer"
          >
            How to Order
          </motion.a>
          <Searchbar />
        </div>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          
          <IconButton disableRipple onClick={handleClickResponsiveCollapsable}>
            {!openResponsiveCollapsable ? <MenuIcon /> : <CloseIcon />}
          </IconButton>

          <Box
            sx={{
              position: "absolute",
              left: "0px",
              top: "72px", // height of the navbar
              height: "100vh",
              width: "100%",
              bgcolor: "rgba(128, 128, 128, 0.4)",
              color: "#212121",
              zIndex: "100",
              display: openResponsiveCollapsable ? "block" : "none",
            }}
          >
            <ResponsiveMenuDropDown
              toggleView={handleClickResponsiveCollapsable}
              categories={userCategories}
            />
          </Box>
        </Box>
      </motion.div>
      <Box
        onMouseEnter={() => handleClickCollapsable(true)}
        sx={{
          position: "fixed",
          left: "0px",
          top: "0px",
          height: "100vh",
          width: "100%",
          bgcolor: "transparent",
          // opacity:"0.3",
          // bgcolor: "rgba(128, 128, 128, 0.4)",
          zIndex: "1000",
          display: openCollapsable ? "block" : "none",
        }}
      >
        <Box
          sx={{ height: "60px", width: "100%", bgcolor: "transparent" }}
        ></Box>
        <CategoryDropDown
          handleOpen={handleClickCollapsable}
          categories={userCategories}
          parentCategroyId={parentCategroyId}
        />
      </Box>
    </div>
  );
}

export default Navbar;
