import React from "react";
import aboutUsImage from "../../../assets/images/about-us-image.png";
import { motion } from "framer-motion";

const About = () => {
  return (
    <>
      <div className="w-full flex items-center justify-center flex-col px-20 mt-[50px] md:mt-0">
        <h1 className="text-[4.4vw] md:text-[2.4vw] mt-4 font-semibold">
          About Us
        </h1>
      </div>
      <AboutHero />
    </>
  );
};

export default About;

function AboutHero() {
  return (
    <div className="flex flex-col-reverse md:flex-row items-center justify-around px-8 md:px-20 my-8">
      <div className="text w-full md:w-1/2">
        <motion.p
          initial={{ opacity: 0, x: -400 }}
          whileInView={{ opacity: 1, x:0 }}
          transition={{ ease: "easeIn", duration: 0.4, delay: 0.03  }}
          className="text-[3.4vw] sm:text-[2.6vw] md:text-[1.2vw] text-center px-2 lg:px-2 mt-4 md:mt-0"
        >
          <strong className="text-[#f26728] text-[24px]">US Sports</strong> is
          your go-to partner for top-quality custom sports uniforms. We craft
          athletic apparel tailored for fast-growing sports businesses, ensuring
          every piece meets the highest standards of performance and durability.
          Our custom uniforms help teams stand out, boost morale, and foster
          unity. Perfect for fundraising, showcasing products, or building team
          culture, our uniforms are visually striking, comfortable, and
          resilient. With innovative design, premium materials, and meticulous
          attention to detail, US Sports delivers excellence from design to
          delivery. Partner with us for superior custom athletic apparel and
          make a lasting impression.
        </motion.p>
      </div>
      <div className="image-about-us w-full md:w-fit flex items-center justify-center">
        <motion.img
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ ease: "easeIn", duration: 0.4, delay: 0.06 }}
          src={aboutUsImage}
          alt="about image"
          className="w-full sm:w-[40vw] md:w-[30vw] "
        />
      </div>
    </div>
  );
}
