import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { textFieldStyle } from "../../../config/constants";
import { useNavigate } from "react-router";
import {
  createNewProduct,
  selectIsNewProductCreating,
} from "../../../slices/productSlice";
import toast from "react-hot-toast";

const AddNewProductDialog = ({ allCategories, open, setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categoryValue, setcategoryValue] = useState("");
  const [productType, setProductType] = useState("single");

  const isProductCreating = useSelector(selectIsNewProductCreating);

  // useEffect(() => {
  //   if (allCategories && allCategories.data.length > 0) {
  //     setcategoryValue(allCategories.data[0].id);
  //   }

  //   return () => {};
  // }, [allCategories]);

  useEffect(() => {
    if (!isProductCreating && open) {
      setOpen(false);
    }
  }, [isProductCreating]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    if (!isProductCreating) {
      setOpen(false);
    }
  };

  // The most parent category

  const [theMostParentCategories, setTheMostParentCategories] = useState([]);
  const [selectedMostParentCategory, setSelectedMostParentCategory] =
    useState("");
  useEffect(() => {
    if (allCategories?.data) {
      const theMostParentCategoriesArray = allCategories.data.filter(
        (category) => category.parent_category_id === null
      );
      setTheMostParentCategories(theMostParentCategoriesArray);
    }
  }, [allCategories?.data]);

  // function to get all subcategories
  const getAllSubCategories = (parentCategoryId) => {
    if (allCategories?.data) {
      const subCategoriesArray = allCategories.data.filter(
        (category) => category.parent_category_id === parentCategoryId
      );
      return subCategoriesArray;
    }
  };

  // Selection Of Categories Based On Gender Category
  const [genderCategoryBasedCategories, setGenderCategoryBasedCategories] =
    useState([]);
  const [selectedGenderBasedCategory, setSelectedGenderBasedCategory] =
    useState("");

  useEffect(() => {
    if (
      selectedMostParentCategory !== "" &&
      getAllSubCategories(selectedMostParentCategory).length > 0
    ) {
      const genderCategoryBasedCategoriesArray = getAllSubCategories(
        selectedMostParentCategory
      );
      setGenderCategoryBasedCategories(genderCategoryBasedCategoriesArray);
    }
  }, [selectedMostParentCategory]);

  // Selection of Sub Categories
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    if (
      selectedGenderBasedCategory !== "" &&
      getAllSubCategories(selectedGenderBasedCategory).length > 0
    ) {
      const subcategoriesArray = getAllSubCategories(
        selectedGenderBasedCategory
      );
      setSubCategories(subcategoriesArray);
    }
  }, [selectedGenderBasedCategory]);

  // const renderSelectSubCategories = (parentCategoryId) => {
  //   const subCategoriesArray = getAllSubCategories(parentCategoryId);
  //   const [selectedCategoryValue, setSelectedCategoryValue] = useState("");
  //   if (subCategoriesArray.length === 0) {
  //     return (
  //       <TextField
  //         id="category_id"
  //         name="category_id"
  //         value={categoryValue}
  //         label="Select Category"
  //         fullWidth
  //         select
  //         size="small"
  //         sx={textFieldStyle}
  //         onChange={(e) => {
  //           setcategoryValue(e.target.value);
  //         }}
  //       >
  //         {allCategories?.data.map((item) => (
  //           <MenuItem value={item.id}>
  //             <Typography>{item.name}</Typography>
  //           </MenuItem>
  //         ))}
  //       </TextField>
  //     );
  //   }
  //   setSelectedSubCategoryArray([
  //     ...selectedSubCategoryArray,
  //     subCategoriesArray,
  //   ]);
  // };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            // console.log(formJson);

            try {
              const { payload: createdProduct } = await dispatch(
                createNewProduct(formJson)
              );
              toast.success("Product created successfully!");
              setProductType("");
              setcategoryValue("");
              setSelectedMostParentCategory("");
              setSelectedGenderBasedCategory("");
              handleClose();
            } catch (error) {
              console.error("Error While Creating Product:", error);
              toast.error("Error While Creating Product");
            }
          },
          sx: { p: 1 },
        }}
      >
        <DialogTitle>Add New Product</DialogTitle>
        <DialogContent sx={{ "&.MuiDialogContent-root": { p: 1 } }}>
          <TextField
            required
            margin="dense"
            id="name"
            name="name"
            label="Product Name"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={textFieldStyle}
          />
          <TextField
            required
            margin="dense"
            id="description"
            name="description"
            label="Product Description"
            multiline
            maxRows={2}
            minRows={1}
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={textFieldStyle}
          />
          <TextField
            required
            margin="dense"
            id="sku"
            name="sku"
            label="SKU"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={textFieldStyle}
          />
          <TextField
            required
            margin="dense"
            id="product_type"
            name="type"
            label="Product Type"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            select
            value={productType}
            sx={textFieldStyle}
            onChange={(e) => setProductType(e.target.value)}
          >
            {["single", "package"].map((item, index) => (
              <MenuItem key={index} value={item}>
                <Typography>{item}</Typography>
              </MenuItem>
            ))}
          </TextField>

          <TextField
            value={selectedMostParentCategory}
            label="Select Gender"
            fullWidth
            select
            size="small"
            sx={textFieldStyle}
            onChange={(e) => {
              setSelectedMostParentCategory(e.target.value);
            }}
          >
            {theMostParentCategories?.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                <Typography>{item.name}</Typography>
              </MenuItem>
            ))}
          </TextField>
          {/* Conditional Checks for Gender Based Category Selection Selection */}
          {selectedMostParentCategory !== "" &&
            getAllSubCategories(selectedMostParentCategory).length > 0 && (
              <TextField
                value={selectedGenderBasedCategory}
                label="Select Category"
                fullWidth
                select
                size="small"
                sx={textFieldStyle}
                onChange={(e) => {
                  setSelectedGenderBasedCategory(e.target.value);
                }}
              >
                {genderCategoryBasedCategories?.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    <Typography>{item.name}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            )}
          {/* Conditional check for Sub Category Selection */}
          {selectedGenderBasedCategory !== "" &&
            getAllSubCategories(selectedGenderBasedCategory).length > 0 && (
              <TextField
                id="category_id"
                name="category_id"
                value={categoryValue}
                label="Select Subcategory"
                fullWidth
                select
                size="small"
                sx={textFieldStyle}
                onChange={(e) => {
                  setcategoryValue(e.target.value);
                }}
              >
                {subCategories?.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    <Typography>{item.name}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            )}
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            disableElevation
            disabled={isProductCreating}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button type="submit" disableElevation disabled={isProductCreating}>
            {isProductCreating ? (
              <Oval height={20} width={20} color="#0a5484" />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewProductDialog;
