import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import sampleImage from "../../assets/images/logo copy.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const formStyles = {
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  maxWidth: "400px",
  margin: "auto",
  padding: "0 16px",
};

function AdminLoginForm() {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL_LOCAL}/auth/login`,
          values,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const { token, user, refreshToken } = response.data;

        sessionStorage.setItem("token", token);
        sessionStorage.setItem("refreshToken", refreshToken);
        sessionStorage.setItem("user", JSON.stringify(user)); // Store user as string

        // Redirect or handle successful login
        navigate("/dashboard");
      } catch (error) {
        console.error("Login failed:", error);
        toast.error("Login failed. Please check your credentials.");
      }
    },
  });

  return (
    <>
      <form
        style={formStyles}
        onSubmit={formik.handleSubmit}
        className="flex items-center w-full justify-end flex-col"
      >
        <img src={sampleImage} alt="Logo" className="w-[15vw] " />
        <Typography textAlign="center" variant="h5">
          Admin Login
        </Typography>
        <TextField
          fullWidth
          id="email"
          name="email"
          size="small"
          label="Email"
          variant="outlined"
          value={formik.values.email} // Changed from username to email
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          fullWidth
          id="password"
          name="password"
          size="small"
          type="password"
          label="Password"
          variant="outlined"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />

        <Button
          disabled={formik.isSubmitting}
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            bgcolor: "#f26728",
            "&:hover": {
              bgcolor: "#f26728",
            },
          }}
          disableElevation
        >
          {formik.isSubmitting ? "Submitting..." : "Login"}
        </Button>
      </form>
    </>
  );
}

export default AdminLoginForm;
