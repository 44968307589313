import React, { useRef, useEffect } from "react";
import ParallaxSection from "./ParallaxSection";
import video from "../../assets/video/FlexClip_12.mp4";
import imageSrc from "../../assets/images/game-hockey-player-scoring-goal.jpg"

const ImageSection01 = () => {
  const videoRef = useRef(null);

  // useEffect(() => {
  //   const options = {
  //     root: null,
  //     rootMargin: "0px",
  //     threshold: 0.5, // Play video when 50% of it is visible
  //   };

  //   const callback = (entries, observer) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         videoRef.current.play();
  //       } else {
  //         videoRef.current.pause();
  //       }
  //     });
  //   };

  //   const observer = new IntersectionObserver(callback, options);
  //   observer.observe(videoRef.current);

  //   // Cleanup
  //   return () => observer.disconnect();
  // }, []);

  return (
    <div
      data-scroll
      data-scroll-section
      data-scroll-speed="-.9"
      className="h-fit py-7 md:h-[105dvh] w-full bg-[#0a0a0a] overflow-hidden -mt-[30px] z-[10]"
    >
      {/* <video
        ref={videoRef}
        src={video}
        className="w-full h-full object-contain md:object-cover"
        loop
        muted // Mute the video until it enters the viewport
      /> */}
      <img
          src={imageSrc}
          alt="banner image"
          className="w-full h-full object-contain md:object-cover scale-110 aspect-*"
        />
    </div> 
  );
};

export default ImageSection01;
