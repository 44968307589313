import React from "react";
import Navbar from "../AnimatedLandingPage/Navbar";
import UnderConstructionImage from "../../assets/images/work-in-progress.webp";
import { Link } from "react-router-dom";
const WeAreUnderConstruction = () => {
  return (
    <div className="overflow-hidden" style={{ overflow: "hidden" }}>
      <Navbar />
      <div className="h-[89vh] w-[100vw] flex justify-center items-center flex-col gap-[1vw]">
        <img
          src={UnderConstructionImage}
          alt="Under Construction Image"
          className="w-[40vw] md:w-[10vw]"
        />
        <h4 className="text-[7vw] md:text-[1.5vw]">
          Go Back to{" "}
          <Link to={"/"} className="underline text-blue-600">
            Home Page
          </Link>
        </h4>
      </div>
    </div>
  );
};

export default WeAreUnderConstruction;
