import { Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeColorsArray, selectColorsArray } from "../../slices/canvasSlice";
import { IoMdAddCircleOutline } from "react-icons/io";
import AddColorToPalette from "./AddColorToPalette";

const colors = [
  "#FFFFFF",
  "#0E0E0E",
  "#D10000",
  "#AD0000",
  "#780000",
  "#5E0B1B",
  "#52C000",
  "#FFB3D7",
  "#FF4689",
  "#F54900",
  "#000C39",
  "#B5A773",
  "#1D0070",
  "#FFEF00",
  "#CCDE00",
  "#81BFFA",
  "#B19CD9",
  "#DBDCDD",
  "#001B64",
  "#DDC095",
  "#56ACDE",
  "#A5A7A8",
  "#130047",
  "#3F2713",
  "#177378",
  "#03428A",
  "#303A1A",
  "#2B1500",
  "#6E8496",
  "#CEC8A4",
  "#61605E",
  "#B84D00",
  "#ECEDEF",
  "#FFBA00",
  "#B18500",
  "#00061A",
  "#20A495",
  "#00240D",
  "#FFD2FC",
  "#086375",
  "#E5C687",
  "#231123",
  "#623B5A",
  "#EDFFAB",
  "#A2D729",
  "#613F75",
  "#FFFFB3",
  "#564787",
];

const ColorPaletteSelector = () => {
  const dispatch = useDispatch();
  const [selectedColors, setSelectedColors] = useState([]);

  const [allColorsArray, setAllColorsArray] = useState(colors);

  const colorsArray = useSelector(selectColorsArray);

  const toggleColor = (color) => {
    if (colorsArray.includes(color)) {
      dispatch(changeColorsArray(colorsArray.filter((c) => c !== color)));

      setSelectedColors(selectedColors.filter((c) => c !== color));
    } else {
      if (colorsArray.length < 8) {
        dispatch(changeColorsArray([...colorsArray, color]));
        setSelectedColors([...selectedColors, color]);
      }
    }
  };

  function isBrightColor(hexCode) {
    // Remove '#' if it exists
    hexCode = hexCode.replace("#", "");

    // Convert hex to RGB
    let r = parseInt(hexCode.substr(0, 2), 16);
    let g = parseInt(hexCode.substr(2, 2), 16);
    let b = parseInt(hexCode.substr(4, 2), 16);

    // Calculate brightness
    let brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Determine if it's bright or dark
    return brightness > 125;
  }

  const [toolbarOpen, setToolbarOpen] = useState(false);
  const toolbarRef = useRef(null);
  const openedToolBar = useRef(null);

  useEffect(() => {
    // Function to handle clicks on document
    const handleClickOutside = (event) => {
      if (
        toolbarRef.current &&
        !toolbarRef.current.contains(event.target) &&
        !openedToolBar.current?.contains(event.target) // Check openedToolBar as well
      ) {
        setToolbarOpen(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToolbarClick = () => {
    setToolbarOpen(!toolbarOpen);
    // setAllColorsArray([...allColorsArray, chromePickerColor]);
  };

  const [chromePickerColor, setChromePickerColor] = useState("#ff00ff");

  return (
    <div>
      <Typography sx={{ mb: 2 }}>Select up to 8 colors:</Typography>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {allColorsArray.map((color, index) => (
          <div
            key={color}
            onClick={() => toggleColor(color)}
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: color,
              marginRight: "10px",
              marginBottom: "10px",
              position: "relative",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            className="shadow-lg"
          >
            {colorsArray.includes(color) && (
              <div
                className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-md ${
                  isBrightColor(color) ? "text-black" : "text-white"
                }`}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {colorsArray.indexOf(color) + 1}
              </div>
            )}
          </div>
        ))}
        <div
          ref={toolbarRef}
          onClick={handleToolbarClick}
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: "#fff",
            marginRight: "10px",
            marginBottom: "10px",
            position: "relative",
            borderRadius: "10px",
            cursor: "pointer",
          }}
          className="shadow-md"
        >
          <div
            className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-3xl font-light text-black`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IoMdAddCircleOutline />
          </div>
          {toolbarOpen && (
            <div
              ref={openedToolBar}
              className={`absolute bottom-0 right-0 text-3xl font-light text-black`}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <AddColorToPalette
                setColor={setChromePickerColor}
                color={chromePickerColor}
                setAllColorsArray={setAllColorsArray}
                setToolbarOpen={setToolbarOpen}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ColorPaletteSelector;
