import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { footerData } from "../../../config/constants";
import EmailIcon from "@mui/icons-material/Email";
import paymentMethodsPng from "../../../assets/images/Payment_Methods.png";
import { useNavigate } from "react-router";

const Footer = () => {
  const navigate = useNavigate();
  const [isLightBackground, setIsLightBackground] = useState(true); // Initial assumption

  useEffect(() => {
    const handleBackgroundColorChange = () => {
      const currentBackgroundColor = getComputedStyle(
        document.body
      ).backgroundColor;
      const isLight = isLightColor(currentBackgroundColor); // Custom function to check lightness
      setIsLightBackground(isLight);
    };

    window.addEventListener("resize", handleBackgroundColorChange); // Handle dynamic changes

    handleBackgroundColorChange(); // Run on initial load

    return () =>
      window.removeEventListener("resize", handleBackgroundColorChange); // Cleanup
  }, []);

  const isLightColor = (colorString) => {
    // Improved lightness check using WCAG contrast ratio calculation
    const rgb = colorString.match(/\d+/g).map(Number); // Extract RGB values
    const [r, g, b] = rgb;
    const lum = 0.2126 * r + 0.7152 * g + 0.0722 * b; // Calculate luminance
    return lum / 255 > 0.5; // Threshold for light background
  };

  const getTextColor = () => {
    return isLightBackground ? "black" : "white";
  };
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#000",
        // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${footerBgImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box sx={{ flexBasis: "70%" }}>
          <Grid
            container
            sx={{
              mt: 3,
              ml: 0,
              width: "100%",
              minHeight: "fit-content",
              height: "fit-content",
              bgcolor: "transparent",
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              color: "#fff",
              p: {
                xs: "10px 30px",
                sm: "20px 90px",
                md: "20px 90px",
                lg: "30px 150px",
              },
            }}
            rowSpacing={15}
            columnSpacing={1}
          >
            {footerData.map((dataRow, index) => (
              <Fragment key={index}>
                <Grid
                  item
                  xs={6}
                  md={3}
                  sx={{
                    paddingTop: "0 !important",
                  }}
                >
                  <List
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <ListItem sx={{ p: 0 }}>
                      <Typography
                        fontSize={"18px"}
                        fontWeight={700}
                        sx={{ color: "#fff", textDecoration: "underline" }}
                      >
                        {dataRow.heading}
                      </Typography>
                    </ListItem>
                    {dataRow.items.map((item, index) => (
                      <ListItem key={item?.id} sx={{ p: 0 }}>
                        <Typography
                          fontSize={"16px"}
                          fontWeight={400}
                          sx={{ color: "#dedede", cursor: "pointer" }}
                          onClick={() => {
                            if (item.id) {
                              navigate(`/category/subcategory/${item.id}`);
                            } else {
                              navigate(item.url);
                            }
                          }}
                        >
                          {item.name}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Box>
        <Box
          sx={{
            flexBasis: "30%",
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            // bgcolor: "pink",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: 700, color: "#fff", mb: 2 }}
          >
            {/* Payment Methods */}
            Location
          </Typography>
          {/* <img
            src={paymentMethodsPng}
            alt="payment methods"
            style={{ width: "60%", height: "auto" }}
          /> */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21358.300560177137!2d74.43487137322444!3d32.53357850925916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391ee8df4221562b%3A0xfa3bd1b74369b63f!2sKapurowali%2C%20Sialkot%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1718090711886!5m2!1sen!2s"
            width="250"
            height="250"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "fit-content",
          bgcolor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "black",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            px: "10%",
            py: "10px",
          }}
        >
          <Typography
            fontSize={"14px"}
            fontWeight={400}
            sx={{ display: "block", color: "#fff" }}
          >
            Kala Ghumana, Post Office Kapurowali, Sialkot. Pakistan
          </Typography>
          <Box
            sx={{
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: { xs: 2, md: 0 },
              color: "#fff",
              gap: 1,
            }}
          >
            <a target="_blank" href="https://wa.me/+923316166114">
              <WhatsAppIcon />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/profile.php?id=61560023433985"
            >
              <FacebookIcon />
            </a>
            <a target="_blank" href="https://instagram.com">
              <InstagramIcon />
            </a>
            <a target="_blank" href="mailto:info@umarsaith.com">
              <Tooltip title={"info@umarsaith.com"}>
                <EmailIcon />
              </Tooltip>
            </a>
          </Box>
        </Box>
        <Divider
          variant="middle"
          component="flex"
          sx={{ width: "78vw", bgcolor: "#fff" }}
        />
        <Typography fontSize={"14px"} py={"10px"} sx={{ color: "#fff" }}>
          Technosofts ©2024. Innovate with Confidence.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
