import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Grid, Card, Box, Typography } from "@mui/material";
import { validateCartItems } from "../../config/utils";
import { useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import {
  selectBackCanvasExportImage,
  selectCustomProduct,
  selectFrontCanvasExportImage,
  selectProductRosterFile,
} from "../../slices/canvasSlice";
import { dataURLtoBlob } from "../../utils/dataUrlToBlob";

const SendInquiryForm = ({
  productPage = false,
  closeModel = null,
  productRoster,
}) => {
  // const notify = () => toast('Here is your toast.');

  const { cartItems } = useSelector((state) => state.virtualCart);
  const [productName, setProductName] = useState("");
  const [productSKU, setproductSKU] = useState("");

  const frontCanvasExportImage = useSelector(selectFrontCanvasExportImage);
  const backCanvasExportImage = useSelector(selectBackCanvasExportImage);

  const rosterFile = useSelector(selectProductRosterFile);

  const { name: NonCustomProductName, sku: NonCustomProductSku } = useSelector(
    (state) => state.userCategoryState.userSingleProduct
  );
  const { product_name, sku } = useSelector(selectCustomProduct);

  useEffect(() => {
    if (productPage) {
      setProductName(NonCustomProductName);
      setproductSKU(NonCustomProductSku);
    } else {
      setProductName(product_name);
      setproductSKU(sku);
    }
  }, []);

  // console.log(product_name, sku);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      subject: "",
      message: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      phoneNumber: Yup.string()
        // .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
        .required("Required"),
      subject: Yup.string().required("Required"),
      message: Yup.string().required("Required"),
    }),

    onSubmit: async (values, { setFieldError, setSubmitting, resetForm }) => {
      if (productPage === false) {
        if (productRoster[0]?.qty === "" || productRoster[0]?.qty === 0) {
          setFieldError("productRosterNumber", "Error: Fill the roster items."); // Set error for the message field
          return;
        }
      }

      const url = `${process.env.REACT_APP_API_URL_LOCAL}/send-enquiry`;

      const postData = {
        ...values,
        product_name: productName,
        sku: productSKU,
        productRoster:
          Array.isArray(productRoster) && productRoster.length > 0
            ? productRoster
            : null,
      };

      const formData = new FormData();

      // Append form values to the FormData object
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      // Append form values to the FormData object
      formData.append("product_name", productName);
      formData.append("sku", productSKU);
      formData.append("rosterSheet", rosterFile);
      if (Array.isArray(productRoster) && productRoster.length > 0) {
        formData.append("productRoster", JSON.stringify(productRoster));
      }

      if (!productPage) {
        // Append image files to the FormData object
        formData.append(
          "frontCanvasImage",
          dataURLtoBlob(frontCanvasExportImage)
        );
        formData.append(
          "backCanvasImage",
          dataURLtoBlob(backCanvasExportImage)
        );
      }

      const formJson = Object.fromEntries(formData.entries());
      // console.log(formJson);

      try {
        setSubmitting(true);
        toast.promise(
          axios.post(url, formData).then((response) => {
            resetForm();
            if (closeModel) closeModel();
          }),
          {
            loading: "Sending...",
            success: "Successfully Submit",
            error: "Something Went Wrong",
          },
          {
            error: {
              duration: 800,
            },
          }
        );
        // const response = await axios.post(url, postData);
        // console.log("Response:", response.data);
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { isSubmitting } = formik;

  return (
    <>
      {/* <Toaster position="bottom-center" reverseOrder={false} /> */}
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {formik.errors.cart && (
              <Typography variant="body1" color="error">
                {formik.errors.productRosterNumber}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              variant="outlined"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              id="subject"
              name="subject"
              label="Subject"
              variant="outlined"
              value={formik.values.subject}
              onChange={formik.handleChange}
              error={formik.touched.subject && Boolean(formik.errors.subject)}
              helperText={formik.touched.subject && formik.errors.subject}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              multiline
              rows={4}
              id="message"
              name="message"
              label="Message"
              variant="outlined"
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button
                disableElevation
                disableRipple
                disabled={isSubmitting}
                sx={{
                  bgcolor: "#fff",
                  color: "#040707",
                  border: "1px solid #040707",
                  "&:hover": {
                    border: "#f26728 solid 1px",
                    color: "#f26728",
                    backgroundColor: "rgba(242, 103, 40, 0.1)",
                  },
                }}
                type="submit"
                variant="contained"
              >
                Send Inquiry
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SendInquiryForm;
