import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { textFieldStyle } from "../../../config/constants";
import {
  createCustomProduct,
  selectIsCustomProductCreating,
} from "../../../slices/adminCanvasSlice";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import axios from "axios";

const AddNewProdDialog = ({ allCategories, open, setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categoryValue, setcategoryValue] = useState("");

  const isCustomProdCreating = useSelector(selectIsCustomProductCreating);

  useEffect(() => {
    if (allCategories && allCategories.data.length > 0) {
      setcategoryValue(allCategories.data[0].id);
    }

    return () => {};
  }, [allCategories]);

  useEffect(() => {
    if (!isCustomProdCreating && open) {
      setOpen(false);
    }
  }, [isCustomProdCreating]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    if (!isCustomProdCreating) {
      setOpen(false);
    }
  };

  // ↘️ START OF STEP WISE SELECTION OF CATEGORIES

  const [theMostParentCategories, setTheMostParentCategories] = useState([]);
  const [selectedMostParentCategory, setSelectedMostParentCategory] =
    useState("");
  useEffect(() => {
    if (allCategories?.data) {
      const theMostParentCategoriesArray = allCategories.data.filter(
        (category) => category.parent_category_id === null
      );
      setTheMostParentCategories(theMostParentCategoriesArray);
    }
    // console.log(theMostParentCategories);
  }, [allCategories?.data]);

  // function to get all subcategories
  const getAllSubCategories = (parentCategoryId) => {
    if (allCategories?.data) {
      const subCategoriesArray = allCategories.data.filter(
        (category) => category.parent_category_id === parentCategoryId
      );
      return subCategoriesArray;
    }
  };

  // Selection Of Categories Based On Gender Category
  const [genderCategoryBasedCategories, setGenderCategoryBasedCategories] =
    useState([]);
  const [selectedGenderBasedCategory, setSelectedGenderBasedCategory] =
    useState("");

  useEffect(() => {
    if (
      selectedMostParentCategory !== "" &&
      getAllSubCategories(selectedMostParentCategory).length > 0
    ) {
      const genderCategoryBasedCategoriesArray = getAllSubCategories(
        selectedMostParentCategory
      );
      setGenderCategoryBasedCategories(genderCategoryBasedCategoriesArray);
    }
  }, [selectedMostParentCategory]);

  // Selection of Sub Categories
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    if (
      selectedGenderBasedCategory !== "" &&
      getAllSubCategories(selectedGenderBasedCategory).length > 0
    ) {
      const subcategoriesArray = getAllSubCategories(
        selectedGenderBasedCategory
      );
      setSubCategories(subcategoriesArray);
    }
  }, [selectedGenderBasedCategory]);

  // Products Of the Category
  const [subcategoryProductsArray, setSubcategoryProductsArray] = useState([]);
  const [subCatProductsIsLoading, setSubCatProductsIsLoading] = useState(false);
  const [selectedSingleProduct, setSelectedSingleProduct] = useState("");

  useEffect(() => {
    if (categoryValue !== "") {
      const getCategoryProductsArray = async (categoryId) => {
        try {
          setSubCatProductsIsLoading(true);
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL_LOCAL}/category/${categoryId}`
          );
          const categoryProductsArray = response.data;
          // Handle the response data here
          // console.log(categoryProductsArray[0]);
          const { all_products: allProductsArray } = categoryProductsArray[0];
          setSubcategoryProductsArray(allProductsArray);

          return categoryProductsArray[0];
        } catch (error) {
          // Handle errors here
          console.error("Error fetching category products:", error);
        } finally {
          setSubCatProductsIsLoading(false);
        }
      };

      getCategoryProductsArray(categoryValue);

      // setSubcategoryProductsArray(categoryProductsArray?.data);
    }
  }, [categoryValue]);

  // ↖️ END OF STEP WISE SELECTION OF CATEGORIES

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            // console.log(formJson);

            toast.promise(
              new Promise(async (resolve, reject) => {
                try {
                  const { payload: createdProduct } = await dispatch(
                    createCustomProduct(formJson)
                  );
                  // console.log(createdProduct);
                  resolve(createdProduct);
                } catch (error) {
                  reject(error);
                } finally {
                  handleClose();
                }
              }),
              {
                loading: "Creating Product...",
                success: "Product created successfully!",
                error: "Error While Creating Product",
              }
            );
          },

          sx: { p: 1 },
        }}
      >
        <DialogTitle>Add New Custom Product</DialogTitle>
        <DialogContent sx={{ "&.MuiDialogContent-root": { p: 1 } }}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="product_name"
            name="product_name"
            label="Product Name"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={textFieldStyle}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="sku"
            name="sku"
            label="SKU"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={textFieldStyle}
          />

          <TextField
            value={selectedMostParentCategory}
            label="Select Gender"
            fullWidth
            select
            size="small"
            sx={textFieldStyle}
            onChange={(e) => {
              setSelectedMostParentCategory(e.target.value);
            }}
          >
            {theMostParentCategories?.map((item) => (
              <MenuItem value={item.id}>
                <Typography>{item.name}</Typography>
              </MenuItem>
            ))}
          </TextField>
          {/* Conditional Checks for Gender Based Category Selection Selection */}
          {selectedMostParentCategory !== "" &&
            getAllSubCategories(selectedMostParentCategory).length > 0 && (
              <TextField
                value={selectedGenderBasedCategory}
                label="Select Category"
                fullWidth
                select
                size="small"
                sx={textFieldStyle}
                onChange={(e) => {
                  setSelectedGenderBasedCategory(e.target.value);
                }}
              >
                {genderCategoryBasedCategories?.map((item) => (
                  <MenuItem value={item.id}>
                    <Typography>{item.name}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            )}
          {/* Conditional check for Sub Category Selection */}
          {selectedGenderBasedCategory !== "" &&
            getAllSubCategories(selectedGenderBasedCategory).length > 0 && (
              <TextField
                id="category_id"
                name="category_id"
                value={categoryValue}
                label="Select Subcategory"
                fullWidth
                select
                size="small"
                sx={textFieldStyle}
                onChange={(e) => {
                  setcategoryValue(e.target.value);
                  setSelectedSingleProduct("");
                }}
              >
                {subCategories?.map((item) => (
                  <MenuItem value={item.id}>
                    <Typography>{item.name}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            )}
          {subCatProductsIsLoading && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ color: "#2b81d6" }}>
                Loading Products of the Category
              </Typography>
              <Box sx={{ width: "100%", my: "4px" }}>
                <LinearProgress />
              </Box>
            </Box>
          )}
          {categoryValue !== "" &&
            subcategoryProductsArray?.length > 0 &&
            !subCatProductsIsLoading && (
              <TextField
                id="simple_product_id"
                name="simple_product_id"
                value={selectedSingleProduct}
                label="Select Simple Product"
                fullWidth
                select
                size="small"
                sx={textFieldStyle}
                onChange={(e) => {
                  setSelectedSingleProduct(e.target.value);
                }}
              >
                {subcategoryProductsArray?.map((item) => (
                  <MenuItem value={item.id}>
                    <Typography>{item.name}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            )}
          {(subcategoryProductsArray?.length === 0 || subcategoryProductsArray === null ) && categoryValue && (
            <Typography>No Products selected</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            disableElevation
            disabled={isCustomProdCreating}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disableElevation
            disabled={
              isCustomProdCreating ||
              subcategoryProductsArray?.length === 0 ||
              selectedSingleProduct === ""
            }
          >
            {isCustomProdCreating ? (
              <Oval height={20} width={20} color="#0a5484" />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddNewProdDialog;
