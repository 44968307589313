import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { textFieldStyle } from "../../../config/constants";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { addProductTag, deleteProductTag } from "../../../slices/productSlice";
import { useParams } from "react-router";
import toast from "react-hot-toast";

const AddTagsInput = ({ productTags }) => {
  const { id: product_id } = useParams();
  const dispatch = useDispatch();
  const [tags, setTags] = useState(productTags);
  const [inputValue, setInputValue] = useState("");
  const [isTagsLoading, setisTagsLoading] = useState(false);
  const [tagsCount, setTagsCount] = useState(productTags.length);

  useEffect(() => {
    setTagsCount(productTags.length);

    return () => {
      setTagsCount(0);
    };
  }, [productTags]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddTag = async (event) => {
    if (event.key === "Enter" && inputValue.trim()) {
      toast.promise(
        new Promise(async (resolve, reject) => {
          try {
            setisTagsLoading(true);
            const formData = new FormData();
            formData.append("product_id", product_id);
            formData.append("tag", inputValue.trim());
            
            // Assuming addProductTag dispatches an action to add a tag
            await dispatch(addProductTag(formData));
  
            resolve(inputValue.trim());
          } catch (error) {
            reject(error);
          } finally {
            setisTagsLoading(false);
          }
        }),
        {
          loading: "Adding Tag...",
          success: (addedTag) => `Tag "${addedTag}" added successfully!`,
          error: "Error Occurred!",
        }
      );
  
      setInputValue(""); // Clear input value after adding tag
    }
  };
  

  const handleDeleteTag = (tagToDeleteID) => {
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          await dispatch(deleteProductTag({ tag_id: tagToDeleteID }));
          resolve();
        } catch (error) {
          reject(error);
        }
      }),
      {
        loading: "Deleting Tag...",
        success: "Tag deleted successfully!",
        error: "Error Occurred!",
      }
    );
  };
  

  return (
    <div className="tags-input-container">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          //   justifyContent: "start",
          alignItems: "center",
          gap: "1vw",
          flexWrap: "wrap",
        }}
      >
        {productTags.map((tagObj) => (
          <Chip
            key={tagObj.id}
            label={tagObj.tag}
            color="primary"
            onDelete={() => handleDeleteTag(tagObj.id)}
          />
        ))}
      </Box>
      <TextField
        size="small"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleAddTag}
        label="Add Tags"
        variant="outlined"
        fullWidth
        placeholder="Enter a tag and press Enter"
        sx={{ ...textFieldStyle, mt: 2 }}
        disabled={isTagsLoading || tagsCount >= 8}
      />
      {tagsCount >= 8 && (
        <Typography sx={{ fontSize: "12px", my: 1 }}>
          You can add maximum 8 tags.
        </Typography>
      )}
    </div>
  );
};

export default AddTagsInput;
