import React from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addRosterItem } from "../../slices/virtualCartSlice";
import RosterItem from "./RosterItem";
import { addItemInRoster, selectProductRoster } from "../../slices/canvasSlice";

function RosterItemsContainer({ cartItem }) {
  const { productId, unitPrice } = cartItem;

  const dispatch = useDispatch();
  const rosterItems = useSelector(selectProductRoster);
  const handleAddRosterItem = () => {
    const uniqueId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    dispatch(
      addItemInRoster({
        newRosterItem: {
          id: uniqueId,
          number: "",
          name: "",
          topSize: "YXS",
          bottomSize: "YXS",
        },
      })
    );
  };

  return (
    <>
      {/* Add Button */}
      <Box
        sx={{
          m: "8px 0px",
        }}
      >
        <Button
          onClick={handleAddRosterItem}
          variant="contained"
          disableElevation
          sx={{
            bgcolor: "#fff",
            color: "#040707",
            border: "1px solid #040707",
            "&:hover": {
              border: "#f26728 solid 1px",
              color: "#f26728",
              backgroundColor: "rgba(242, 103, 40, 0.1)",
            },
          }}
        >
          + Add Player
        </Button>
      </Box>

      <Grid
        container
        spacing={2}
        sx={{ display: { xs: "none", md: "flex" }, m: "8px 0px" }}
      >
        {/* Headings */}
        <Grid item xs={1}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}
          >
            Number
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}
          >
            Name
          </Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}
          >
            Top Size
          </Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}
          >
            Bottom Size
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}
          >
            Qty
          </Typography>
        </Grid>
        {/* <Grid item xs={2}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#555555" }}
          >
            Price
          </Typography>
        </Grid> */}
      </Grid>

      {/* End Headings */}

      {/* Roster */}
      <Box>
        {rosterItems?.map((rosterItem) => (
          <RosterItem
            key={rosterItem?.id}
            item={rosterItem}
            unitPrice={unitPrice}
            productId={productId}
          />
        ))}
        {/* End Roster */}
      </Box>
    </>
  );
}

export default RosterItemsContainer;
