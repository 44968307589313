import { Box, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";

const ProductDetailsTab = () => {
  const { userSingleProduct: product } = useSelector(
    (state) => state.userCategoryState
  );

  // console.log(product);
  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          // width: { xs: "98%", md: "49%" },
          width: "100%",
          height: { xs: "fit-content" },
          p: "20px 15px",
        }}
      >
        {/* <Typography variant="h6" sx={{ pb: 3 }}>
          Product Description
        </Typography> */}
        {/* <Typography variant="body2">{product?.description}</Typography> */}
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(product?.description),
          }}
        />
      </Box>
      {/*       
      <Box
        sx={{
          width: { xs: "98%", md: "49%" },
          height: { xs: "fit-content", md: "500px" },
          p: "20px 15px",
        }}
      >
        <Typography variant="h6" sx={{ pb: 3 }}>
          Product Composition
        </Typography>
        <Typography variant="body2" pb={3}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo quis eos
          quidem dolores repellendus porro dolore obcaecati minima similique
          blanditiis debitis officiis accusantium, placeat iure, exercitationem,
          odio eaque deleniti dolor.
        </Typography>
        <Typography variant="h6" sx={{ pb: 3 }}>
          Care Instructions
        </Typography>
        <List>
          {[
            `Machine wash at max. 30ºC/86ºF with short spin cycle`,
            `Machine wash
          at max. 30ºC/86ºF with short spin cycle`,
            `Machine wash at max.
          30ºC/86ºF with short spin cycle`,
            `Machine wash at max. 30ºC/86ºF with
          short spin cycle`,
          ].map((item) => (
            <ListItem key={item} disablePadding sx={{ py: 1 }}>
              {item}
            </ListItem>
          ))}
        </List>
      </Box> */}
    </Box>
  );
};

export default ProductDetailsTab;
