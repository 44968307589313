import { Box, Fade, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Topbar from "./Topbar";
import AllCard from "./AllCard";
import { Bars } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCustomProduct,
  selectProductData,
} from "../../../slices/adminCanvasSlice";
import { deleteProduct } from "../../../slices/productSlice";
import toast from "react-hot-toast";

const AllContainer = ({ title, data, actions, isLoading, redirectUrl, searchFunction }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productData = useSelector(selectProductData);
  

  const handleDeleteCustomProduct = async (c_id) => {
    const userConfirmed = window.confirm("Are you sure want to delete?");
    if (userConfirmed) {
      // console.log("Confirmed");
      toast
        .promise(
          new Promise(async (resolve, reject) => {
            try {
              await dispatch(deleteCustomProduct(c_id));
              resolve();
            } catch (error) {
              reject(error);
            }
          }),
          {
            loading: "Deleting Product...",
            success: "Product deleted successfully!",
            error: "Error While Deleting Product",
          }
        )
        .then(() => {
          // console.log("Deleted Successfully!");
          navigate("/dashboard/custom-products");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // console.log("Process Completed");
        });
    } else {
      // console.log("Not Confirmed");
    }
  };

  const handleDeleteProduct = async (id) => {
    const userConfirmed = window.confirm("Are you sure want to delete?");
    if (userConfirmed) {
      // console.log("Confirmed");
      toast
        .promise(
          new Promise(async (resolve, reject) => {
            try {
              await dispatch(deleteProduct(id));
              resolve();
            } catch (error) {
              reject(error);
            }
          }),
          {
            loading: "Deleting Product...",
            success: `Product deleted successfully!`,
            error: "Error While Deleting Product",
          }
        )
        .finally(() => {
          // console.log("Process Completed");
        });
    } else {
      // console.log("Not Confirmed");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        minHeight: "220px",
      }}
    >
      <Topbar title={title} actions={actions} searchFunction={searchFunction} />
      {!isLoading ? (
        <Fade in={data}>
          <Grid container spacing={2}>
            {Array.isArray(data) &&
              data?.map((item, index) => (
                <Grid item xs={6} sm={6} md={4} lg={3} xl={2} key={index}>
                  <AllCard
                    data={item}
                    handleDelete={
                      item?.c_id
                        ? handleDeleteCustomProduct
                        : handleDeleteProduct
                    }
                    redirectUrl={redirectUrl}
                  />
                </Grid>
              ))}
          </Grid>
        </Fade>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "150px",
            width: "100%",
          }}
        >
          <Bars height={40} width={40} color="#66666a" />
        </Box>
      )}
    </Box>
  );
};

export default AllContainer;
