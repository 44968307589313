import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../slices/userCategorySlice";

import React, { useEffect } from "react";
import { Bars } from "react-loader-spinner";
import { getCategoriesWithoutParent } from "../../config/utils";
import { motion } from "framer-motion";
import menCategoryImage from "../../assets/images/category images/men-category-image.jpg";
import womenCategoryImage from "../../assets/images/category images/women-category-image.jpg";
import { useNavigate } from "react-router";

const CategoryContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userCategories, catLoading, catError } = useSelector(
    (state) => state.userCategoryState
  );

  const parentCategories = getCategoriesWithoutParent(userCategories);
  console.log(parentCategories);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  if (catLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Typography>Loading ...</Typography> */}
        {/* <CircularProgress/> */}
        <Bars
          height="45"
          width="45"
          color="gray"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </Box>
    );
  }
  if (catError) return <>Error</>;

  return (
    <Box sx={{ px: { xs: 2, md: 6, lg: 10 } }}>
      {/* <SideDrawer /> */}
      {/* <Grid container spacing={2}> */}
      {/* {parentCategories?.map((categoryData) => {
          return (
            <Grid
              key={categoryData.id}
              sx={{ justifyContent: "center" }}
              item
              xs={12}
              sm={4}
              md={3}
              lg={2}
            >
              <CategoryCard
                key={categoryData.id}
                btnSize="small"
                categoryData={categoryData}
              />
            </Grid>
          );
        })} */}
      <div className="w-full mb-5 flex items-center justify-center flex-col md:flex-row">
        <div
          className="w-full md:w-1/2 h-[300px] md:h-[500px] p-4 flex items-center justify-center bg-cover bg-center"
          style={{ backgroundImage: `url(${menCategoryImage})` }}
        >
          <motion.button
            initial={{}}
            whileHover={{ backgroundColor: "#000", color: "#fff" }}
            className="text-[5vw] md:text-[2vw] font-medium bg-white px-5 py-2 rounded-lg cursor-pointer"
            onClick={() => {
              navigate(
                "/category/subcategory/6d1735c4-61f0-4e05-bda4-f7dfc1ffdb7d"
              );
            }}
          >
            Men
          </motion.button>
        </div>
        <div
          className="w-full md:w-1/2 h-[300px] md:h-[500px] p-4 flex items-center justify-center bg-cover bg-top"
          style={{ backgroundImage: `url(${womenCategoryImage})` }}
        >
          <motion.button
            whileHover={{ backgroundColor: "#000", color: "#fff" }}
            className="text-[5vw] md:text-[2vw] font-medium bg-white px-5 py-2 rounded-lg cursor-pointer"
            onClick={() => {
              navigate(
                "/category/subcategory/5272b0d9-8871-4f1a-b8bf-f31d2e12e8ed"
              );
            }}
          >
            Women
          </motion.button>
        </div>
      </div>
      {/* </Grid> */}
    </Box>
  );
};

export default CategoryContainer;
