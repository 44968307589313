import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  buttonStyleMui,
  selectMuiStyle,
  textFieldStyle,
} from "../../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsCategoryUpdating,
  updateCategory,
} from "../../../slices/categoriesSlice";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";

const UpdateCategoryDialog = ({
  allCategories,
  open,
  setOpen,
  currentCategory,
}) => {
  const dispatch = useDispatch();
  const [parentCategoryValue, setParentCategoryValue] = useState("");
  const [isParentCategory, setIsParentCategory] = useState(true);
  const [newName, setNewName] = useState(null);
  const [newDesc, setNewDesc] = useState(null);
  const [currentCatId, setCurrentCatId] = useState(null);

  useEffect(() => {
    setNewName(currentCategory.name);
    setNewDesc(currentCategory.description);
    setCurrentCatId(currentCategory.id);
  }, [currentCategory]);

  const isCategoryUpdatingApi = useSelector(selectIsCategoryUpdating);

  const handleIsParentCategoryChange = (event) => {
    setIsParentCategory(event.target.checked);
  };

  useEffect(() => {
    if (allCategories && allCategories.data.length > 0) {
      setParentCategoryValue(allCategories.data[0].id);
    }
  }, [allCategories]);

  useEffect(() => {
    if (!isCategoryUpdatingApi && open) {
      setOpen(false);
    }
  }, [isCategoryUpdatingApi]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    if (!isCategoryUpdatingApi && open) {
      setOpen(false);
      return;
    }
  };

  // ↘️ START OF STEPWISE SELECTION OF PARENT CATEGORIES

  const [theMostParentCategories, setTheMostParentCategories] = useState([]);
  const [selectedMostParentCategory, setSelectedMostParentCategory] =
    useState("");
  const [secondOrderCategories, setSecondOrderCategories] = useState([]);
  const [selectedSecondOrderCategory, setSelectedSecondOrderCategory] =
    useState("");

  useEffect(() => {
    if (allCategories?.data) {
      const theMostParentCategoriesArray = allCategories.data.filter(
        (category) => category.parent_category_id === null
      );
      setTheMostParentCategories(theMostParentCategoriesArray);
    }
  }, [allCategories?.data]);

  const getAllSubCategories = (parentCategoryId) => {
    if (allCategories?.data) {
      return allCategories.data.filter(
        (category) => category.parent_category_id === parentCategoryId
      );
    }
    return [];
  };

  useEffect(() => {
    if (selectedMostParentCategory !== "") {
      const subCategories = getAllSubCategories(selectedMostParentCategory);
      setSecondOrderCategories(subCategories);
      setSelectedSecondOrderCategory(""); // Reset second order selection if first order changes
    }
  }, [selectedMostParentCategory]);

  // ↖️ END OF STEPWISE SELECTION OF PARENT CATEGORIES

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const parent_id = selectedSecondOrderCategory || selectedMostParentCategory;

            toast.promise(
              new Promise(async (resolve, reject) => {
                try {
                  await dispatch(
                    updateCategory({ data: { ...formJson, parent_id }, id: currentCatId })
                  );
                  resolve();
                } catch (error) {
                  reject(error);
                } finally {
                  handleClose();
                }
              }),
              {
                loading: "Updating Category...",
                success: "Category updated successfully!",
                error: "Error While Updating Category",
              }
            );
          },

          sx: { p: 1 },
        }}
      >
        <DialogTitle>Update Category</DialogTitle>
        <DialogContent sx={{ "&.MuiDialogContent-root": { p: 1 } }}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={handleIsParentCategoryChange}
                checked={isParentCategory}
              />
            }
            label="Make Parent Category"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="new_name"
            name="new_name"
            label="Update Category Name"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={textFieldStyle}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="new_desc"
            name="description"
            label="Category Description"
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            sx={textFieldStyle}
            value={newDesc}
            onChange={(e) => setNewDesc(e.target.value)}
          />
          {!isParentCategory && (
            <>
              <TextField
                value={selectedMostParentCategory}
                label="Select Parent Category"
                fullWidth
                select
                size="small"
                sx={textFieldStyle}
                onChange={(e) => {
                  setSelectedMostParentCategory(e.target.value);
                }}
              >
                {theMostParentCategories?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Typography>{item.name}</Typography>
                  </MenuItem>
                ))}
              </TextField>
              {selectedMostParentCategory !== "" &&
                secondOrderCategories.length > 0 && (
                  <TextField
                    value={selectedSecondOrderCategory}
                    label="Select Second Order Category"
                    fullWidth
                    select
                    size="small"
                    sx={textFieldStyle}
                    onChange={(e) => {
                      setSelectedSecondOrderCategory(e.target.value);
                    }}
                  >
                    {secondOrderCategories?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Typography>{item.name}</Typography>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            disableElevation
            disabled={isCategoryUpdatingApi}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disableElevation
            disabled={isCategoryUpdatingApi}
          >
            {isCategoryUpdatingApi ? (
              <Oval height={20} width={20} color="#0a5484" />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateCategoryDialog;
