import React, { useEffect } from "react";
import PNF from "../../assets/images/PNF.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  return (
    <div className="w-screen h-screen flex items-center justify-center flex-col">
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Page Not Found | US Sports</title>
        <meta
          name="Page Not Found"
          content="This is the 404 (Page Not Found) Page of the US Sports Website."
        />
        {/* <link rel="canonical" href="http://example.com/your-page" /> */}
      </Helmet>
      <img src={PNF} alt="Page Not Found Image" className="h-2/3" />
      <h3 className="text-[1.2vw]">
        Go Back To{" "}
        <Link to={"/"} className="text-[#f26728] underline">
          Home Page
        </Link>
      </h3>
    </div>
  );
};

export default PageNotFound;
