import { configureStore } from "@reduxjs/toolkit";
import canvasReducer from "../slices/canvasSlice";
import awayCanvasReducer from "../slices/awayCanvasSlice";
import adminCanvasReducer from "../slices/adminCanvasSlice";

import categoriesReducer from "../slices/categoriesSlice";
import productReducer from "../slices/productSlice";

// import { customProductsApi } from "./apiSlice";
import virtualCartReducer from "../slices/virtualCartSlice";
import tabIndex from "../slices/tabIndexSlice";
import userCategoryReducer from "../slices/userCategorySlice";
import usersAdminSlice from "../slices/usersAdminSlice";


const store = configureStore({
  reducer: {
    canvasState: canvasReducer,
    awayCanvasState: awayCanvasReducer,
    adminCanvasState: adminCanvasReducer,

    categoriesSlice: categoriesReducer,
    productSlice: productReducer,

//     [customProductsApi.reducerPath]: customProductsApi.reducer,
    virtualCart: virtualCartReducer,
    tabIndex,
    userCategoryState:userCategoryReducer,
    usersAdminState: usersAdminSlice

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
