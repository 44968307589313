import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import ProductDetails from "./ProductDetails";
import ProductTabs from "./ProductTabs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Bars } from "react-loader-spinner";
import { imageApiUrl } from "../../config/constants";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { fetchProduct } from "../../slices/userCategorySlice";
import RelatedProducts from "./RelatedProducts";

const SingleProductContainer = () => {
  const [categoryId, setCategoryId] = useState(null);
  const { productId } = useParams();
  const [isScrollable, setIsScrollable] = useState(false);

  const containerRef = useRef(null);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -300,
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 300,
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 986,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  const dispatch = useDispatch();
  const {
    userSingleProduct: product,
    userSingleProductLoading: loading,
    userSingleProductError: error,
  } = useSelector((state) => state.userCategoryState);

  useEffect(() => {
    if (product.category_id) {
      setCategoryId(product.category_id);
    }
  }, [product]);

  const productImages = product?.product_images;
  // const ProductImages = [...productImages, ...productImages];

  // Find the primary image
  const primaryImage = product?.product_images?.find(
    (image) => image.type === "primary"
  );

  // Filter Secondary images
  // const secondaryImages = product.product_images?.filter(
  //   (image) => image.type !== "primary"
  // );

  // console.log("secondaryImage", secondaryImages);

  // const [displayImageUrl, setDisplayImageUrl] = useState(
  //   primaryImage?.image_url
  // );

  useEffect(() => {
    dispatch(fetchProduct(productId));
  }, [dispatch, productId]);

  // For scrollable buttons.
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setIsScrollable(container.scrollWidth > container.clientWidth);
    }
  }, []);

  const handleImageHover = (event) => {
    const secondaryImages = document.querySelectorAll(".secondary-images");

    secondaryImages.forEach((image) => {
      image.style.border = "0px solid white";
    });

    event.target.style.border = "2px solid red";
    const otherImage = document.getElementById("main-image");
    otherImage.src = event.target.src;
  };
  console.log(categoryId);

  if (loading) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Typography>Loading ...</Typography> */}
        {/* <CircularProgress/> */}
        <Bars
          height="45"
          width="45"
          color="gray"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            color: "red",
            fontWeight: "700",
          }}
        >
          Error: {error}
        </Typography>
      </Box>
    );
  }

  if (!product) {
    return "No product";
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          mx: { xs: 2, md: 8 },
          bgcolor: "#fff",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "flex-start",
          justifyContent: "center",
          gap: { xs: "20px", sm: "30px", md: "50px" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "50%" },
          }}
        >
          {/* Primay Image */}
          <Box
            width="100%"
            sx={{
              maxWidth: "500px",
            }}
          >
            <img
              id="main-image"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                aspectRatio: "1/1",
              }}
              src={`${imageApiUrl}/uploads/${primaryImage?.image_url}`}
            />
          </Box>

          {/* Secondary Images */}
          <Box
            sx={{
              // bgcolor: "lightgray",
              width: "100%",
              maxWidth: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              p: "6px",
              gap: "8px",
              // bgcolor: "red",
              overflowX: "hidden", // Hide scrollbar
              position: "relative",
            }}
          >
            {isScrollable && (
              <IconButton
                disableFocusRipple
                disableRipple
                onClick={scrollLeft}
                sx={{
                  position: "absolute",
                  left: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                  color: "white",
                  bgcolor: "gray",
                }}
              >
                <NavigateBeforeIcon />
              </IconButton>
            )}

            <Box
              ref={containerRef}
              sx={{
                display: "flex",
                gap: "8px",
                overflowX: "auto",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {product?.product_images?.map((slideImage, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "100px",
                    height: "100px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    className="secondary-images"
                    style={{
                      width: "inherit",
                      height: "inherit",
                      objectFit: "contain",
                      border: index === 0 ? "2px solid red" : "0px solid white",
                      transition: "border-color 0.1s ease",
                    }}
                    src={`${imageApiUrl}/uploads/${slideImage?.image_url}`}
                    alt={`image-${index}`}
                    onClick={handleImageHover}
                    onMouseOut={(event) => {
                      if (
                        document.getElementById("main-image").src !==
                        event.target.src
                      ) {
                        event.target.style.border = "0px solid white";
                      }
                    }}
                  />
                </Box>
              ))}
            </Box>

            {isScrollable && (
              <IconButton
                disableFocusRipple
                disableRipple
                size="small"
                onClick={scrollRight}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                  color: "white",
                  bgcolor: "gray",
                }}
              >
                <NavigateNextIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        <ProductDetails />
      </Box>
      <ProductTabs />
      <RelatedProducts categoryId={categoryId} />
    </ThemeProvider>
  );
};

export default SingleProductContainer;
