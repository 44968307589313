import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

const Breadcrumb = ({
  items = [
    { name: "Category", link: "/category" },
    { name: "SubCategory", link: "/category" },
  ],
  currentTitle = "Current Page",
}) => {
  const navigate = useNavigate();
  const goTo = (url) => {
    navigate(url);
  };

  return (
    <Box
      sx={{
        minHeight: "60px",
        height: "fit-content",
        mx: { xs: 2, sm: 8 },
        my: 3,
        mt: { xs: 7, md: 0 },
      }}
      role="presentation"
    >
      <Breadcrumbs maxItems={3} aria-label="breadcrumb">
        <Link
          underline="hover"
          color="inherit"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            goTo("/");
          }}
        >
          Home
        </Link>

        {items.map((item) => (
          <Link
            key={item.link}
            onClick={(e) => {
              e.preventDefault();
              goTo(item.link);
            }}
            underline="hover"
            color="inherit"
            href="#"
          >
            {item.name}
          </Link>
        ))}
        <Typography color="text.primary">{currentTitle}</Typography>
      </Breadcrumbs>
    </Box>
  );
};

export default Breadcrumb;
