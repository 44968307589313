import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productPageTabIndex: 0,
};

const tabIndexSlice = createSlice({
  name: "tabIndex",
  initialState,
  reducers: {
    setProductPageTabIndex: (state, action) => {
      state.productPageTabIndex = action.payload;
    },
  },
});

export const selectProductPageTabIndex = state=>state.tabIndex.productPageTabIndex;

export const { setProductPageTabIndex } = tabIndexSlice.actions;

export default tabIndexSlice.reducer;
