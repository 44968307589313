import { motion } from "framer-motion";
import React from "react";
import { FaArrowUpLong } from "react-icons/fa6";
import bannerImg from "../../assets/images/1.webp"; 
function LandingPage() {
  return (
    <div
      className="relative w-full  md:h-screen mt-[15dvw] md:mt-0 md:pt-1 flex justify-between flex-col rounded-b-[2vw] bg-white z-[100] aspect-video bg-cover"
      style={{
        backgroundImage: `url(${bannerImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      {/* <div className="textStructure mt-[100px] md:mt-[14vw] px-[5vw]">
        {["We Manufacture", "High Quality", "Uniforms"].map((item, index) => {
          return (
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{
                ease: [0.22, 1, 0.36, 1],
                duration: 2,
                delay: index * 0.3,
              }}
              className="w-fit flex items-center"
            >
              <h1
                key={index}
                className="uppercase text-[40px] md:text-[8vw] lg:text-[6vw] leading-[12vw] md:leading-[6.5vw] tracking-tighter font-semibold"
              >
                {item}
              </h1>
            </motion.div>
          );
        })}
      </div> */}
      {/* <div className="border-t-2 border-zinc-700 py-[5dvw] mt-[20dvh] md:mt-0 flex flex-col md:flex-row justify-between items-center md:py-[1.2vw] px-[5vw] gap-[4dvw]">
        {["For Brands", "From Cutting to Packing"].map((item, index) => (
          <p
            key={index}
            className="font-light tracking-tight leading-none text-[8dvw] md:text-[1.4vw]"
          >
            {item}
          </p>
        ))}
        <div className="start flex items-center sm:flex-col md:flex-row gap-[1.7vw]">
          <div className="px-[2dvw] py-[1dvw] md:px-[1vw] md:py-[0.5vw] border-2 rounded-full border-zinc-500 font-light text-[6dvw] md:text-[1.1vw] uppercase">
            Book An Enquiry
          </div>
          <div className="w-[13dvw] h-[13dvw] md:w-[3vw]  md:h-[3vw] flex items-center justify-center rounded-full border-2 border-zinc-500">
            <span className="rotate-[45deg] text-[6dvw] md:text-[1.5vw]">
              <FaArrowUpLong />
            </span>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default LandingPage;
