import { Box } from "@mui/material";
import React from "react";
import { Outlet, useLocation } from "react-router";
import CustomProducts from "../../../components/Dashboard/CustomProducts/CustomProducts";

const CustomProduct = () => {
  const location = useLocation();

  return (
    <Box sx={{ width: "100%" }}>
      {location.pathname === "/dashboard/custom-products" ? (
        <CustomProducts />
      ) : (
        <Outlet />
      )}
    </Box>
  );
};

export default CustomProduct;
