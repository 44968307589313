import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Product from "../../../components/Dashboard/DashProduct/Product";
import { Outlet, useLocation } from "react-router";

const DashProduct = () => {
  const location = useLocation();
  return (
    <Box sx={{ width: "100%" }}>
      {location.pathname === "/dashboard/products" ? <Product /> : <Outlet />}
    </Box>
  );
};

export default DashProduct;
