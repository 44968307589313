import React from "react";
import CreateProductAdmin from "../../components/CreateProductAdmin/CreateProductAdmin";

const AdminCreateProduct = () => {
  return (
    <>
      <CreateProductAdmin />
    </>
  );
};

export default AdminCreateProduct;
