import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Breadcrumb from "../../components/Category/Breadcrumb";
import Footer from "../../components/LandingPage/Footer/Footer";
import ProductContainer from "../../components/Product/ProductContainer";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getRelatedCategoriesNameAndLink } from "../../config/utils";
import Navbar from "../../components/AnimatedLandingPage/Navbar";
import { Helmet } from "react-helmet";

const Product = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const { userCategories } = useSelector((state) => state.userCategoryState);

  const items = getRelatedCategoriesNameAndLink(userCategories, categoryId);
  // console.log(items);

  // useEffect(() => {
  //   window.addEventListener("popstate", function (e) {
  //     e.preventDefault();
  //     navigate("/");
  //     console.log(e);
  //   });
  //   window.removeEventListener("popstate", function (e) {
  //     e.preventDefault();
  //     navigate("/");
  //     console.log(e);
  //   });
  // }, []);
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Products | US Sports</title>
      </Helmet>
      {/* <Header topbar2={true} /> */}
      <Navbar />
      <Breadcrumb
        items={[{ name: "Categories", link: "/category" }, ...items]}
        currentTitle="Products"
      />
      {/* <SubcategoryHeader subcategory={true} /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          px: { xs: 2, md: 6, lg: 10 },
          minHeight: "30vh",
        }}
      >
        <ProductContainer />
      </Box>
      <Footer />
    </>
  );
};

export default Product;
