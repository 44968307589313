import React from "react";
import SubcategoryContainer from "./SubcategoryContainer";
import ProductContainer from "../Product/ProductContainer";
import { Box, Divider } from "@mui/material";


function SubCategoryAndProductCategoryContainer() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        px: { xs: 2, md: 6, lg: 10 },
        minHeight: "30vh",
      }}
    >
      <SubcategoryContainer />
      {/* <Divider/> */}
      <ProductContainer label={true}/>
    </Box>
  );
}

export default SubCategoryAndProductCategoryContainer;
