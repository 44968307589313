import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../slices/userCategorySlice";

import React, { useEffect } from "react";
import CategoryCard from "../Common/CategoryCard";
import { Bars } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router";
import { getCategoriesByParentId } from "../../config/utils";

const SubcategoryContainer = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const dispatch = useDispatch();

  const { userCategories, catLoading, catError } = useSelector(
    (state) => state.userCategoryState
  );

  const subcategories = getCategoriesByParentId(userCategories, categoryId);

  // console.log(catLoading, catError, userSubCategories);

  useEffect(() => {
    if (userCategories.length === 0) dispatch(fetchCategories());
  }, [dispatch, navigate]);

  if (catLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Typography>Loading ...</Typography> */}
        {/* <CircularProgress/> */}
        <Bars
          height="45"
          width="45"
          color="gray"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </Box>
    );
  }
  if (catError) return <>Error</>;

  if (subcategories.length === 0) navigate(`/products/${categoryId}`);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {/* <SideDrawer /> */}
      <Typography sx={{ fontWeight: "700", fontSize: "24px" }}>
        Sub Categories
      </Typography>
      <Grid container spacing={2}>
        {subcategories?.map((categoryData) => {
          return (
            <Grid key={categoryData.id} item xs={12} sm={4} md={3} lg={2}>
              <CategoryCard btnSize="small" categoryData={categoryData} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default SubcategoryContainer;
