import React from "react";
import AnimatedLandingPageComponent from "../../components/AnimatedLandingPage/AnimatedLandingPage";
import { Helmet } from "react-helmet";
const AnimatedLandingPage = () => {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Home | US Sports</title>
      </Helmet>
      <AnimatedLandingPageComponent />
    </>
  );
};

export default AnimatedLandingPage;
