import { Box, Button } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CanvasEditor from "../Canvas/CanvasEditor";
import {
  Group,
  Image,
  Layer,
  Path,
  Stage,
  Text,
  Transformer,
} from "react-konva";

import {
  changeColor,
  changeLogoDimensions,
  changeLogoPosition,
  changePosition,
  changeText,
  deleteLogo,
  exportCanvas,
  exportCanvasAsync,
  selectBackCanvasExportImage,
  selectBackCanvasRef,
  selectBackMaskImage,
  selectBackPaths,
  selectBackTextArray,
  selectColors,
  selectCustomizeTabIndex,
  selectFrontCanvasExportImage,
  selectFrontCanvasRef,
  selectFrontMaskImage,
  selectFrontPaths,
  selectFrontTextArray,
  selectUserBackLogo,
  selectUserFrontLogo,
  updateStageRef,
} from "../../slices/canvasSlice";
import {
  changeAwaygeColor,
  changeAwayLogoDimensions,
  changeAwayLogoPosition,
  changeAwayPosition,
  changeAwayText,
  exportAwayCanvas,
  selectAwayBackCanvasRef,
  selectAwayBackMaskImage,
  selectAwayBackPaths,
  selectAwayBackTextArray,
  selectAwayColors,
  selectAwayCustomizeTabIndex,
  selectAwayFrontCanvasRef,
  selectAwayFrontMaskImage,
  selectAwayFrontPaths,
  selectAwayFrontTextArray,
  selectAwayUserBackLogo,
  selectAwayUserFrontLogo,
  selectIsAwayActive,
  updateAwayStageRef,
} from "../../slices/awayCanvasSlice";

import useImage from "use-image";
import { clipFunction } from "../../utils/clipFunction";

const Canvas = ({ isFrontView, setIsFrontView }) => {
  const dispatch = useDispatch();

  const [selectedImageBack, setSelectedImageBack] = useState(null);
  const [selectedImageFront, setSelectedImageFront] = useState(null);

  const isAwayActive = useSelector(selectIsAwayActive);

  //.................................................................

  const stage1Ref = useRef(null);
  const userFrontLogoRef = useRef(null);
  const userFrontLogoTransformerRef = useRef(null);

  const dragBoundFunctionLogoFront = (pos) => {
    const stageWidth = stage1Ref.current.width();
    const stageHeight = stage1Ref.current.height();
    const nodeWidth = userFrontLogoRef.current.width();
    const nodeHeight = userFrontLogoRef.current.height();

    let newX = pos.x;
    let newY = pos.y;

    // Bound the drag movement within the stage
    if (newX < 0) {
      newX = 0;
    }
    if (newY < 0) {
      newY = 0;
    }
    if (newX + nodeWidth > stageWidth) {
      newX = stageWidth - nodeWidth;
    }
    if (newY + nodeHeight > stageHeight) {
      newY = stageHeight - nodeHeight;
    }

    return {
      x: newX,
      y: newY,
    };
  };

  const stage2Ref = useRef(null);

  const dragBoundFunctionLogoBack = (pos) => {
    const stageWidth = stage2Ref.current.width();
    const stageHeight = stage2Ref.current.height();
    const nodeWidth = userBackLogoRef.current.width();
    const nodeHeight = userBackLogoRef.current.height();

    let newX = pos.x;
    let newY = pos.y;

    // Bound the drag movement within the stage
    if (newX < 0) {
      newX = 0;
    }
    if (newY < 0) {
      newY = 0;
    }
    if (newX + nodeWidth > stageWidth) {
      newX = stageWidth - nodeWidth;
    }
    if (newY + nodeHeight > stageHeight) {
      newY = stageHeight - nodeHeight;
    }

    return {
      x: newX,
      y: newY,
    };
  };

  // useEffect(()=>{
  //   dispatch(updateStageRef({canvasRef: stage1Ref, refStateName: "frontStageRef"}))
  //   dispatch(updateStageRef({canvasRef: stage2Ref, refStateName: "backStageRef"}))
  // },[stage1Ref, stage2Ref])

  //.... For Away Variant
  const awayStage1Ref = useRef(null);
  const awayStage2Ref = useRef(null);
  //................................................................

  //... For Away Variant
  const awayUserFrontLogoRef = useRef(null);
  const awayUserFrontLogoTransformerRef = useRef(null);
  //................................................................

  const userBackLogoRef = useRef(null);
  const userBackLogoTransformerRef = useRef(null);

  //... For Away Variant
  const awayUserBackLogoRef = useRef(null);
  const awayUserBackLogoTransformerRef = useRef(null);
  //................................................................

  const colors = useSelector(selectColors);
  const frontPathsArr = useSelector(selectFrontPaths);
  const backPathsArr = useSelector(selectBackPaths);
  const frontMaskImageObject = useSelector(selectFrontMaskImage);
  const backMaskImageObject = useSelector(selectBackMaskImage);
  const frontTextArray = useSelector(selectFrontTextArray);
  const backTextArray = useSelector(selectBackTextArray);
  const userFrontLogo = useSelector(selectUserFrontLogo);
  const userBackLogo = useSelector(selectUserBackLogo);
  const frontCanvasImage = useSelector(selectFrontCanvasExportImage);
  const backCanvasImage = useSelector(selectBackCanvasExportImage);

  const awayColors = useSelector(selectAwayColors);
  const awayFrontPathsArr = useSelector(selectAwayFrontPaths);
  const awayBackPathsArr = useSelector(selectAwayBackPaths);
  const awayFrontMaskImageObject = useSelector(selectAwayFrontMaskImage);
  const awayBackMaskImageObject = useSelector(selectAwayBackMaskImage);
  const awayFrontTextArray = useSelector(selectAwayFrontTextArray);
  const awayBackTextArray = useSelector(selectAwayBackTextArray);
  const awayUserFrontLogo = useSelector(selectAwayUserFrontLogo);
  const awayUserBackLogo = useSelector(selectAwayUserBackLogo);

  //...............................................................
  const [frontMaskImage] = useImage(
    `${process.env.REACT_APP_API_URL_LOCAL}/uploads/${frontMaskImageObject?.src}`
  );

  const [backMaskImage] = useImage(
    `${process.env.REACT_APP_API_URL_LOCAL}/uploads/${backMaskImageObject?.src}`
  );

  // for Away variant ....
  const [awayFrontMaskImage] = useImage(awayFrontMaskImageObject.src);
  const [awayBackMaskImage] = useImage(awayBackMaskImageObject.src);
  //...............................................................

  const frontStageRef = useSelector(selectFrontCanvasRef);
  const backStageRef = useSelector(selectBackCanvasRef);

  const customizeTabIndex = useSelector(selectCustomizeTabIndex);

  useEffect(() => {
    if (
      userFrontLogoRef.current &&
      userFrontLogoTransformerRef &&
      selectedImageFront === userFrontLogoRef.current
    ) {
      userFrontLogoTransformerRef.current.nodes([userFrontLogoRef.current]);
      if (selectedImageFront === userFrontLogoRef.current) {
        userFrontLogoTransformerRef.current.getLayer().batchDraw();
      }
    }
  }, [userFrontLogoRef, userFrontLogoTransformerRef, selectedImageFront]);

  useEffect(() => {
    if (
      userBackLogoRef.current &&
      userBackLogoTransformerRef &&
      selectedImageBack === userBackLogoRef.current
    ) {
      userBackLogoTransformerRef.current.nodes([userBackLogoRef.current]);
      if (selectedImageBack === userBackLogoRef.current) {
        userBackLogoTransformerRef.current.getLayer().batchDraw();
      }
    }
  }, [userBackLogoRef, userBackLogoTransformerRef, selectedImageBack]);

  //... for away variant ...
  // useEffect(() => {
  //   if (
  //     awayUserFrontLogoRef.current &&
  //     awayUserFrontLogoTransformerRef &&
  //     selectedImageFront === awayUserFrontLogoRef.current
  //   ) {
  //     awayUserFrontLogoTransformerRef.current.nodes([
  //       awayUserFrontLogoRef.current,
  //     ]);
  //     if (selectedImageFront === awayUserFrontLogoRef.current) {
  //       awayUserFrontLogoTransformerRef.current.getLayer().batchDraw();
  //     }
  //   }
  // }, [
  //   awayUserFrontLogoRef,
  //   awayUserFrontLogoTransformerRef,
  //   selectedImageFront,
  // ]);

  // useEffect(() => {
  //   if (
  //     awayUserBackLogoRef.current &&
  //     awayUserBackLogoTransformerRef &&
  //     selectedImageBack === awayUserBackLogoRef.current
  //   ) {
  //     userBackLogoTransformerRef.current.nodes([awayUserBackLogoRef.current]);
  //     if (selectedImageBack === awayUserBackLogoRef.current) {
  //       awayUserBackLogoTransformerRef.current.getLayer().batchDraw();
  //     }
  //   }
  // }, [awayUserBackLogoRef, awayUserBackLogoTransformerRef, selectedImageBack]);

  useEffect(() => {
    if (customizeTabIndex === "roster") {
      // console.log("exporting canvas ...");
      dispatch(
        exportCanvas({ stageRef: stage1Ref, downloadName: "canvas-front" })
      );
      dispatch(
        exportCanvas({ stageRef: stage2Ref, downloadName: "canvas-back" })
      );
    }
  }, [customizeTabIndex]);

  const handleSelectFrontImage = (e) => {
    if (e.target.getClassName() === "Image") {
      setSelectedImageFront(e.target);
    }
  };

  const handleDeselectFrontImage = (event) => {
    const clickedOnImage = event.target === userFrontLogoRef.current;
    if (!clickedOnImage) {
      setSelectedImageFront(null);
    }
  };

  const handleSelectBackImage = (e) => {
    if (e.target.getClassName() === "Image") {
      setSelectedImageBack(e.target);
    }
  };

  const handleDeselectBackImage = (event) => {
    const clickedOnImage = event.target === userBackLogoRef.current;
    if (!clickedOnImage) {
      setSelectedImageBack(null);
    }
  };

  const deleteElement = (e) => {
    // console.log(e.target);
    e.target.destroy();
  };

  const handleDeleteKeyPress = (event) => {
    if (
      (event.key === "Delete" || event.key === "Backspace") &&
      selectedImageFront
    ) {
      // Handle deletion based on the selected image
      // console.log("Delete key pressed front");
      setSelectedImageFront(null); // Clear selected image after deletion
      dispatch(deleteLogo({ logoLocation: "front" }));
    }
    if (
      (event.key === "Delete" || event.key === "Backspace") &&
      selectedImageBack
    ) {
      // Handle deletion based on the selected image
      // console.log("Delete key pressed back");
      setSelectedImageBack(null); // Clear selected image after deletion
      dispatch(deleteLogo({ logoLocation: "back" }));
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleDeleteKeyPress);
    return () => {
      document.removeEventListener("keydown", handleDeleteKeyPress);
    };
  }, [selectedImageFront, selectedImageBack]);

  function getBoundBox(oldBox, newBox) {
    const minX = Math.min(newBox.x, oldBox.x);
    const minY = Math.min(newBox.y, oldBox.y);
    const maxX = Math.max(newBox.x + newBox.width, oldBox.x + oldBox.width);
    const maxY = Math.max(newBox.y + newBox.height, oldBox.y + oldBox.height);

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };
  }

  function getTotalBox(boxes) {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    boxes.forEach((box) => {
      minX = Math.min(minX, box.x);
      minY = Math.min(minY, box.y);
      maxX = Math.max(maxX, box.x + box.width);
      maxY = Math.max(maxY, box.y + box.height);
    });

    return {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };
  }

  return (
    <Box
      sx={{
        position: "relative",
        height: "fit-content",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* Front and Back View Buttons */}
      <Box
        sx={{
          position: "absolute",
          display: customizeTabIndex !== "roster" ? "flex" : "none",
          flexDirection: "column",
          top: "16px",
          right: "16px",
          zIndex: "200",
          // bgcolor: "red",
          gap: "6px",
          cursor: "pointer",
        }}
      >
        <Button
          onClick={() => {
            setIsFrontView(true);
          }}
          disableRipple
          variant="text"
          sx={{
            width: { xs: "78px", lg: "92px" },
            height: { xs: "78px", lg: "92px" },
            border: isFrontView ? "#040707 solid 1px" : "lightgray solid 1px",
            borderRadius: "4px",
            zIndex: "200",
            color: "#040707",
            "&:hover": {
              border: "#f26728 solid 1px",
              color: "#f26728",
              backgroundColor: "rgba(242, 103, 40, 0.1)",
            },
          }}
        >
          Front Side
        </Button>

        <Button
          onClick={() => {
            setIsFrontView(false);
          }}
          variant="text"
          disableRipple
          sx={{
            width: { xs: "78px", lg: "92px" },
            height: { xs: "78px", lg: "92px" },
            border: !isFrontView ? "#040707 solid 1px" : "lightgray solid 1px",
            borderRadius: "4px",
            zIndex: "200",
            color: "#040707",
            "&:hover": {
              border: "#f26728 solid 1px",
              color: "#f26728",
              backgroundColor: "rgba(242, 103, 40, 0.1)",
            },
          }}
        >
          Back Side
        </Button>
      </Box>

      {/* Main Product Design Canvas .... */}
      <Box sx={{ display: !(customizeTabIndex === "roster") ? "block" : "none" }}>
        {/* Home Variant */}
        <Box
          sx={{
            display: !isAwayActive ? "flex" : "none",
          }}
        >
          {/* Front Side */}
          <Box
            sx={{
              height: "fit-content",
              display: isFrontView ? "flex" : "none",
            }}
          >
            <Stage
              width={600}
              height={600}
              onClick={
                isFrontView ? handleDeselectFrontImage : handleDeselectBackImage
              }
              ref={stage1Ref}
            >
              <Layer className="front-layer">
                <Group>
                  {frontPathsArr?.map((pathObj) => {
                    // console.log(pathObj);
                    return <Path data={pathObj.path} fill={pathObj?.color} />;
                  })}
                </Group>
                <Group>
                  <Image image={frontMaskImage} />
                </Group>
                <Group>
                  {frontTextArray?.map((textObj) => {
                    // console.log(textObj);
                    return (
                      <Text
                        text={textObj.text}
                        x={textObj.x}
                        y={textObj.y}
                        fill={textObj.color}
                        draggable={textObj.draggable}
                        height={textObj.height}
                        width={textObj.width}
                        fontSize={textObj.fontSize}
                        align={textObj.align}
                        stroke={textObj.stroke}
                        strokeWidth={textObj.strokeWidth}
                        fontFamily={textObj.fontFamily}
                        onDragEnd={(e) => {
                          // console.log(e.target.attrs.x);
                          dispatch(
                            changePosition({
                              textArr: "frontTextArray",
                              targetElementId: textObj.id,
                              newX: e.target.attrs.x,
                              newY: e.target.attrs.y,
                            })
                          );
                        }}
                      />
                    );
                  })}
                </Group>
                <Group>
                  {userFrontLogo.logo && (
                    <Image
                      ref={userFrontLogoRef}
                      image={userFrontLogo.logo}
                      x={userFrontLogo.x}
                      y={userFrontLogo.y}
                      draggable={true}
                      dragBoundFunc={dragBoundFunctionLogoFront}
                      width={userFrontLogo.width}
                      height={userFrontLogo.height}
                      onClick={handleSelectFrontImage}
                      onDragEnd={(e) => {
                        // console.log(e.target.attrs);
                        dispatch(
                          changeLogoPosition({
                            logoStateName: "userFrontLogo",
                            newX: e.target.attrs.x,
                            newY: e.target.attrs.y,
                          })
                        );
                      }}
                      onTransformEnd={(e) => {
                        const node = e.target;
                        const scaleX = node.attrs.scaleX;
                        const scaleY = node.attrs.scaleY;

                        // Reset scale if it has changed due to rotation
                        if (scaleX !== 1 || scaleY !== 1) {
                          node.setAttrs({
                            scaleX: 1,
                            scaleY: 1,
                          });
                          node.getLayer().batchDraw();
                        }

                        // Update dimensions based on original width and height (assuming they don't change)
                        const newWidth = userFrontLogo.width * scaleX;
                        const newHeight = userFrontLogo.height * scaleY;
                        dispatch(
                          changeLogoDimensions({
                            logoStateName: "userFrontLogo",
                            newW: newWidth,
                            newH: newHeight,
                          })
                        );
                      }}
                    />
                  )}
                  {selectedImageFront === userFrontLogoRef.current && (
                    <Transformer
                      rotateEnabled
                      resizeEnabled
                      ref={userFrontLogoTransformerRef}
                      // rotationDeg={{ x: 0, y: 0 }}
                    />
                  )}
                </Group>
              </Layer>
            </Stage>
          </Box>

          {/* Back Side */}
          <Box
            sx={{
              height: "fit-content",
              display: !isFrontView ? "flex" : "none",
            }}
          >
            <Stage
              width={600}
              height={600}
              onClick={
                isFrontView ? handleDeselectFrontImage : handleDeselectBackImage
              }
              ref={stage2Ref}
            >
              <Layer className="back-layer">
                <Group>
                  {backPathsArr?.map((pathObj) => {
                    // console.log(pathObj);
                    return <Path data={pathObj.path} fill={pathObj.color} />;
                  })}
                </Group>
                <Group>
                  <Image image={backMaskImage} />
                </Group>
                <Group>
                  {backTextArray?.map((textObj) => {
                    // console.log(textObj);
                    return (
                      <Text
                        text={textObj.text}
                        x={textObj.x}
                        y={textObj.y}
                        fill={textObj.color}
                        draggable={textObj.draggable}
                        height={textObj.height}
                        width={textObj.width}
                        fontSize={textObj.fontSize}
                        align={textObj.align}
                        stroke={textObj.stroke}
                        strokeWidth={textObj.strokeWidth}
                        fontFamily={textObj.fontFamily}
                      />
                    );
                  })}
                </Group>
                <Group>
                  {userBackLogo.logo && (
                    <Image
                      ref={userBackLogoRef}
                      image={userBackLogo.logo}
                      x={userBackLogo.x}
                      y={userBackLogo.y}
                      draggable={true}
                      dragBoundFunc={dragBoundFunctionLogoBack}
                      width={userBackLogo.width}
                      height={userBackLogo.height}
                      onClick={handleSelectBackImage}
                      onDragEnd={(e) => {
                        // console.log(e.target.attrs);
                        dispatch(
                          changeLogoPosition({
                            logoStateName: "userBackLogo",
                            newX: e.target.attrs.x,
                            newY: e.target.attrs.y,
                          })
                        );
                      }}
                      onTransformEnd={(e) => {
                        const node = e.target;
                        const scaleX = node.attrs.scaleX;
                        const scaleY = node.attrs.scaleY;

                        // Reset scale if it has changed due to rotation
                        if (scaleX !== 1 || scaleY !== 1) {
                          node.setAttrs({
                            scaleX: 1,
                            scaleY: 1,
                          });
                          node.getLayer().batchDraw();
                        }

                        // Update dimensions based on original width and height (assuming they don't change)
                        const newWidth = userBackLogo.width * scaleX;
                        const newHeight = userBackLogo.height * scaleY;
                        dispatch(
                          changeLogoDimensions({
                            logoStateName: "userBackLogo",
                            newW: newWidth,
                            newH: newHeight,
                          })
                        );
                      }}
                    />
                  )}
                  {selectedImageBack === userBackLogoRef.current && (
                    <Transformer
                      rotateEnabled
                      resizeEnabled
                      ref={userBackLogoTransformerRef}
                    />
                  )}
                </Group>
              </Layer>
            </Stage>
          </Box>
        </Box>

        {/* Away Variant */}
        <Box
          sx={{
            display: isAwayActive ? "flex" : "none",
          }}
        >
          {/* Front Side */}
          <Box
            sx={{
              height: "fit-content",
              display: isFrontView ? "flex" : "none",
            }}
          >
            <Stage
              width={600}
              height={600}
              onClick={
                isFrontView ? handleDeselectFrontImage : handleDeselectBackImage
              }
              ref={awayStage1Ref}
            >
              <Layer className="front-layer">
                <Group>
                  {awayFrontPathsArr?.map((pathObj) => {
                    // console.log(pathObj);
                    return <Path data={pathObj.path} fill={pathObj.color} />;
                  })}
                </Group>
                <Group>
                  <Image image={awayFrontMaskImage} />
                </Group>
                <Group>
                  {awayFrontTextArray?.map((textObj) => {
                    // console.log(textObj);
                    return (
                      <Text
                        text={textObj.text}
                        x={textObj.x}
                        y={textObj.y}
                        fill={textObj.color}
                        draggable={textObj.draggable}
                        height={textObj.height}
                        width={textObj.width}
                        fontSize={textObj.fontSize}
                        align={textObj.align}
                        stroke={textObj.stroke}
                        strokeWidth={textObj.strokeWidth}
                        fontFamily={textObj.fontFamily}
                        onDragEnd={(e) => {
                          // console.log(e.target.attrs.x);
                          dispatch(
                            changeAwayPosition({
                              textArr: "frontTextArray",
                              targetElementId: textObj.id,
                              newX: e.target.attrs.x,
                              newY: e.target.attrs.y,
                            })
                          );
                        }}
                      />
                    );
                  })}
                </Group>
                <Group>
                  {awayUserFrontLogo.logo && (
                    <Image
                      ref={awayUserFrontLogoRef}
                      image={awayUserFrontLogo.logo}
                      x={awayUserFrontLogo.x}
                      y={awayUserFrontLogo.y}
                      draggable
                      width={awayUserFrontLogo.width}
                      height={awayUserFrontLogo.height}
                      onClick={handleSelectFrontImage} //TODO::
                      onDragEnd={(e) => {
                        // console.log(e.target.attrs);
                        dispatch(
                          changeLogoPosition({
                            logoStateName: "userFrontLogo",
                            newX: e.target.attrs.x,
                            newY: e.target.attrs.y,
                          })
                        );
                      }}
                      onTransform={(e) => {
                        const node = e.target;
                        const scaleX = node.attrs.scaleX;
                        const scaleY = node.attrs.scaleY;

                        // Calculate new width and height
                        const newWidth = node.width() * scaleX;
                        const newHeight = node.height() * scaleY;
                        // console.log(newWidth, newHeight);
                        dispatch(
                          changeAwayLogoDimensions({
                            logoStateName: "userFrontLogo",
                            newW: newWidth,
                            newH: newHeight,
                          })
                        );
                      }}
                    />
                  )}
                  {selectedImageFront === awayUserFrontLogoRef.current && ( //TODO::
                    <Transformer
                      rotateEnabled
                      resizeEnabled
                      ref={awayUserFrontLogoTransformerRef}
                    />
                  )}
                </Group>
              </Layer>
            </Stage>
          </Box>

          {/* Back Side */}
          <Box
            sx={{
              height: "fit-content",
              display: !isFrontView ? "flex" : "none",
            }}
          >
            <Stage
              width={600}
              height={600}
              onClick={
                isFrontView ? handleDeselectFrontImage : handleDeselectBackImage
              }
              ref={awayStage2Ref}
            >
              <Layer className="back-layer">
                <Group>
                  {awayBackPathsArr?.map((pathObj) => {
                    // console.log(pathObj);
                    return <Path data={pathObj.path} fill={pathObj.color} />;
                  })}
                </Group>
                <Group>
                  <Image image={awayBackMaskImage} />
                </Group>
                <Group>
                  {awayBackTextArray?.map((textObj) => {
                    // console.log(textObj);
                    return (
                      <Text
                        text={textObj.text}
                        x={textObj.x}
                        y={textObj.y}
                        fill={textObj.color}
                        draggable={textObj.draggable}
                        height={textObj.height}
                        width={textObj.width}
                        fontSize={textObj.fontSize}
                        align={textObj.align}
                        stroke={textObj.stroke}
                        strokeWidth={textObj.strokeWidth}
                        fontFamily={textObj.fontFamily}
                      />
                    );
                  })}
                </Group>
                <Group>
                  {awayUserBackLogo.logo && (
                    <Image
                      ref={awayUserBackLogoRef}
                      image={awayUserBackLogo.logo}
                      x={awayUserBackLogo.x}
                      y={awayUserBackLogo.y}
                      draggable
                      width={awayUserBackLogo.width}
                      height={awayUserBackLogo.height}
                      onClick={handleSelectBackImage}
                      onDragEnd={(e) => {
                        // console.log(e.target.attrs);
                        dispatch(
                          changeLogoPosition({
                            logoStateName: "userBackLogo",
                            newX: e.target.attrs.x,
                            newY: e.target.attrs.y,
                          })
                        );
                      }}
                      onTransform={(e) => {
                        const node = e.target;
                        const scaleX = node.attrs.scaleX;
                        const scaleY = node.attrs.scaleY;

                        // Calculate new width and height
                        const newWidth = node.width() * scaleX;
                        const newHeight = node.height() * scaleY;
                        // console.log(newWidth, newHeight);
                        // console.log(userFrontLogo.width);
                        dispatch(
                          changeAwayLogoDimensions({
                            logoStateName: "userBackLogo",
                            newW: newWidth,
                            newH: newHeight,
                          })
                        );
                      }}
                    />
                  )}
                  {selectedImageBack === awayUserBackLogoRef.current && (
                    <Transformer
                      rotateEnabled
                      resizeEnabled
                      ref={awayUserBackLogoTransformerRef}
                    />
                  )}
                </Group>
              </Layer>
            </Stage>
          </Box>
        </Box>
      </Box>

      {/* 
      <Box
        sx={{
          width: customizeTabIndex === 3 ? "100%" : "50%",
          height: "fit-content",
          // bgcolor: "lightseagreen",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <CanvasEditor setIsFrontView={setIsFrontView} frontView={isFrontView} /> */}

      {/* <button
          onClick={() =>
            dispatch(
              exportCanvas({
                stageRef: stage1Ref,
                downloadName: "canvas-front",
              })
            )
          }
        >
          Export canvas 01
        </button>

        <button
          onClick={() =>
            dispatch(
              exportCanvas({ stageRef: stage2Ref, downloadName: "canvas-back" })
            )
          }
        >
          Export canvas 02
        </button> */}

      {/* <button
          onClick={() => {
            dispatch(changeColor({ path: "baseSvg", newColor: "pink" }));
          }}
        >
          Change Base Color
        </button>
        <button onClick={() => setIsFrontView(!isFrontView)}>
          Change View
        </button>
        <button onClick={handleDeleteButtonPressed}>Delete Button</button>
      </Box> */}
    </Box>
  );
};

export default Canvas;
