import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ProductCard from "../Common/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../slices/userCategorySlice";

const RelatedProducts = ({ categoryId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (categoryId !== "") {
      dispatch(fetchProducts(categoryId));
    }
  }, [categoryId]);

  const { userProducts, prodLoading, prodError } = useSelector(
    (state) => state.userCategoryState
  );

  const relatedProducts = userProducts?.all_products;
  return (
    <Box sx={{ mx: { xs: 1, md: 8 }, my: 4 }}>
      <Typography variant="h6" sx={{ pb: 3 }}>
        Related Products
      </Typography>
      <Box>
        <Grid container spacing={4}>
          {Array.isArray(relatedProducts) ? (
            relatedProducts?.map((product) => (
              <Grid item xs={12} sm={4} md={3} lg={2} key={product.id}>
                <ProductCard productData={product} />
              </Grid>
            ))
          ) : (
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <Typography sx={{ fontStyle: "italic" }}>
                No Products Found
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default RelatedProducts;
