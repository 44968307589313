import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { productData } from "../config/productData";
import { fontStyles } from "../config/constants";
import { dataURLtoBlob } from "../utils/dataUrlToBlob";
import axios from "axios";

export const getCustomProductById = createAsyncThunk(
  "canvasState/getCustomProductById",
  async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_LOCAL}/custom-product/${id}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
// export const exportCanvasAsync = createAsyncThunk(
//   "canvasState/exportCanvasAsync",
//   async ({ dataUrl, downloadName }) => {
//     try {
//       // const { stageRef, downloadName } = action.payload;
//       // const dataUrl = stageRef.current.toDataURL();
//       return { downloadName, dataUrl };
//     } catch (error) {
//       throw error;
//     }
//   }
// );

const canvasSlice = createSlice({
  name: "canvasState",
  initialState: {
    colorsArray: [],
    isCustomProductLoading: false,
    customProduct: {},
    fontStyles: fontStyles,
    colors: productData.colors,
    frontPathsArr: null,
    backPathsArr: null,
    frontMaskImage: null,
    backMaskImage: null,
    frontTextArray: null,
    backTextArray: null,
    userFrontLogo: {
      logo: null,
      x: 150,
      y: 200,
      title: "frontLogo",
      height: 220,
      width: 220,
    },
    userBackLogo: {
      logo: null,
      x: 150,
      y: 200,
      title: "backLogo",
      height: 220,
      width: 220,
    },
    frontCanvasExportImage: null,
    backCanvasExportImage: null,
    frontCanvasBlob: null,
    backCanvasBlob: null,
    frontStageRef: null,
    backStageRef: null,

    customizeTabIndex: "palette",

    // Roster State
    productRoster: [],
    rosterFile: null,
  },
  reducers: {
    changeColorsArray: (state, action) => {
      state.colorsArray = action.payload;
    },
    changeColor: (state, action) => {
      const { targetElements, newColor, applyTo } = action.payload;

      if (applyTo === "front") {
        state.frontPathsArr = state.frontPathsArr.map((pathObj) => {
          if (targetElements === pathObj.title) {
            return { ...pathObj, color: newColor };
          }
          return pathObj;
        });
      }
      if (applyTo === "back") {
        state.backPathsArr = state.backPathsArr.map((pathObj) => {
          if (targetElements === pathObj.title) {
            return { ...pathObj, color: newColor };
          }
          return pathObj;
        });
      }

      // state.frontPathsArr = state.colors.map((colorObj) => {
      //   if (targetElements.includes(colorObj.title)) {
      //     // If the title matches one of the target elements, update the fill color
      //     return { ...colorObj, fill: newColor };
      //   }
      //   // If the title doesn't match, leave the object unchanged
      //   return colorObj;
      // });
    },
    changeText: (state, action) => {
      const { targetElements, newText } = action.payload;

      if (Array.isArray(state.frontTextArray)) {
        state.frontTextArray = state.frontTextArray.map((textObj) => {
          if (targetElements === textObj.title) {
            return { ...textObj, text: newText };
          }
          return textObj;
        });
      }
      if (Array.isArray(state.backTextArray)) {
        state.backTextArray = state.backTextArray.map((textObj) => {
          if (targetElements === textObj.title) {
            return { ...textObj, text: newText };
          }
          return textObj;
        });
      }
    },
    changeTextFill: (state, action) => {
      const { targetElements, newColor } = action.payload;
      if (Array.isArray(state.frontTextArray)) {
        state.frontTextArray = state.frontTextArray.map((textObj) => {
          if (targetElements === textObj.title) {
            return { ...textObj, color: newColor };
          }
          return textObj;
        });
      }
      if (Array.isArray(state.backTextArray)) {
        state.backTextArray = state.backTextArray.map((textObj) => {
          if (targetElements === textObj.title) {
            return { ...textObj, color: newColor };
          }
          return textObj;
        });
      }
    },
    changeTextStroke: (state, action) => {
      const { targetElements, newColor } = action.payload;
      if (Array.isArray(state.frontTextArray)) {
        state.frontTextArray = state.frontTextArray.map((textObj) => {
          if (targetElements === textObj.title) {
            return { ...textObj, stroke: newColor };
          }
          return textObj;
        });
      }

      if (Array.isArray(state.backTextArray)) {
        state.backTextArray = state.backTextArray.map((textObj) => {
          if (targetElements === textObj.title) {
            return { ...textObj, stroke: newColor };
          }
          return textObj;
        });
      }
    },
    changePosition: (state, action) => {
      const { textArr, targetElementId, newX, newY } = action.payload;
      state[textArr] = state[textArr].map((textObj) => {
        if (targetElementId === textObj.id) {
          return { ...textObj, x: newX, y: newY };
        }
        return textObj;
      });
    },
    changeFontStyles: (state, action) => {
      const { targetElements, newFont } = action.payload;

      if (Array.isArray(state.frontTextArray)) {
        state.frontTextArray = state.frontTextArray.map((textObj) => {
          if (targetElements.includes(textObj.title)) {
            return { ...textObj, fontFamily: newFont };
          }
          return textObj;
        });
      }

      if (Array.isArray(state.backTextArray)) {
        state.backTextArray = state.backTextArray.map((textObj) => {
          if (targetElements.includes(textObj.title)) {
            return { ...textObj, fontFamily: newFont };
          }
          return textObj;
        });
      }
    },
    changeTextStrokeWidth: (state, action) => {
      const { targetElements, newStrokeWidth } = action.payload;

      if (Array.isArray(state.frontTextArray)) {
        state.frontTextArray = state.frontTextArray.map((textObj) => {
          if (targetElements === textObj.title) {
            return { ...textObj, strokeWidth: newStrokeWidth };
          }
          return textObj;
        });
      }

      if (Array.isArray(state.backTextArray)) {
        state.backTextArray = state.backTextArray.map((textObj) => {
          if (targetElements === textObj.title) {
            return { ...textObj, strokeWidth: newStrokeWidth };
          }
          return textObj;
        });
      }
    },
    changeTextFontSize: (state, action) => {
      const { targetElements, newFontSize } = action.payload;

      if (Array.isArray(state.frontTextArray)) {
        state.frontTextArray = state.frontTextArray.map((textObj) => {
          if (targetElements === textObj.title) {
            return { ...textObj, fontSize: newFontSize };
          }
          return textObj;
        });
      }

      if (Array.isArray(state.backTextArray)) {
        state.backTextArray = state.backTextArray.map((textObj) => {
          if (targetElements === textObj.title) {
            return { ...textObj, fontSize: newFontSize };
          }
          return textObj;
        });
      }
    },
    addFrontLogo: (state, action) => {
      const { userLogo } = action.payload;
      state.userFrontLogo.logo = userLogo;
    },
    addBackLogo: (state, action) => {
      const { userLogo } = action.payload;
      state.userBackLogo.logo = userLogo;
    },
    changeLogoPosition: (state, action) => {
      const { logoStateName, newX, newY } = action.payload;
      state[logoStateName] = { ...state[logoStateName], x: newX, y: newY };
    },
    changeLogoDimensions: (state, action) => {
      const { logoStateName, newW, newH } = action.payload;
      state[logoStateName].width = newW;
      state[logoStateName].height = newH;
    },
    deleteLogo: (state, action) => {
      const { logoLocation } = action.payload; // Using it to determine which logo to delete
      if (logoLocation === "front") {
        state.userFrontLogo.logo = null;
      }
      if (logoLocation === "back") {
        state.userBackLogo.logo = null;
      }
    },
    exportCanvas: (state, action) => {
      const { downloadName, stageRef } = action.payload;
      if (downloadName === "canvas-front") {
        const dataUrl = stageRef.current.toDataURL();
        state.frontCanvasExportImage = dataUrl;
      } else {
        const dataUrl = stageRef.current.toDataURL();
        state.backCanvasExportImage = dataUrl;
      }
    },
    updateStageRef: (state, action) => {
      const { canvasRef, refStateName } = action.payload;
      state[refStateName] = canvasRef;
    },

    setCustomizeTabIndex: (state, action) => {
      state.customizeTabIndex = action.payload;
    },

    addItemInRoster: (state, action) => {
      const { newRosterItem } = action.payload;
      state.productRoster = [...state.productRoster, newRosterItem];
    },
    updateItemInRoster: (state, action) => {
      const { newRosterItem, Id } = action.payload;
      state.productRoster = state.productRoster.map((item) => {
        if (item.id === Id) {
          return newRosterItem;
        }
        return item;
      });
    },
    deleteItemInRoster: (state, action) => {
      const { rosterItemId } = action.payload;
      state.productRoster = state.productRoster.filter(
        (item) => item.id !== rosterItemId
      );
    },
    addRosterFile: (state, action) => {
      const rosterFile = action.payload;
      state.rosterFile = rosterFile;
    },
    // User CRUD on Text
    addTextOnCanvas: (state, action) => {
      const { textLocation } = action.payload;
      const idOfNewTextObject = Math.random().toString(36).substring(2, 15);
      const titleOfNewTextObject = Math.random().toString(36).substring(2, 15);
      const newTextObject = {
        x: 125,
        y: 150,
        id: idOfNewTextObject,
        fill: "#0ff",
        text: "New Text",
        align: "center",
        title: titleOfNewTextObject,
        width: "auto",
        height: "auto",
        stroke: "#000",
        fontSize: 24,
        draggable: true,
        fontFamily: "Protest Riot",
        strokeWidth: 0.5,
      };
      if (textLocation === "front") {
        if (Array.isArray(state.frontTextArray)) {
          state.frontTextArray = [...state.frontTextArray, newTextObject];
        } else {
          state.frontTextArray = [newTextObject];
        }
      }
      if (textLocation === "back") {
        if (Array.isArray(state.backTextArray)) {
          state.backTextArray = [...state.backTextArray, newTextObject];
        } else {
          state.backTextArray = [newTextObject];
        }
      }
    },
    deleteTextFromCanvas: (state, action) => {
      const { objectID, textLocation } = action.payload;
      if (textLocation === "front") {
        if (Array.isArray(state.frontTextArray)) {
          state.frontTextArray = state.frontTextArray.filter(
            (textObj) => textObj.id !== objectID
          );
        }
      }
      if (textLocation === "back") {
        if (Array.isArray(state.backTextArray)) {
          state.backTextArray = state.backTextArray.filter(
            (textObj) => textObj.id !== objectID
          );
        }
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getCustomProductById.fulfilled, (state, action) => {
        const customProduct = action.payload;
        state.customProduct = customProduct;
        state.frontPathsArr = customProduct.frontobjects;
        state.backPathsArr = customProduct.backobjects;
        state.frontTextArray = customProduct.fronttext;
        state.backTextArray = customProduct.backtext;
        state.frontMaskImage = customProduct.frontmaskimage;
        state.backMaskImage = customProduct.backmaskimage;
        state.isCustomProductLoading = false;

        // console.log(state.frontPathsArr);
        // console.log(state.backPathsArr);

        // console.log(customProduct);
      })
      .addCase(getCustomProductById.rejected, (state, action) => {
        state.isCustomProductLoading = false;

        console.error(action.error.message);
      })
      .addCase(getCustomProductById.pending, (state, action) => {
        state.isCustomProductLoading = true;
      })
      // .addCase(exportCanvasAsync.fulfilled, (state, action) => {
      //   const { downloadName, dataUrl } = action.payload;
      //   if (downloadName === "canvas-front") {
      //     state.frontCanvasExportImage = dataUrl;
      //   } else {
      //     state.backCanvasExportImage = dataUrl;
      //   }
      // }),
});

export const {
  changeColorsArray,
  changeColor,
  changeText,
  changeTextFill,
  changeTextStroke,
  changeFontStyles,
  changeTextFontSize,
  changeTextStrokeWidth,
  addFrontLogo,
  addBackLogo,
  changePosition,
  changeLogoPosition,
  changeLogoDimensions,
  exportCanvas,
  updateStageRef,
  setCustomizeTabIndex,
  addItemInRoster,
  updateItemInRoster,
  deleteItemInRoster,
  addRosterFile,
  addTextOnCanvas,
  deleteTextFromCanvas,
  deleteLogo,
} = canvasSlice.actions;

export const selectColorsArray = (state) => state.canvasState.colorsArray;
export const selectIsCustomProductLoading = (state) =>
  state.canvasState.isCustomProductLoading;
export const selectCustomProduct = (state) => state.canvasState.customProduct;
export const selectColors = (state) => state.canvasState.colors;
export const selectFrontPaths = (state) => state.canvasState.frontPathsArr;
export const selectBackPaths = (state) => state.canvasState.backPathsArr;
export const selectFrontMaskImage = (state) => state.canvasState.frontMaskImage;
export const selectBackMaskImage = (state) => state.canvasState.backMaskImage;
export const selectFrontTextArray = (state) => state.canvasState.frontTextArray;
export const selectBackTextArray = (state) => state.canvasState.backTextArray;
export const selectFontStyles = (state) => state.canvasState.fontStyles;
export const selectUserFrontLogo = (state) => state.canvasState.userFrontLogo;
export const selectUserBackLogo = (state) => state.canvasState.userBackLogo;
export const selectFrontCanvasExportImage = (state) =>
  state.canvasState.frontCanvasExportImage;
export const selectBackCanvasExportImage = (state) =>
  state.canvasState.backCanvasExportImage;
export const selectFrontCanvasRef = (state) => state.canvasState.frontStageRef;
export const selectBackCanvasRef = (state) => state.canvasState.backStageRef;
export const selectFrontCanvasBlob = (state) =>
  state.canvasState.frontCanvasBlob;
export const selectBackCanvasBlob = (state) => state.canvasState.backCanvasBlob;

export const selectCustomizeTabIndex = (state) =>
  state.canvasState.customizeTabIndex;

export const selectProductRoster = (state) => state.canvasState.productRoster;
export const selectProductRosterFile = (state) => state.canvasState.rosterFile;

export default canvasSlice.reducer;
