import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import sampleImage from "../../assets/images/no-image-icon-23494.png";

const CategoryCard = ({ btnSize, categoryData }) => {
  const navigate = useNavigate();
  const goTo = (url) => {
    navigate(url);
  };
  // console.log(categoryData.category_img_url);
  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: "10px",
        m: "0 16px 16px 0",
      }}
      elevation={3}
    >
      <CardMedia
        component="img"
        image={
          categoryData.category_img_url
            ? `${process.env.REACT_APP_API_URL_LOCAL}/uploads/${categoryData.category_img_url}`
            : sampleImage
        }
        alt="product image"
        sx={{
          width: "100%",
          maxHeight: "100%",
          aspectRatio: "1/1",
          objectFit: "contain",
        }}
      />
      <CardContent
        style={{ padding: "16px" }}
        sx={{
          // bgcolor: "#262626",
          height: "20%",
          minHeight: "fit-content",
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          justifyContent: "flex-start",
          gap: "4px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "#262626",
            justifyContent: "flex-start",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <Tooltip title={categoryData.name} placement="top">
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "14px", md: "16px" },
                fontWeight: { xs: 600, md: 700 },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "120px",
              }}
            >
              {categoryData.name}
            </Typography>
          </Tooltip>
          <Tooltip title={categoryData.description}>
            <Typography
              fontWeight={400}
              color="#262626"
              pt={"2px"}
              sx={{
                fontSize: { xs: "12px", md: "14px" },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {categoryData.description}
            </Typography>
          </Tooltip>
        </Box>

        <Button
          variant="outlined"
          sx={{
            color: "#f26728",
            borderColor: "#f26728",
            "&:hover": {
              borderColor: "#f26728",
              color: "#fff",
              bgcolor: "#f26728",
            },
          }}
          onClick={() => {
            if (categoryData?.subcat_count > 0) {
              goTo(`/category/subcategory/${categoryData.id}`);
            } else {
              goTo(`/products/${categoryData.id}`);
            }
          }}
          size={btnSize}
        >
          {categoryData?.subcat_count > 0 ? "View Categories" : "View Products"}
        </Button>
      </CardContent>
    </Card>
  );
};

export default CategoryCard;
