import React, { useEffect, useRef } from "react";
import Navbar from "./Navbar";
import LandingPage from "./LandingPage";
import ImageSection01 from "./ImageSection01";
import About from "./About";
import Categories from "./Categories";
import Eyes from "./Eyes";
import LocomotiveScroll from "locomotive-scroll";
import VideoContainer from "./VideoContainer";
import playVideo from "../../assets/video/FlexClip_14.mp4";
import "../../assets/styles/animatedLandingPage.css";
import Footer from "../LandingPage/Footer/Footer";
import ParallaxSection from "./ParallaxSection";
import parallaxSectionVideo from "../../assets/video/FlexClip_6.mp4";

const AnimatedLandingPage = () => {
  const appContainerRef = useRef(null);
  const appContentRef = useRef(null);

  const locomotiveScroll = new LocomotiveScroll();

  return (
    <div className="w-full min-h-screen " style={{ fontFamily: "Noto Sans" }}>
      <LandingPage />

      <ImageSection01 />
      <About />
      <div className="w-full p-[30px] md:px-[3vw] md:py-[3vw]">
        <ParallaxSection
          rounded={true}
          height={window.innerWidth < 700 ? "30dvh" : "95vh"}
          width={"100%"}
          videoSrc={parallaxSectionVideo}
        />
      </div>
      <Categories />
      <VideoContainer src={playVideo} />
    </div>
  );
};

export default AnimatedLandingPage;
