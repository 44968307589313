export function camelCaseToCapitalized(str) {
  // Use regular expression to split camel case string
  // and capitalize each word
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\b([a-z])/g, function (_, initial) {
      return initial.toUpperCase();
    });
}

export function validateRosterItems(roster) {
  if (roster.length === 0) return false;
  for (let i = 0; i < roster.length; i++) {
    const item = roster[i];
    if (
      item.number === null ||
      item.number === "" ||
      item.playerName === "" ||
      item.qty === null ||
      item.qty === ""
    ) {
      return false;
    }
  }
  return true;
}

export function validateCartItems(cartItems) {
  if (cartItems.length === 0) return false;
  for (const item of cartItems) {
    if (!validateRosterItems(item.roster)) {
      return false;
    }
  }
  return true;
}

export function calculateAverageRating(reviews) {
  if (!reviews) return 0;
  if (reviews.length === 0) return 0; // Return 0 if there are no reviews

  const totalRating = reviews.reduce(
    (accumulator, review) => accumulator + review.rating,
    0
  );
  return totalRating / reviews.length;
}

export const phoneNumberRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
export const zipCodeRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

export function getCategoriesWithoutParent(categories) {
  return categories.filter((category) => category.parent_category_id === null);
}

export function getCategoryById(categories, id) {
  return categories.find((category) => category.id === id);
}

export function getCategoriesByParentId(categories, parentId) {
  return categories.filter(
    (category) => category.parent_category_id === parentId
  );
}

// export function getRelatedCategoriesWithSubcategories(categories , id) {
//   const category = categories.find((cat) => cat.id === id);
//   if (!category) {
//     return null;
//   }

//   const result = {
//     category: category,
//     subcategories: [],
//   };

//   if (category.parent_category_id) {
//     const parentCategory = getRelatedCategoriesWithSubcategories(
//       category.parent_category_id
//     );
//     if (parentCategory) {
//       result.subcategories.push(parentCategory);
//     }
//   }
//   return result;
// }

export function getRelatedCategoriesNameAndLink(categories, id) {
  const items = [];
  let currentId = id;
  

  do {
    let category = getCategoryById(categories, currentId);

    let name = category?.name;
    let link = `/category/subcategory/${category?.id}`;

    items.unshift({ name, link });

    if (!category?.parent_category_id) return items;
    currentId = category?.parent_category_id;
  } while (true);
}

export function buildCategoryTree(categories, parentCategoryId = null) {
  const tree = [];
  if (Array.isArray(categories)) {
    categories.forEach((category) => {
      if (category?.parent_category_id === parentCategoryId) {
        const subtree = {
          category: category,
          subcategories: buildCategoryTree(categories, category.id),
        };
        tree.push(subtree);
      }
    });
    return tree;
  }
}
