import { Button, Card, CardContent, CardMedia, Box } from "@mui/material";
import React, { useState, useRef } from "react";
import { buttonStyleMui } from "../../../config/constants";
import {
  deleteSecondaryImage,
  selectSingleProduct,
  updatePrimaryImage,
  updateSecondaryImage,
} from "../../../slices/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import toast from "react-hot-toast";

const ProductImageUploadCard = ({ imageSrc, image, variant }) => {
  const { id: productId } = useParams();
  // console.log(productId);
  const dispatch = useDispatch();
  const [productImageFile, setProductImageFile] = useState(null);
  const fileInputRef = useRef(null);

  const singleProduct = useSelector(selectSingleProduct);

  const handleChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image_id", image.id);
    formData.append("image", file);
    const formJson = Object.fromEntries(formData);

    try {
      if (variant === "primary") {
        await toast.promise(
          dispatch(updatePrimaryImage({ id: productId, formData: formData })),
          {
            loading: "Updating Primary Image...",
            success: "Primary Image updated successfully!",
            error: "Error While Updating Primary Image",
          }
        );
      } else if (variant === "secondary") {
        await toast.promise(
          dispatch(updateSecondaryImage({ id: productId, formData: formData })),
          {
            loading: "Updating Secondary Image...",
            success: "Secondary Image updated successfully!",
            error: "Error While Updating Secondary Image",
          }
        );
      }

      if (file) {
        setProductImageFile(URL.createObjectURL(file));
      }
    } catch (error) {
      console.error(error);
    } finally {
      event.target.value = "";
    }
  };

  const handleDeleteFile = async () => {
    const imageId = image?.id;
    const formData = new FormData();
    formData.append("image_id", imageId);
    const formJson = Object.fromEntries(formData);
    // console.log(formJson);

    try {
      // console.log(formData);
      if (variant === "primary") {
        toast.error("Primary Image cannot be deleted");
        return;
      } else if (variant === "secondary") {
        await toast.promise(
          dispatch(
            deleteSecondaryImage({
              id: productId,
              formData: { image_id: image?.id },
            })
          ),
          {
            loading: "Deleting Secondary Image...",
            success: "Secondary Image deleted successfully!",
            error: "Error While Deleting Secondary Image",
          }
        );
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error(error);
    }
    // setProductImageFile(null);
    // Additional logic for deleting the file if required
  };

  return (
    <>
      <Card sx={{ width: "300px", height: "fit-content", mr: 2, mb: 2 }}>
        <CardMedia
          component={"img"}
          image={productImageFile || imageSrc}
          alt="product image"
          height={"300px"}
          sx={{
            objectFit: "contain",
            objectPosition: "center",
            maxHeight: "80%",
            overflow: "hidden",
          }}
        />
        {/* <Box
          sx={{
            width: "100%",
            height: "300px",
            backgroundImage: `url(${productImageFile || imageSrc})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Box> */}
        <CardContent
          sx={{
            p: "10px",
            pb: "10px !important",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Button
            disableRipple
            disableElevation
            sx={{
              ...buttonStyleMui,
              bgcolor: "none",
              color: "#000",
              "&:hover": { bgcolor: "none", color: "#000" },
            }}
            onClick={() => fileInputRef.current.click()}
            disabled={singleProduct.isLoading}
          >
            Change
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleChange}
          />
          <Button
            disableRipple
            disableElevation
            sx={{
              ...buttonStyleMui,
              bgcolor: "none",
              color: "#000",
              "&:hover": { bgcolor: "none", color: "#000" },
            }}
            onClick={handleDeleteFile}
          >
            Delete
          </Button>
        </CardContent>
      </Card>
    </>
  );
};

export default ProductImageUploadCard;
