import React, { useState, useRef, useEffect } from "react";

const VideoContainer = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handleVideoEnded = () => {
    setIsPlaying(false);
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const [rotate, setRotate] = useState(0);
  useEffect(() => {
    window.addEventListener("mousemove", (e) => {
      let mouseX = e.clientX;
      let mouseY = e.clientY;

      let deltaX = mouseX - window.innerWidth / 2;
      let deltaY = mouseY - window.innerHeight / 2;

      var angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
      setRotate(angle - 180);
    });
  });

  const [showText, setShowText] = useState(false);
  const [textPosition, setTextPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    if (isPlaying) {
      setTextPosition({
        x: e.clientX - e.currentTarget.getBoundingClientRect().x + 1,
        y: e.clientY - e.currentTarget.getBoundingClientRect().y + 1,
      });
    }
  };

  return (
    <div
      className="video-container relative w-full h-fit overflow-hidden p-[30px] md:px-[3vw] md:py-[3vw] rounded-3xl"
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
    >
      <video
        ref={videoRef}
        src={src}
        onEnded={handleVideoEnded}
        className="w-full h-full rounded-3xl "
        onClick={isPlaying ? togglePlayPause : undefined}
      />

      {showText && isPlaying && (
        <div
          style={{
            position: "absolute",
            top: textPosition.y,
            left: textPosition.x,
            pointerEvents: "none", // Make sure the text doesn't interfere with cursor events
          }}
          className="text-white border-2 border-white rounded-3xl px-[.8vw] py-[.4vw]"
        >
          Pause
        </div>
      )}
      {/* <button
        onClick={togglePlayPause}
        className={`play-button flex items-center justify-center bg-gray-700 opacity-75 hover:opacity-100 text-white px-4 py-2 rounded-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
      >
        {isPlaying ? "Pause" : "Play"}
      </button> */}
      <div
        className="flex gap-10 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] "
        style={{ display: !isPlaying ? "flex" : "none" }}
      >
        <div className="h-[25dvw] w-[25dvw] md:w-[15vw]  md:h-[15vw] rounded-full bg-zinc-100 flex items-center justify-center">
          <div
            className="w-2/3 h-2/3 rounded-full bg-zinc-900 relative cursor-pointer"
            onClick={togglePlayPause}
          >
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-[4dvw] md:text-[2vw]">
              Play
            </div>
            <div
              style={{
                transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
              }}
              className="line w-full h-10 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%]"
            >
              <div className="w-[5dvw] h-[5dvw] md:w-10 md:h-10 rounded-full bg-zinc-100">
                {/* <button onClick={togglePlayPause}>
                      {isPlaying ? "Pause" : "Play"}
                    </button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="h-[25dvw] w-[25dvw] md:w-[15vw] md:h-[15vw] rounded-full bg-zinc-100 flex items-center justify-center">
          <div
            className="w-2/3 h-2/3 rounded-full bg-zinc-900 relative cursor-pointer"
            onClick={togglePlayPause}
          >
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-[4dvw] md:text-[2vw]">
              Play
            </div>
            <div
              style={{
                transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
              }}
              className="line w-full h-10 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%]"
            >
              <div className="w-[5dvw] h-[5dvw] md:w-10 md:h-10 rounded-full bg-zinc-100">
                {/* <button onClick={togglePlayPause}>
                      {isPlaying ? "Pause" : "Play"}
                    </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoContainer;
