import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

const HowToOrder = () => {
  return (
    <>
      <Helmet>
        <title>How to order | US Sports</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
      </Helmet>
      <div className="w-full min-h-screen px-8">
        <TopHeading />
        <HowItWorks />
        <FindAnswersToQuestions />
        <CareInstructions />
      </div>
    </>
  );
};

export default HowToOrder;

function TopHeading() {
  return (
    <div className="top-heading w-full flex items-center justify-center flex-col mt-16 md:mt-0">
      <h1 className="text-[4.4vw] md:text-[2.4vw] mt-4 font-semibold">
        How to place an order
      </h1>
      <p className="text-[2.6vw] md:text-[1.5vw] font-medium text-center">
        Order your sublimated gear hassle-free without adding extra unpaid work
        to your schedule.
      </p>
    </div>
  );
}

function HowItWorks() {
  const howItWorksSteps = [
    {
      title: "Explore",
      description: "Choose a category from the navigation menu.",
    },
    {
      title: "Design",
      description:
        "Choose colors from the palette, customize your design, add your logo, edit text, and adjust its color, font, size, style, and stroke.",
    },
    {
      title: "Get a Quote",
      description:
        "Enter your team's information or upload the roster file, complete the inquiry form, and submit it.",
    },
  ];
  return (
    <div className="w-full flex items-center justify-center flex-col">
      <h4 className="text-[2.9vw] md:text-[1.3vw] font-medium my-[3vw] md:mt-[1vw]">
        Here's how it works:
      </h4>
      <div className="w-full flex items-center justify-center md:flex-row flex-col gap-y-6">
        {howItWorksSteps.map((item, index) => {
          return (
            <motion.div
              key={index}
              className="w-full h-fit md:h-[400px] flex flex-col items-center justify-center"
              initial={{ opacity: 0, y: -20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{
                ease: "easeInOut",
                duration: 0.2,
                delay: index * 0.4,
              }}
            >
              <div className="circle border-2 bg-black/10 text-black cursor-pointer border-black w-[200px] aspect-square rounded-full flex items-center justify-center">
                <p className="text-[9vw] md:text-[6vw] font-bold">
                  {index + 1}
                </p>
              </div>
              <h4 className="text-[4vw] md:text-[2vw] font-bold text-black">
                {item.title}
              </h4>
              <p className="text-center px-4 text-wrap leading-tight text-[3vw] md:text-[1.2vw]">
                {item.description}
              </p>
            </motion.div>
          );
        })}
      </div>
      <p className="text-[2.7vw] my-4 md:my-0 md:text-[1vw] italic text-black">
        We will get in touch with you within one business day.
      </p>
    </div>
  );
}

function FindAnswersToQuestions() {
  return (
    <div className="w-full my-8 px-[4vw]">
      <h2 className="text-center text-[3.8vw] md:text-[2.4vw] font-semibold">
        Discover solutions to questions you likely have.
      </h2>
      <QuestionsAccordion />
    </div>
  );
}

function QuestionsAccordion() {
  const faqs = [
    {
      question: "Fully sublimated means",
      answer:
        "Fully sublimated means that the design and your custom decorations are dyed directly into the garment as it’s made, bringing your gear to you in record time.",
    },
    {
      question: "Production and ship time",
      answer:
        "Production and ship time begins once final artwork is approved and you submit your payment.",
    },
  ];
  return (
    <div className="w-full py-2">
      {faqs.map((faq, index) => {
        return (
          <Accordion defaultExpanded key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#f26728" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                className="text-black font-bold"
                sx={{ fontSize: { xs: "3.4vw", md: "1.4vw" } }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

function CareInstructions() {
  return (
    <div className="w-full px-[4vw] flex items-center justify-center flex-col mb-10">
      <h4 className="text-[3.8vw] md:text-[2.4vw] mt-4 font-semibold">
        Care Instructions
      </h4>
      <div className="w-full flex flex-col items-center gap-y-3 my-2">
        {[
          "Do not dry clean, wash in hot water, use chlorine bleach, wash whites and colors together or dry on high heat. Trust us, you’ll be much happier with the life of your jersey!",
          "Do not screenprint sublimated apparel.",
          "Be responsible – this is a time to follow the rules! We stand behind our products, but our warranty doesn’t cover misuse.",
        ].map((instruction) => (
          <div className="instruction w-full bg-black/5 border-[1px] border-black text-[#000] px-[4vw] md:px-[1vw] py-[1.9vw] md:py-[0.6vw] rounded-lg ">
            {instruction}
          </div>
        ))}
      </div>
    </div>
  );
}
