import React, { useEffect } from "react";
import AllCategoriesContainer from "../../../components/Dashboard/Categories/AllCategoriesContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllParentCategories,
  selectAllParentCategories,
} from "../../../slices/categoriesSlice";

const DashCategories = () => {
  const dispatch = useDispatch();
  const allCategories = useSelector(selectAllParentCategories);
  useEffect(() => {
    try {
      dispatch(getAllParentCategories());
    } catch (error) {
      throw error;
    }
  }, []);

  return (
    <div>
      <AllCategoriesContainer allParentCategories={allCategories} />
    </div>
  );
};

export default DashCategories;
