import {
  Avatar,
  Box,
  Card,
  Chip,
  Grid,
  IconButton,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import toast from "react-hot-toast";
import axios from "axios";
import { deleteProductReview } from "../../../slices/productSlice";
import { useDispatch } from "react-redux";
const ReviewsEditor = ({ singleProduct }) => {
  const dispatch = useDispatch();
  const [productReviews, setProductReviews] = useState(
    singleProduct?.data?.reviews
  );
  useEffect(() => {
    setProductReviews(singleProduct?.data?.reviews);
  }, [singleProduct?.data]);

  const handleReviewDelete = (reviewId, productId) => {
    // console.log(reviewId);
    const confirmed = window.confirm("Are you sure want to delete?");
    if (confirmed) {
      toast.promise(
        new Promise(async (resolve, reject) => {
          try {
            await dispatch(deleteProductReview({ reviewId, productId }));
            resolve();
          } catch (error) {
            reject(error);
          }
        }),
        {
          loading: "Deleting...",
          success: "Deleted",
          error: "Error",
        }
      );
    } else {
      toast.error("Cancelled");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        borderRadius: "0.3vw",
        boxShadow: 2,
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "start",
          flexDirection: "column",
          mb: 0.5,
          gap: "1vw ",
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
          Edit Reviews Of This Product
        </Typography>
        <Grid container spacing={2}>
          {Array.isArray(productReviews) &&
            productReviews.map((review) => {
              const reviewDateObj = new Date(review.date);
              const formattedDate = reviewDateObj.toLocaleString();
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={review.id}
                  sx={{
                    width: "100%",
                    height: "fit-content",
                  }}
                >
                  <Card sx={{ bgcolor: "#fafcfd", p: "10px 10px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          alt={review.name}
                          src=""
                          sx={{
                            mr: 2,
                            width: { xs: "6vw", md: "3vw" },
                            height: { xs: "6vw", md: "3vw" },
                          }}
                        />
                        <Box sx={{}}>
                          <Tooltip title={review.name}>
                            <Typography
                              sx={{
                                fontSize: { xs: "2.5vw", md: "1vw" },
                                fontWeight: 700,
                                maxWidth: "100px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {review.name}
                            </Typography>
                          </Tooltip>
                          <Typography
                            sx={{
                              fontSize: { xs: "2vw", md: "1vw" },
                              fontWeight: 400,
                            }}
                          >
                            <Rating
                              readOnly
                              size="small"
                              value={review.rating}
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <Tooltip title="Delete This Review">
                        <IconButton
                          onClick={() =>
                            handleReviewDelete(
                              review.id,
                              singleProduct?.data?.id
                            )
                          }
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#e0eae9",
                        my: "5px",
                        p: "3px 6px",
                        borderRadius: "0.5vw",
                      }}
                    >
                      <Typography sx={{ fontSize: { xs: "2vw", md: "1vw" } }}>
                        {review.content}
                      </Typography>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <Typography
                        sx={{
                          fontSize: { xs: "2vw", md: "0.8vw" },
                          textAlign: "right",
                          fontStyle: "italic",
                        }}
                      >
                        {review.email}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "2vw", md: "0.8vw" },
                          textAlign: "right",
                          fontStyle: "italic",
                        }}
                      >
                        {formattedDate}
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
};

export default ReviewsEditor;
