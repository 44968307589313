import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Badge, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ControlMenuButton = ({ actions, textOrPathObj }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Badge variant="dot" invisible={textOrPathObj?.isSaved} color="error">
          <MoreVertIcon />
        </Badge>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {actions?.map((action) => {
          return (
            <MenuItem
              onClick={() => {
                handleClose();
                action.handleClick(textOrPathObj);
              }}
              sx={{ color: action?.color }}
            >
              <action.icon sx={{ mr: 1, color: action?.color }} />

              {action.title}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default ControlMenuButton;
