import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Oval } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { buttonStyleMui, textFieldStyle } from "../../../config/constants";
import { useNavigate, useParams } from "react-router";
import {
  addPrimaryImage,
  addSecondaryImage,
  createNewProduct,
  selectIsNewProductCreating,
} from "../../../slices/productSlice";

import UploadIcon from "@mui/icons-material/Upload";
import axios from "axios";
import toast from "react-hot-toast";

const FileUploadDialogPrimary = ({ open, setOpen }) => {
  const { id: productId } = useParams();
  // console.log(productId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imageTypeValue, setimageTypeValue] = useState("secondary");

  const [productImageFile, setProductImageFile] = useState(null);
  const [isLoadingApi, setIsLoadingApi] = useState(false);

  const fileInputRef = useRef(null);

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProductImageFile(file);
    }
  };

  useEffect(() => {
    if (!isLoadingApi && open) {
      setOpen(false);
    }
  }, [isLoadingApi]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    if (!isLoadingApi) {
      setOpen(false);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            setIsLoadingApi(true);
            const formData = new FormData(event.currentTarget);
            formData.append("image", productImageFile);
            const formJson = Object.fromEntries(formData.entries());
            // console.log(formJson);

            toast.promise(
              new Promise(async (resolve, reject) => {
                try {
                  const imageAdded = await dispatch(
                    addPrimaryImage({ id: productId, formData: formData })
                  );
                  resolve();
                } catch (error) {
                  reject(error);
                } finally {
                  setIsLoadingApi(false);
                  // handleClose();
                }
              }),
              {
                loading: "Adding Primary Image...",
                success: "Primary Image added successfully!",
                error: "Error While Adding Primary Image",
              }
            );
          },

          sx: { p: 1 },
        }}
      >
        <DialogTitle>Add New Product Image</DialogTitle>
        <DialogContent sx={{ "&.MuiDialogContent-root": { p: 1 } }}>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleChange}
          />
          <Button
            fullWidth
            sx={{ ...buttonStyleMui, mb: 2 }}
            onClick={() => fileInputRef.current.click()}
          >
            <UploadIcon sx={{ mr: 1 }} />
            Upload Image
          </Button>
          <Box sx={{ width: "100%", textAlign: "center", color: "blueviolet" }}>
            {productImageFile?.name}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disableRipple
            disableElevation
            disabled={isLoadingApi}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button type="submit" disableElevation disabled={isLoadingApi}>
            {isLoadingApi ? (
              <Oval height={20} width={20} color="#0a5484" />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileUploadDialogPrimary;
