import React from "react";
import WeAreUnderConstruction from "../../components/Common/WeAreUnderConstruction";

const UnderConstruction = () => {
  return (
    <div>
      <WeAreUnderConstruction />
    </div>
  );
};

export default UnderConstruction;
