export const categories = [
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ed",
    name: "Women",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    parent_category_id: null,
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ee",
    name: "Sports",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ed",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ef",
    name: "Apparel",
    description:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ed",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8f0",
    name: "Basketball",
    description:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ee",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8f1",
    name: "Fastpitch",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ee",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8f2",
    name: "Field Hockey / Lacrosse",
    description:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ee",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8f3",
    name: "Soccer",
    description:
      "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ee",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8f4",
    name: "Tennis",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ee",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8f5",
    name: "Track And Field",
    description:
      "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ee",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8f6",
    name: "Volleyball",
    description:
      "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ee",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8f7",
    name: "Cage Jackets",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ef",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8f8",
    name: "Cinch Sack",
    description:
      "Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ef",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8f9",
    name: "Fan Replica Jersey",
    description:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ef",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8fa",
    name: "Game Day Jackets",
    description:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ef",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8fb",
    name: "Polo",
    description:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ef",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8fc",
    name: "Hoodie",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ef",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8fd",
    name: "Yoga Pants",
    description:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ef",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8fe",
    name: "Tech Tee",
    description:
      "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ef",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ff",
    name: "Socks",
    description:
      "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ef",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e800",
    name: "Quarter Zip Jackets",
    description:
      "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.",
    parent_category_id: "5272b0d9-8871-4f1a-b8bf-f31d2e12e8ef",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "6d1735c4-61f0-4e05-bda4-f7dfc1ffdb7d",
    name: "Men",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    parent_category_id: null,
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "7b70d542-044d-4a4f-a28e-949394ad03e3",
    name: "Sports",
    description:
      "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    parent_category_id: "6d1735c4-61f0-4e05-bda4-f7dfc1ffdb7d",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    name: "Apparel",
    description:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    parent_category_id: "6d1735c4-61f0-4e05-bda4-f7dfc1ffdb7d",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "e3e268b3-7243-4c15-bc1c-9038b5183702",
    name: "Baseball",
    description:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    parent_category_id: "7b70d542-044d-4a4f-a28e-949394ad03e3",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "4c3d6a27-7a5d-4fc7-af78-d0e049bfb1f3",
    name: "Basketball",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    parent_category_id: "7b70d542-044d-4a4f-a28e-949394ad03e3",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "cc155c4f-3bc0-44e1-b537-4f7a5c191e82",
    name: "Football",
    description:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
    parent_category_id: "7b70d542-044d-4a4f-a28e-949394ad03e3",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "32949a30-94e8-4cc4-8e24-4566a5c08c2e",
    name: "Hockey",
    description:
      "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.",
    parent_category_id: "7b70d542-044d-4a4f-a28e-949394ad03e3",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "665303f7-3ee2-4d9c-85f6-5bc0784f8029",
    name: "Tennis",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    parent_category_id: "7b70d542-044d-4a4f-a28e-949394ad03e3",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "a80d4cb0-cf3e-4e0e-a0e5-7d39c9d2a36b",
    name: "Track And Field",
    description:
      "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.",
    parent_category_id: "7b70d542-044d-4a4f-a28e-949394ad03e3",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "87b2e46a-7cd2-4f85-869b-9d0149f9e55f",
    name: "Lacroose",
    description:
      "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    parent_category_id: "7b70d542-044d-4a4f-a28e-949394ad03e3",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "c891c72d-22b8-4e7a-bc82-25b4f01d93b3",
    name: "Soccer",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    parent_category_id: "7b70d542-044d-4a4f-a28e-949394ad03e3",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "cd6c8ed5-9fc3-4ee3-92e3-670f7bc8c2e2",
    name: "Volleyball",
    description:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    parent_category_id: "7b70d542-044d-4a4f-a28e-949394ad03e3",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "6d22dadc-fd2c-43c8-bf71-8d18a4d52d39",
    name: "Wrestling",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    parent_category_id: "7b70d542-044d-4a4f-a28e-949394ad03e3",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "7d68345d-4b10-4c32-bb4e-1a6834d40e3a",
    name: "eSports",
    description:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.",
    parent_category_id: "7b70d542-044d-4a4f-a28e-949394ad03e3",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "b6b394fb-9613-4f59-b4af-08d4d96cc47c",
    name: "Cage Jackets",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "12a02a0a-334d-4881-af89-40b366fe7c32",
    name: "Cinch Sack",
    description:
      "Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "739e3e0d-46ee-4a8a-b105-7e52b5cf02fb",
    name: "Compression",
    description:
      "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "fc641f6f-7f8c-4048-b0d0-cc7479ae9f41",
    name: "Pant",
    description:
      "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "0de6bb43-0f2b-4940-9961-4d09f22713f3",
    name: "Fan Replica Jersey",
    description:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "5a34e00b-011f-4ef5-bb79-cb0f54029b39",
    name: "Game Day Jackets",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "8a56c8ae-3a86-4208-b3b7-960b3e3082ac",
    name: "Hoodies",
    description:
      "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "db94fd19-fa26-4c6a-875e-bf86e73ff04d",
    name: "Polo Man",
    description:
      "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "ef01006c-f5c0-44f1-b6cf-989a798d6b87",
    name: "Quarter Zip Jackets",
    description:
      "Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "d57be762-0d23-45b8-92a7-98f51a8ed61d",
    name: "SFN Jooger",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "facd7a57-1b7a-490f-bb2d-9c5b18a20a07",
    name: "Socks",
    description:
      "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "42973e1d-5e19-420d-a071-7e392f45bcac",
    name: "Team Short With Pockets",
    description:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
  {
    id: "6868f8ff-5868-4e6b-88dc-3d9e18f4b739",
    name: "Tech Tee",
    description:
      "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    parent_category_id: "4aef7fc3-5925-48e6-a12a-88a39b24888c",
    category_img_url: "",
    subcat_count: null,
  },
];

// function generateInsertCommands(categories) {
//   // Initialize an empty array to store the INSERT commands
//   const insertCommands = [];

//   // Loop through each category object
//   categories.forEach((category) => {
//     // Construct the VALUES part of the INSERT command for the current category
//     const valuesPart = `('${category.id}', '${category.name.replace(
//       /'/g,
//       "''"
//     )}', '${category.description.replace(/'/g, "''")}', ${
//       category.parent_category_id ? `'${category.parent_category_id}'` : "NULL"
//     }, '${category.category_img_url}')`;

//     // Push the VALUES part to the array
//     insertCommands.push(valuesPart);
//   });

//   // Combine the VALUES parts into a single multi-line command
//   const combinedCommand = `INSERT INTO categories (id, name, description, parent_category_id, category_img_url)\nVALUES\n${insertCommands.join(
//     ",\n"
//   )};`;

//   return combinedCommand;
// }

// // Call the function and log the combined INSERT command
// const combinedInsertCommand = generateInsertCommands(categories);
// console.log(combinedInsertCommand);
